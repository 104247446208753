import { Outlet, Navigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import Footer from "./Components/Footer";
import { ProtectedWrapper } from "./common/ProtectedOutletStyle";

function ProtectedRouter() {
  return (
    <div>
      <ProtectedWrapper>
        <Outlet />
      </ProtectedWrapper>
      <Footer />
    </div>
  );
}

export default ProtectedRouter;
