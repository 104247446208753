import { useEffect, useState } from "react";
import { Radio, Button } from "antd";
import _get from "lodash.get";
import { IServerType, IServicePlanType } from "./interfaces";

import {
  DownCircleOutlined,
  UpCircleOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";

import { CardWrapper, CardHeading, CardBody, Dropdown, ServerBtn, CartText, NameWrapper } from "./style";

import {
  ServerTypeContainer,
  ServerTypeCard,
  ServiceTypeCardHeader,
  ServiceTypeCardBody,
  ServiceTypeCardFooter,
  ServiceTypeCardBodyTextContainer,
  ServiceTypeModalHeader,
  KnowMore,
  ServerTypeCardPrice,
} from "../style";

import MobileModal from "../../../common/Components/MobileModal";
import { ITenureServicePlansMap } from "../interfaces";
import { ServicePlan, ServiceWithPlans } from "../../../constants/interfaces";

interface IProps {
  visible: boolean;
  tenureServicePlansMap: ITenureServicePlansMap;
  name: string;
  color: string;
  isOpen: boolean;
  isInCart: boolean;
  addToCart(item: any): void;
  removeFromCart(item: any): void;
  onToggle(): void;
}

function Card(props: IProps) {
  const [choosedTenure, setChoosedTenure] = useState<number | null>(() => {
    return +Object.keys(props.tenureServicePlansMap)[0];
  });
  const [choosedServicePlan, setChoosedServicePlan] = useState<ServicePlan | null>(() => {
    return props.tenureServicePlansMap[choosedTenure][0];
  });

  const [isServerTypeModalOpen, setIsServerTypeModalOpen] = useState<boolean>(false);
  const [isSimpleServerTypeView, setIsSimpleServerTypeView] = useState<boolean>(true);

  function setServerType(type: IServicePlanType, serverType: IServerType = null) {
    const servicePlan = props.tenureServicePlansMap[choosedTenure].find((servicePlan) => {
      return servicePlan.type === type && servicePlan.serverType === serverType;
    });

    if (!servicePlan) {
      setChoosedTenure(+Object.keys(props.tenureServicePlansMap)[0]);
      setChoosedServicePlan(props.tenureServicePlansMap[choosedTenure][0]);
      return;
    }

    console.log({ type, serverType, servicePlan });
    setChoosedServicePlan(servicePlan);
    setIsServerTypeModalOpen(false);
  }

  useEffect(() => {
    if (!props.isInCart) return;

    props.addToCart(choosedServicePlan.id);
  }, [choosedServicePlan]);

  useEffect(() => {
    setServerType(choosedServicePlan.type, choosedServicePlan.serverType);
  }, [choosedTenure]);

  if (!props.visible || !choosedServicePlan) return null;
  return (
    <CardWrapper>
      <CardHeading color={props.color} onClick={props.onToggle}>
        <div className="left">
          {props.isInCart ? <CheckCircleTwoTone twoToneColor={[props.color, "#fefefe"]} /> : null}
          <NameWrapper>
            <span>{props.name}</span>
            {props.isInCart ? (
              <span className="subheading">{`${choosedServicePlan.tenurePeriod} ${
                choosedServicePlan.tenurePeriod === "1" ? "month" : "months"
              }`}</span>
            ) : null}
          </NameWrapper>
        </div>
        <div className="right">
          {props.tenureServicePlansMap[choosedTenure].find((servicePlan) => servicePlan.type === "SERVER") ? (
            <ServerBtn
              selected={choosedServicePlan.type === "SERVER"}
              onClick={(e) => {
                setIsServerTypeModalOpen(true);
                e.stopPropagation();
              }}
            >
              <span>Server</span>
              {choosedServicePlan.serverType === "WITH_SOUND" ? <img src="/crown.png" /> : null}
            </ServerBtn>
          ) : null}

          {props.tenureServicePlansMap[choosedTenure].find((servicePlan) => servicePlan.type === "NON_SERVER") ? (
            <ServerBtn
              selected={choosedServicePlan.type === "NON_SERVER"}
              onClick={(e) => {
                setServerType("NON_SERVER");
                e.stopPropagation();
              }}
            >
              Non Server
            </ServerBtn>
          ) : null}

          {props.isOpen ? <UpCircleOutlined /> : <DownCircleOutlined />}
        </div>
      </CardHeading>
      <CardBody isOpen={props.isOpen}>
        <Dropdown>
          <div className="heading">
            <span>Tenure</span>
            <span>
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }).format(choosedServicePlan.price)}
            </span>
          </div>
          <div className="body">
            {Object.keys(props.tenureServicePlansMap).map((_tenure) => (
              <div className="row">
                <span>{`${_tenure} month`}</span>
                <Radio
                  checked={Number(_tenure) === choosedTenure}
                  onChange={(e) => {
                    console.log(e.target);
                    if (e.target.checked) setChoosedTenure(Number(_tenure) as 1 | 3 | 6 | 12);
                  }}
                />
              </div>
            ))}
          </div>
        </Dropdown>
        <Button
          type="primary"
          danger={props.isInCart}
          onClick={() => {
            if (props.isInCart) {
              props.removeFromCart(choosedServicePlan.id);
            } else {
              props.onToggle();
              props.addToCart(choosedServicePlan.id);
            }
          }}
        >
          {props.isInCart ? (
            <>
              <MinusCircleOutlined />
              <CartText>Remove from cart</CartText>
            </>
          ) : (
            <>
              <PlusCircleOutlined />
              <CartText>Add to cart</CartText>
            </>
          )}
        </Button>
      </CardBody>

      <MobileModal
        isOpen={isServerTypeModalOpen}
        onClose={() => {
          setIsServerTypeModalOpen(false);
          setIsSimpleServerTypeView(true);
        }}
      >
        <ServiceTypeModalHeader>Choose server type</ServiceTypeModalHeader>
        {isSimpleServerTypeView && (
          <>
            <ServerTypeContainer>
              {props.tenureServicePlansMap[choosedTenure].find((servicePlan) => servicePlan.serverType === "PUBLIC") ? (
                <ServerTypeCard onClick={() => setServerType("SERVER", "PUBLIC")}>
                  <ServiceTypeCardHeader simple>Server</ServiceTypeCardHeader>
                </ServerTypeCard>
              ) : null}

              {props.tenureServicePlansMap[choosedTenure].find(
                (servicePlan) => servicePlan.serverType === "WITH_SOUND"
              ) ? (
                <ServerTypeCard onClick={() => setServerType("SERVER", "WITH_SOUND")}>
                  <ServiceTypeCardHeader simple>
                    <span>Server with sound</span>
                    <img src="/crown.png" />
                  </ServiceTypeCardHeader>
                  <ServerTypeCardPrice>+ Rs 3000</ServerTypeCardPrice>
                </ServerTypeCard>
              ) : null}
            </ServerTypeContainer>
            {/* <KnowMore onClick={() => setIsSimpleServerTypeView(false)}>What is this?</KnowMore> */}
          </>
        )}

        {/* ::: THIS CAN BE USED IN FUTURE :::
        {!isSimpleServerTypeView && (
          <ServerTypeContainer>
            <ServerTypeCard onClick={() => setServerType("SERVER", "PUBLIC")}>
              <ServiceTypeCardHeader>Server (Without long sound)</ServiceTypeCardHeader>
              <ServiceTypeCardBody>
                <ServiceTypeCardBodyTextContainer>
                  <p>A powerful server is shared with all the other public server users</p>
                </ServiceTypeCardBodyTextContainer>
              </ServiceTypeCardBody>
              <ServiceTypeCardFooter>Choose</ServiceTypeCardFooter>
            </ServerTypeCard>
            <ServerTypeCard onClick={() => setServerType("SERVER", "WITH_SOUND")}>
              <ServiceTypeCardHeader>
                <span>Server (With long sound)</span>
                <img src="/crown.png" />
              </ServiceTypeCardHeader>
              <ServiceTypeCardBody>
                <ServiceTypeCardBodyTextContainer>
                  <p>A private server is assigned to you</p>
                  <p>It guarantees notification with fast response</p>
                  <p>Backup server ensures no failures</p>
                  <p>Stock is checked every after 3 seconds</p>
                </ServiceTypeCardBodyTextContainer>
              </ServiceTypeCardBody>
              <ServiceTypeCardFooter>Choose</ServiceTypeCardFooter>
            </ServerTypeCard>
          </ServerTypeContainer>
        )} */}
      </MobileModal>
    </CardWrapper>
  );
}

export default Card;
