import React, { useEffect, useRef } from "react";
import { Tour } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import { FOOTER_ITEMS } from "./constants";
import { FooterWrapper, FooterItem, FooterItemContainer, GlobalStyleCSS } from "./style";
import AddDeviceModal from "./AddDeviceModal";

const Footer = () => {
  const navigate = useNavigate();
  const refs = useRef<Array<HTMLDivElement | null>>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isAddDeviceModalOpen, setIsAddDeviceModalOpen] = React.useState(false);
  const [isTourVisible, setIsTourVisible] = React.useState(false);
  const [currTab, setCurrTab] = React.useState(() => {
    if (window) return window.location.pathname;
    return "/dashboard";
  });

  const onFooterItemClicked = (itemPath: string) => {
    if (!itemPath) return;

    navigate(itemPath);
    setCurrTab(itemPath);
  };

  const steps = FOOTER_ITEMS.map((item, idx) => ({
    title: item.tour.title,
    description: item.tour.description,
    target: () => refs.current[idx],
  }));

  useEffect(() => {
    const tourShownBefore = localStorage.getItem("tourShownBefore");
    if (!tourShownBefore || !JSON.parse(tourShownBefore)) {
      setIsTourVisible(true);
    }
    if (searchParams.get("selectedService")) {
      setIsAddDeviceModalOpen(true);
    }
  }, []);

  const closeTour = () => {
    localStorage.setItem("tourShownBefore", "true");
    setIsTourVisible(false);
  };

  return (
    <>
      <GlobalStyleCSS />

      <FooterWrapper>
        {FOOTER_ITEMS.map((item, idx) => (
          <FooterItemContainer
            key={item.path}
            selected={currTab.startsWith(item.path)}
            onClick={() => onFooterItemClicked(item.path)}
            ref={(el) => (refs.current[idx] = el)}
          >
            <FooterItem special={item.special} onClick={() => (item.special ? setIsAddDeviceModalOpen(true) : null)}>
              <img src={item.icon} alt={item.name} />
            </FooterItem>
          </FooterItemContainer>
        ))}
      </FooterWrapper>

      <AddDeviceModal isVisible={isAddDeviceModalOpen} onClose={() => setIsAddDeviceModalOpen(false)} />
      <Tour open={isTourVisible} onClose={closeTour} steps={steps} />
    </>
  );
};

export default Footer;
