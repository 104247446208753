import styled, { createGlobalStyle, css, keyframes } from "styled-components";
import { Button, Input, Progress } from "antd";
import { TfiReload } from "react-icons/tfi";

export const GlobalStyleCSS = createGlobalStyle`
  .ant-tour {
    inset: auto auto 90.5px 0px !important;
  }

  .ant-tour-title {
    font-size: 25px !important;
  }

  .ant-tour-description {
    font-size: 22px;
    margin-bottom: 5px;
  }

  .ant-tour-indicators {
    display: flex !important;
    align-items: center;
  }

  .ant-tour-indicator {
    width: 10px !important;
    height: 10px !important;
  }

  .ant-tour-footer .ant-tour-buttons button {
    height: 50px;
    width: 130px;
    font-size: 22px;
  }

  .ant-tour-inner svg {
    width: 20px;
    height: 20px;
  }

  .ant-tour-inner .ant-tour-close {
    top: 25px !important;
  }

  .ant-tag {
    display: flex;
    align-items: center;
    font-size: 17px;
    padding: 5px 15px;
    margin: 0px;
  }

  .ant-tour-content {
    width: 100vw;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100vw;
  overflow: scroll;
`;

export const Footer = styled.div`
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  background: #5b30ad;
  justify-content: space-around;
`;

export const FooterItemContainer = styled.div<{ selected?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => (props.selected ? `border-bottom: #fff solid 5px;` : ``)}
  transition: border 0.1s ease-in-out;
`;

export const FooterItem = styled.div<{ special?: boolean }>`
  width: 28px;
  height: 28px;
  ${(props) => (props.special ? `width: 45px; height: 45px;` : ``)};

  img {
    height: 100%;
    width: 100%;
  }
`;

export const Content = styled.div`
  height: calc(100vh - 70px);
  position: relative;
  padding: 10px 15px;
  background: #fafafc;
`;

export const GuideWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GuideText = styled.div`
  color: #fefefe;
  font-size: 32px;
  font-family: fantasy;
  position: relative;
  bottom: 100px;
`;

export const StyledHeading = styled.div`
  font-size: 40px;
  padding: 20px;
  font-weight: 400;
  color: #626262;
  padding: 0px 0px 20px 0px;
`;

export const DropdownWrapper = styled.div`
  margin-bottom: 20px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const SelectDurationText = styled.div`
  font-size: 20px;
  color: #000;
`;

export const SelectDeviceWrapper = styled.div`
  padding-bottom: 30px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ebebeb;
`;

export const DurationSelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 70px;
`;

export const SelectDeviceHeading = styled.div`
  text-align: center;
  font-size: 28px;
  color: #000;
  margin-bottom: 40px;
`;

export const ServicesDropdownWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  justify-content: space-between;
  background: #ebebeb;
  border-radius: 10px;
  box-sizing: border-box;

  p {
    color: #000;
    font-size: 20px;
  }

  .anticon-caret-down {
    font-size: 22px;
    color: #666;
  }
`;

export const StyledOption = styled.div`
  display: flex;
  gap: 5px;
  font-size: 20px;
`;

export const StyledOptionButton = styled(Button).attrs({ primary: true })``;

export const AddDeviceButtonWrapper = styled.div`
  margin-top: 20px;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #ebebeb;
  width: 100%;
  left: 0px;
  font-size: 22px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

export const DeviceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 126px);
  overflow: auto;
`;
export const LogoContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ebebeb;
  padding: 0px 5px;

  img {
    width: 100%;
  }
`;
export const DetailsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: calc(100% - 60px);
`;

export const Model = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
`;

export const ModelColorBox = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  background: ${(props) => props.color};
  border-radius: 3px;
`;

export const ModelName = styled.div`
  font-size: 13px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ModelSpecs = styled.div`
  position: absolute;
  top: 20px;
  left: 0px;
  font-size: 11px;
  font-weight: 500;
`;

export const Name = styled.div`
  font-weight: 800;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const Price = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
`;
export const DaysLeft = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
`;
export const DeviceCard = styled.div`
  min-height: 115px;
  width: 100%;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 28px 20px 45px 20px;
  position: relative;

  // yeh dekh bro
  .strip.notInStock {
    background: #ebebeb;
    color: #7d7d7d;
  }
`;

const scaleUpDown = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

export const Strip = styled.div<{ inStock?: boolean; isLoading: boolean }>`
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background: ${(props) => (props.inStock ? `#589058` : `#ebebeb`)};
  color: ${(props) => (props.inStock ? `#fff` : `#909090`)};
  text-align: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 12px;
  font-weight: 600;

  span {
    ${(props) =>
      props.isLoading || props.inStock
        ? css`
            animation: ${scaleUpDown} 0.5s infinite;
          `
        : ``};
  }
`;
export const ProgressBar = styled.div<{ trailColor: string }>`
  position: absolute;
  bottom: 0px;
  height: 4px;
  width: 100%;
  background: ${(props) => props.trailColor};
`;

export const ProgressStrip = styled.div<{ strokeColor: string; isFull: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  background: ${(props) => props.strokeColor};
  transition: width 3s ease-in-out;
  ${(props) => (props.isFull ? `width: 100%` : `width: 0px`)};
  ${(props) => (props.isFull ? `transition-duration: 0.5s` : `transition-duration: 3s`)};
`;

export const RemoveButton = styled.div`
  position: absolute;
  right: 20px;
  top: 35px;
`;
export const FilterWrapper = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  gap: 15px;
  overflow: auto;
  margin-bottom: 20px;
`;

export const Filter = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0px 25px;
  border-radius: 12px;
  font-weight: 600;
  background: ${(props) => (props.isActive ? `#5b30ad` : `#ebebeb`)};
  color: ${(props) => (props.isActive ? `#F3F4F8` : `#7d7d7d`)};

  span {
    width: max-content;
  }
`;

export const NoDataWrapper = styled.div`
  text-align: center;
  color: gray;
  font-size: 20px;
  margin: auto auto;
  span {
    gap: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-size: 13px;
    margin-top: 5px;
  }
`;

export const TimeoutOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-direction: column;
  font-size: 8px;
  gap: 10px;
`;

export const StyledTfiReload = styled(TfiReload)`
  width: 40px;
  height: 40px;
`;

export const StockDetectedFullScreen = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  gap: 30px;
  overflow: auto;

  >img {
    height: 50px;
  }

  >h1 {
    font-size: 32px;
    font-weight: 300;
  }

  span {
    width: max-content;
  }
 }
`;

export const StockDetectedCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StockDetectedLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  fit-content: true;

  img {
    width: 100%;
  }
`;

export const StockDetectedRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 50%;
  font-size: 22px;
  font-weight: 200;

  p {
    text-align: center;
  }
`;

export const StockDetectedCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 50%;
    text-align: start;
  }
`;

export const TimeLeftProgress = styled(Progress)`
  height: 20px;
  width: 20px;
`;

export const ProvideDetailsWrapper = styled.div``;

export const ProvideDetailsHeading = styled.div`
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 20px;
`;

export const StyledProvideDetailsInput = styled(Input)`
  width: 100%;
  margin-bottom: 20px;
`;
