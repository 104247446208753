import React, { useEffect } from "react";
import _get from "lodash.get";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Select, Tag, Tooltip, message } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import {
  SelectDeviceWrapper,
  SelectDeviceHeading,
  SelectService,
  SelectDevice,
  SelectDurationText,
  DurationSelectWrapper,
  AddDeviceButtonWrapper,
  ToolTipText,
  StyledQuestionCircleTwoTone,
  SelectPincode,
} from "./style";

import MobileModal from "../../../common/Components/MobileModal";
import apiEndPoints from "../../../constants/apiEndpoints";
import apiCaller from "../../../common/utils/apiCaller";
import { I_GET_SERVICES_DEVICES } from "../../../constants/apiEndPointsInterfaces";
import { POPULAR_PINCODES } from "../constants";

function AddDeviceModal(props: { isVisible: boolean; onClose: () => void }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [servicesDevices, setServicesDevices] = React.useState([]);
  const [userServices, setUserServices] = React.useState([]);

  const [purchasedSubscriptions, setPurchasedSubscriptions] = React.useState([]);
  const [notPurchasedSubscriptions, setNotPurchasedSubscriptions] = React.useState([]);

  const [selectedService, setSelectedService] = React.useState<number | null>(null);
  const [selectedDevice, setSelectedDevice] = React.useState<number | null>(null);

  const [pinCodeOptions, setPinCodeOptions] = React.useState([]);
  const [pinCodeValues, setPinCodeValues] = React.useState<string[]>([]);
  const [pinCodeText, setPinCodeText] = React.useState("");

  useEffect(() => {
    const selectedService = searchParams.get("selectedService");
    const selectedDevice = searchParams.get("selectedDevice");

    if (selectedService) setSelectedService(+selectedService);
    if (selectedDevice) setSelectedDevice(+selectedDevice);
  }, []);

  useEffect(() => {
    if (!props.isVisible) return;
    fetchServicesDevices();
  }, [props.isVisible]);

  async function fetchServicesDevices() {
    try {
      const {
        data: { userServices, servicesDevices },
      } = await apiCaller<I_GET_SERVICES_DEVICES>(
        apiEndPoints.GET_SERVICES_DEVICES.URL,
        apiEndPoints.GET_SERVICES_DEVICES.METHOD
      );

      setServicesDevices(servicesDevices);
      setUserServices(userServices);

      setPurchasedSubscriptions(
        userServices.map((obj) => ({
          label: obj.name,
          value: obj.id,
        }))
      );

      setNotPurchasedSubscriptions(
        servicesDevices
          .map((obj) => ({
            label: obj.name,
            value: obj.id,
          }))
          .filter((obj) => !userServices.find((_obj) => obj.value === _obj.id))
      );
    } catch (err) {
      console.log(err);
    }
  }

  function getDevicesForSelectedService() {
    const selectedServiceDevices = servicesDevices.find((obj) => obj.id === selectedService);
    if (!selectedServiceDevices) return [];

    return selectedServiceDevices.devices.map((device) => ({
      label: `${device.modelName} (${device.color}, ${device.spec})`,
      value: device.id,
    }));
  }

  function filterOption(inputValue, option) {
    if (typeof option.label !== "string") return true;
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  }

  async function addDevice() {
    try {
      const res = await apiCaller(apiEndPoints.ADD_DEVICE.URL, apiEndPoints.ADD_DEVICE.METHOD, {
        selectedDevice,
      });

      message.success("Device added successfully");
      props.onClose();
      navigate("/dashboard", { state: { deviceAdded: Math.random() } });
    } catch (err) {
      if (err instanceof Error) message.error(_get(err, "response.data.message", err.message));
    }
  }

  function openChooseProductScreen() {
    if (!window || !window.ReactNativeWebView) return;

    const selectedServiceDevices = servicesDevices.find((obj) => obj.id === selectedService);
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "openScreen",
        payload: {
          screenName: "CHOOSE_PRODUCT",
          screenData: {
            brand: selectedServiceDevices.name,
            url: selectedServiceDevices.homepageUrl,
          },
        },
      })
    );
  }

  // Some variables used for rendering
  const isPinCodeSupported = _get(
    userServices.find((obj) => obj.id === selectedService),
    "isPinCodeSupported",
    false
  );
  return (
    <MobileModal isOpen={props.isVisible} onClose={props.onClose}>
      <SelectDeviceWrapper>
        <SelectDeviceHeading>Choose Device</SelectDeviceHeading>

        <SelectService
          value={selectedService || "Choose a Service"}
          onChange={(value: number) => {
            const isNotSubscribed = notPurchasedSubscriptions.find((obj) => obj.value === value);
            if (isNotSubscribed) {
              navigate("/services");
              message.error("Please subscribe to this service to add a device");
              props.onClose();
            }

            setSelectedService(value);
            setSelectedDevice(null);
          }}
          onClick={(e) => e.stopPropagation()}
          options={[
            ...(purchasedSubscriptions.length
              ? [
                  {
                    label: "Your Purchased Subscriptions",
                    options: purchasedSubscriptions,
                  },
                ]
              : []),
            {
              label: <span style={{ color: "#5b30ad" }}>Not Purchased Subscription</span>,
              options: notPurchasedSubscriptions,
            },
          ]}
        />

        <SelectDevice
          value={selectedDevice}
          onChange={(value: number) => setSelectedDevice(value)}
          showSearch
          placeholder="Select a device"
          disabled={!!!selectedService}
          filterOption={filterOption}
          onClick={(e) => e.stopPropagation()}
          options={[
            {
              value: "Selected Manually",
              label: <div onClick={openChooseProductScreen}> Select Manually</div>,
            },
            ...getDevicesForSelectedService(),
          ]}
        />

        {isPinCodeSupported ? (
          <SelectPincode
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please enter pincode"
            onSearch={(value) => setPinCodeText(value)}
            onChange={(values: string[]) => {
              setPinCodeValues(values);
              setPinCodeText("");
            }}
            options={[
              ...pinCodeOptions,
              ...(pinCodeText ? [{ label: pinCodeText, value: pinCodeText }] : POPULAR_PINCODES),
            ]}
          />
        ) : null}
      </SelectDeviceWrapper>

      <SelectDurationText>
        <span>Duration</span>
        <Tooltip
          title={
            <div>
              <div>The selected device detection will expire in 10 days automatically</div>
              <br />
              <div>
                Don't worry we will remind you multiple times in last 3 days of expiration to reactivate this detection.
              </div>
              <br />
              <div>This helps us to keep our data clean.</div>
            </div>
          }
          color={"#722ed1"}
          trigger={["click", "hover"]}
        >
          <StyledQuestionCircleTwoTone twoToneColor="#5b30ad" />
        </Tooltip>
      </SelectDurationText>
      <DurationSelectWrapper>
        <Tag color={"#722ed1"}>
          <CheckCircleOutlined />
          <span>10 days</span>
        </Tag>
      </DurationSelectWrapper>

      <AddDeviceButtonWrapper type="primary" disabled={!selectedService || !selectedDevice} onClick={addDevice}>
        <span>Add device</span>
      </AddDeviceButtonWrapper>
    </MobileModal>
  );
}

export default AddDeviceModal;
