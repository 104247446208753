import React from "react";
import Navbar from "../Navbar";
import { howItWorksStepsData } from "../constants";
import Footer from "../Footer";

const HowItWorks = () => {
  return (
    <>
      <Navbar />
      <section className="py-10 bg-gray-50 sm:py-9 lg:py-12">
        <div className="px-4 mx-auto max-w-6xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-normal sm:text-4xl md:text-5xl">How it works ?</h2>
            <p className="mx-auto max-w-[700px] mt-4  text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
              Detect stock effortlessly with our user-friendly app in three simple steps.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            {/* left text right img */}
            <div className="grid grid-cols-1 sm:grid-cols-5">
              <div className=" p-4 sm:p-6 lg:p-10 flex flex-col justify-center  gap-3 col-span-3 text-center sm:text-left">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl -ml-1">Step 1</h2>
                <p className="d:text-xl/relaxed lg:text-base/relaxed xl:text-2xl/relaxed">
                  {howItWorksStepsData[0].description}
                </p>
              </div>
              <div className="flex items-center justify-center sm:justify-end order-first sm:order-last col-span-2">
                <img className="h-[600px] object-cover" src={howItWorksStepsData[0].image} alt="" />
              </div>
            </div>

            {/* right text left img */}
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <div className="flex items-center justify-center sm:justify-start ">
                <img className=" h-[600px] object-cover" src={howItWorksStepsData[1].image} alt="" />
              </div>
              <div className=" p-4 sm:p-6 lg:p-10 flex flex-col justify-center  gap-3 text-center sm:text-right">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl -ml-1">Step 2</h2>
                <p className="d:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  {howItWorksStepsData[1].description}
                </p>
              </div>
            </div>

            {/* left text right img */}
            <div className="grid grid-cols-1 sm:grid-cols-5">
              <div className=" p-4 sm:p-6 lg:p-10 flex flex-col justify-center  gap-3 col-span-3 text-center sm:text-left">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl -ml-1">Step 3</h2>
                <p className="d:text-xl/relaxed lg:text-base/relaxed xl:text-2xl/relaxed">
                  {howItWorksStepsData[2].description}
                </p>
              </div>
              <div className="flex items-center justify-center sm:justify-end order-first sm:order-last col-span-2">
                <img className="h-[600px] object-cover" src={howItWorksStepsData[2].image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HowItWorks;
