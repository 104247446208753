import Lottie from "lottie-react";
import { referralLottie } from "./constants";

const Referral = () => {
  return (
    <>
      <section className="bg-white dark:bg-gray-900 ">
        <div className="container flex flex-col items-center pb-[50px] md:px-4 md:py-12 mx-auto xl:flex-row max-w-7xl">
          <div className="flex justify-center xl:w-1/2">
            <Lottie className="w-[300px] h-[300px]" animationData={referralLottie} />
          </div>

          <div className="flex flex-col items-center px-5 sm:px-0 mt-6 xl:items-start xl:w-1/2 xl:mt-0">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold tracking-tight text-gray-800  dark:text-white">
              Refer Friends & Earn Money!
            </h2>

            <p className="block max-w-2xl mt-4 text-gray-500 dark:text-gray-300 sm:px-5 md:px-0">
              Receive direct cash rewards for every successful referral. Effortlessly redeem these rewards directly into
              your bank account without any hassle. There's no limit to your earnings—refer more, earn more.{" "}
            </p>
            <div className="grid gap-6 mt-8 sm:grid-cols-2">
              <div className="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                <svg
                  className="w-5 h-5 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span className="mx-3">No Complicated Points System</span>
              </div>

              <div className="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                <svg
                  className="w-5 h-5 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span className="mx-3">Unlimited Earning</span>
              </div>

              <div className="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                <svg
                  className="w-5 h-5 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span className="mx-3">Fast and Easy Redemption</span>
              </div>

              <div className="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                <svg
                  className="w-5 h-5 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>

                <span className="mx-3"> Unique Referral Link</span>
              </div>

              <div className="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                <svg
                  className="w-5 h-5 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span className="mx-3">Transparent Terms and Conditions</span>
              </div>

              <div className="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                <svg
                  className="w-5 h-5 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span className="mx-3">Track Your Earnings</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Referral;
