import { brands } from "../constants";
const Brands = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center lg:py-16 md:py-10">
        <div className="text-center max-w-7xl">
          <h2 className="text-3xl font-bold tracking-normal sm:text-4xl md:text-5xl">Supported Platforms </h2>
          <p className="mt-4 mx-auto max-w-[700px] px-[16px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
            Check out the platforms we feature for real-time stock detection, with an ongoing commitment to expanding
            our coverage.
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 m-5 mt-14 max-w-7xl">
          {brands.map((brand) => (
            <div className="m-5 md:m-10 flex justify-center items-center" key={brand.key}>
              <img className="w-36" src={brand.url} alt={brand.title} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Brands;
