import { useEffect, useState } from "react";
import Lottie from "lottie-react";

import AnimatedStockAlert from "./AnimatedStockAlert";
import BellLottie from "./lottie.json";
import LoadingLottie from "./loading.json";
import Navbar from "../Navbar";

import "./notif.css";
import Footer from "../Footer";

const Downloads = () => {
  const [isInStock, setIsInStock] = useState(false);

  function onStockStatusChange(status: boolean) {
    setIsInStock(status);
  }

  return (
    <>
      <Navbar />
      <div className="sm:h-screen  md:h-[calc(100vh-80px)] flex items-center justify-center">
        <section className="pb-10 sm:py-16 lg:py-24">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="grid items-center grid-cols-1 gap-8 lg:grid-cols-2 overflow-hidden">
              <div className="flex flex-col items-center justify-start w-full h-full px-10 lg:mt-16 pt-16 pb-24 lg:pb-0 lg:pt-0 small-zoom">
                <AnimatedStockAlert onStockStatusChange={onStockStatusChange} />
                <div
                  className={`notif ${isInStock ? "animated" : ""} ${`absolute z-[1]`}`}
                  onClick={() => setIsInStock(true)}
                >
                  <div className="mt-10 bg-[#ebebeb] rounded-2xl w-[380px] sm:w-[320px]">
                    <div className="flex justify-center items-center h-[60px] relative">
                      <div className="flex items-center justify-center p-3 absolute left-[0px] mt-[2px]">
                        <Lottie className="w-[70px] " animationData={BellLottie} />
                      </div>
                      <div className="ml-[35px] text-xl text-gray-600">Your Product is in Stock</div>
                    </div>
                  </div>
                </div>
                <div
                  className={`notif ${!isInStock ? "animated" : ""} ${`absolute z-[1]`}`}
                  onClick={() => setIsInStock(true)}
                >
                  <div className="mt-10 bg-[#ebebeb] rounded-2xl w-[315px] sm:w-[250px]">
                    <div className="flex justify-center items-center h-[60px] relative">
                      <div className="flex items-center justify-center p-3 absolute -left-[20px] mt-[2px]">
                        <Lottie className="w-[100px] " animationData={LoadingLottie} />
                      </div>
                      <div className="ml-[20px] text-xl text-gray-500">checking stock</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <h1 className="text-4xl font-bold text-black sm:text-6xl lg:text-7xl">
                  Never miss an <br /> in-stock product&nbsp;
                  <span className="relative inline-flex">
                    <span className="absolute inset-x-0 bottom-0 border-b-[30px] border-[#4ADE80]"></span>
                    <h1 className="relative text-4xl font-bold text-black sm:text-6xl lg:text-7xl">again.</h1>
                  </span>
                </h1>

                <p className="mt-8 text-base text-black sm:text-xl">
                  With Stock detector, You can get real-time notifications when your desired product comes back in
                  stock.
                </p>

                <div className="mt-10 flex justify-center lg:justify-start items-center sm:space-x-8 ">
                  <a
                    href="#"
                    title=""
                    className="inline-flex items-center justify-center w-full sm:w-auto px-10 py-4 text-base font-semibold text-white transition-all duration-200 bg-[#623eb5] hover:bg-[#876acc] focus:bg-[#876acc] rounded-xl"
                    role="button"
                  >
                    <svg
                      className="w-8 h-8 mr-2 -ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="white"
                        d="M12 29c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V29zM40 29c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V29zM22 40c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V40zM30 40c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-1.1.9-2 2-2s2 .9 2 2V40z"
                      ></path>
                      <path
                        fill="white"
                        d="M14 18v15c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V18H14zM24 8c-6 0-9.7 3.6-10 8h20C33.7 11.6 30 8 24 8zM20 13.6c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1C21 13.1 20.6 13.6 20 13.6zM28 13.6c-.6 0-1-.4-1-1 0-.6.4-1 1-1s1 .4 1 1C29 13.1 28.6 13.6 28 13.6z"
                      ></path>
                      <path
                        fill="white"
                        d="M28.3 10.5c-.2 0-.4-.1-.6-.2-.5-.3-.6-.9-.3-1.4l1.7-2.5c.3-.5.9-.6 1.4-.3.5.3.6.9.3 1.4l-1.7 2.5C29 10.3 28.7 10.5 28.3 10.5zM19.3 10.1c-.3 0-.7-.2-.8-.5l-1.3-2.1c-.3-.5-.2-1.1.3-1.4.5-.3 1.1-.2 1.4.3l1.3 2.1c.3.5.2 1.1-.3 1.4C19.7 10 19.5 10.1 19.3 10.1z"
                      ></path>
                    </svg>
                    Download App{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Downloads;
