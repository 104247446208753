import Hero from "./Hero";
import Brands from "./Brands";
import Features from "./Features";
import FAQ from "./FAQ";
import Referral from "./Referral";
import Contact from "./Contact";

import Navbar from "../Navbar";
import Footer from "../Footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Brands />
      <Features />
      <Referral />
      <FAQ />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
