const Contact = () => {
  return (
    <>
      <section className="py-10 bg-gray-100 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-none text-gray-900 sm:text-4xl lg:text-5xl">Contact us</h2>
            <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-500">
              Have questions or need assistance? We're here to help!
            </p>
          </div>

          <div className="max-w-5xl mx-auto mt-12 sm:mt-16">
            <div className="grid grid-cols-1 gap-6 px-8 text-center md:px-0 md:grid-cols-2">
              <div className="overflow-hidden bg-white rounded-xl">
                <div className="p-6">
                  <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>
                  </span>

                  <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Email</h2>
                  <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    Reach out to our dedicated support team by sending an email.
                  </p>
                  <a
                    href="mailto:nmnishant@nms.com"
                    className="cursor-pointer mt-2 text-sm text-blue-500 dark:text-blue-400"
                  >
                    support@nmnishant.com
                  </a>
                </div>
              </div>

              <div className="overflow-hidden bg-white rounded-xl">
                <div className="p-6">
                  <span className="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                    <img className="w-[20px]" src="/whatsapp.png" alt="" />
                  </span>

                  <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Live Chat</h2>
                  <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    Chat with our team for assistance over the whatsapp
                  </p>
                  <a
                    href="https://www.google.com"
                    className="cursor-pointer mt-2 text-sm text-blue-500 dark:text-blue-400"
                  >
                    Start new chat
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
