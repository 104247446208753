import { useState } from "react";
import { Link } from "react-router-dom";
import { navLinks } from "../constants";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-[#623eb5] text-white md:max-h-[80px]">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between">
          <div className="flex space-x-4">
            <div>
              <Link to="/" className="flex items-center py-5 px-2">
                <img className="h-10 w-11 grayscale invert " src="/sd.png" alt="Log" />
                <span className="font-bold text-2xl tracking-tighter">
                  Stock Detector<span className="text-3xl">.</span>
                </span>
              </Link>
            </div>
          </div>

          <div className="hidden md:flex items-center space-x-1">
            {navLinks.map((route) => (
              <Link
                key={route.key}
                to={route.link}
                className="py-5 px-3 hover:scale-105 transition-transform duration-300 ease-in-out"
              >
                {route.title}
              </Link>
            ))}
          </div>

          <div className="md:hidden flex items-center">
            <button className="mobile-menu-button" onClick={toggleMobileMenu}>
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={`mobile-menu text-center ${isMobileMenuOpen ? "" : "hidden"} md:hidden`}>
        {navLinks.map((route) => (
          <Link
            to={route.link}
            className="block py-2 px-4 text-sm hover:scale-105 "
            onClick={() => setMobileMenuOpen(false)}
          >
            {route.title}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
