export const FOOTER_ITEMS = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "/dashboard/home.png",
    tour: {
      title: "Home",
      description: "This is your home page, here you can see all your devices and their status.",
    },
  },
  {
    path: "/services",
    name: "Subscription",
    icon: "/dashboard/subscription.png",
    tour: {
      title: "Subscription",
      description: "Here you can see your subscription status and purchase new plan.",
    },
  },
  {
    path: null,
    name: "Add device",
    icon: "/dashboard/add-device.png",
    special: true,
    tour: {
      title: "Add device",
      description: "Click here to add a new device.",
    },
  },
  {
    path: "/referral",
    name: "Referral",
    icon: "/dashboard/referral.png",
    tour: {
      title: "Referral",
      description: "Here you can see your referral code and share it with your friends to earn money.",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "/dashboard/profile.png",
    tour: {
      title: "Profile",
      description: "Here you can see your profile and change your password.",
    },
  },
];

export const POPULAR_PINCODES = [
  {
    label: "110005 - Karol bagh (Delhi)",
    value: "110005",
  },
  {
    label: "125001 - Hisar (Haryana)",
    value: "125001",
  },
  {
    label: "110007 - Kamla Nagar (Delhi)",
    value: "110007",
  },
];
