import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import _get from "lodash.get";
import { Digit, OTPContainer, OTPIllustration, OTPLabel, DigitWrapper, ResendButton, ResendOTPWrapper } from "./style";
import apiCaller from "../../common/utils/apiCaller";
import apiEndPoints from "../../constants/apiEndpoints";
import useAuth from "../../hooks/useAuth";

const Index: React.FC = () => {
  const OTP_LENGTH = 6;
  const OTP_RESEND_TIME = 30;
  const navigate = useNavigate();
  const location = useLocation();
  const authData = useAuth();
  const phone = (location.state.phone as string) || "Error occured";

  const [otp, setOtp] = useState<string>("");
  const [canOTPBeSentAgain, setCanOTPBeSentAgain] = useState<boolean>(true);
  const [remainingOTPResendTime, setRemainingOTPResendTime] = useState<number>(OTP_RESEND_TIME);
  const [loading, setLoading] = useState<boolean>(false);
  const hiddenInput = useRef<HTMLInputElement | null>(null);

  const onKeypress = useCallback((e: any) => {
    if (e.key === "Backspace") return setOtp((prevOtp) => prevOtp.slice(0, -1));

    // Check if it is a number
    const isNumber = /^\d$/.test(e.key);
    if (!isNumber || loading) return;

    setOtp((prevOtp) => (prevOtp.length >= OTP_LENGTH ? prevOtp : prevOtp + e.key));
  }, []);

  const resendOTP = async () => {
    try {
      setLoading(true);
      await apiCaller(apiEndPoints.LOGIN.URL, apiEndPoints.LOGIN.METHOD, { phone });
      message.success("OTP sent successfully");
    } catch (err) {
      if (err instanceof Error) message.error(_get(err, "response.data.message", err.message));
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setLoading(true);
      await apiCaller(apiEndPoints.VERIFY_OTP.URL, "POST", { phone, otp });
      authData.isLoggedIn();
    } catch (err) {
      if (err instanceof Error) {
        message.error(_get(err, "response.data.message", err.message));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (otp.length === OTP_LENGTH) verifyOTP();
  }, [otp]);

  useEffect(() => {
    if (window) {
      window.document.body.addEventListener("keydown", onKeypress);
      window.document.body.addEventListener("paste", (event) => {
        event.preventDefault();
        const otp = event.clipboardData.getData("text");
        if (otp.length === OTP_LENGTH) setOtp(otp);
      });
    }

    return () => {
      if (window) window.document.removeEventListener("keydown", onKeypress);
    };
  }, []);

  useEffect(() => {
    if (canOTPBeSentAgain) {
      setRemainingOTPResendTime(OTP_RESEND_TIME);
      return;
    }

    const timeout = setTimeout(() => {
      setCanOTPBeSentAgain(true);
    }, OTP_RESEND_TIME * 1000);

    const interval = setInterval(() => {
      setRemainingOTPResendTime((prev) => prev - 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [canOTPBeSentAgain]);

  return (
    <>
      <OTPContainer>
        <OTPIllustration>
          <img src="/otp.avif" alt="" />
        </OTPIllustration>
        <OTPLabel>
          <div>Enter Verification Code</div>
          <p>We are automatically detecting a sms</p>
          <p>sent to your mobile number {phone.slice(-4).padStart(10, "*")}</p>
        </OTPLabel>
        <DigitWrapper>
          {[0, 1, 2, 3, 4, 5].map((index) => (
            <Digit hasDigit={otp[index] ? true : false} value={otp[index] || ""} />
          ))}
        </DigitWrapper>

        <ResendOTPWrapper>
          {canOTPBeSentAgain ? `Didn't Receive the OTP?` : `OTP can be sent again in ${remainingOTPResendTime} seconds`}
          <ResendButton
            loading={false}
            disabled={!canOTPBeSentAgain}
            onClick={() => {
              setCanOTPBeSentAgain(false);
              resendOTP();
            }}
          >
            RESEND OTP
          </ResendButton>
        </ResendOTPWrapper>
        <input ref={hiddenInput} type="text" style={{ position: "absolute", left: "-9999px" }} />
      </OTPContainer>
    </>
  );
};

export default Index;
