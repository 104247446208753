import styled from "styled-components";

import { Button } from "antd";
export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  font-family: sans-serif;
`;

export const HeroSection = styled.div`
  display: flex;
  /* justify-content: space-evenly; */
  padding: 10px;
`;

export const TotalEarned = styled.div`
  flex: 1;
  padding: 5px;
  text-align: left;
  margin-top: 50px;

  h1 {
    color: gray;
    font-size: 12px;
    text-align: left;
  }
`;

export const IllustrationWrapper = styled.div`
  flex: 1.5;
  margin: auto auto;
`;

export const RefferalImage = styled.img`
  max-width: 80%;
  height: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledButton = styled(Button)``;
interface TabItemProps {
  isActive: boolean;
}

export const TabItem = styled.div<TabItemProps>`
  flex: 1;
  border-bottom: ${(props) => (props.isActive ? "3px solid #683cbc" : "none")};
  color: ${(props) => (props.isActive ? "#683cbc" : "gray")};
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  cursor: pointer;
  padding: 9px;
  text-align: center;

  &:hover {
    color: #683cbc;
  }
`;

export const TotalAmount = styled.div`
  color: #683cbc;
  font-size: 35px;
  font-weight: 900;
  span {
    font-size: 28px;
  }
`;

// referral card

export const ReferralStatusWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const Card = styled.div`
  text-align: center;
  margin: 15px 20px;
  border: grey solid 1px;
  border-radius: 10px;
  background-color: #683cbc;
  color: white;
  padding: 15px 20px 20px;
`;
export const Name = styled.div`
  font-size: 17px;
  margin-bottom: 3px;
  font-weight: bold;
`;
export const Result = styled.div`
  span {
    font-weight: 900;
  }
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 7px;
`;
export const Requirements = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 20px;
  font-size: 14px;
`;
export const Item = styled.div`
  display: flex;
  p {
    margin-left: 5px;
  }
`;

// export const StatusButton = styled.div`
//   position: relative;

//   background: #78dcec;
//   color: #683cbc;
//   font-weight: 900;
//   font-size: 15px;
//   padding: 10px;
//   border-radius: 5px;

//   &:before {
//     content: "";
//     position: absolute;
//     top: -50%;
//     width: 50px;
//     height: 50px;
//     left: 15%;
//     background-image: url("https://pngfre.com/wp-content/uploads/Trophy-10.png");
//     background-size: cover;
//     z-index: 1;
//   }
// `;

// Original StatusButton component without the image
export const StatusButtonWithoutImage = styled.div`
  position: relative;
  background: #78dcec;
  color: #683cbc;
  font-weight: 900;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
`;

// New component with the image
// export const StatusButtonWithImage = styled.div`
//   position: relative;
//   background: #78dcec;
//   color: #683cbc;
//   font-weight: 900;
//   font-size: 15px;
//   padding: 10px;
//   border-radius: 5px;

//   &:before {
//     content: "";
//     position: absolute;
//     /* top: -50%; */
//     top: -25%;
//     width: 50px;
//     height: 50px;
//     left: 10%;
//     background-image: url("/referral/finalTrophy.png");
//     background-size: cover;
//     z-index: 1;
//   }
// `;

export const StatusButtonWithImage = styled.div`
  background: #78dcec;
  color: #683cbc;
  font-weight: 900;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 50px;

    img {
      position: absolute;
      width: 45px;
      height: 45px;
      z-index: 1;
      bottom: -5px;
      left: calc(50% - 120px);
    }
  }
`;

//

export const InviteSection = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #96efff;
  margin: 20px 20px 10px;
  border-radius: 10px;

  img {
    width: 60px;
    height: 65px;
    margin-right: 10px;
  }
  h1 {
    color: #7752fe;
    font-size: 16px;
    font-weight: 800;
    margin: 0 0 0 10px;
  }
`;

// RewardsCard

export const RewardCardWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  color: white;
  height: 150px;
  background: #683cbc;
  margin: 15px 20px;
  border: grey solid 1px;
  border-radius: 10px;
  padding: 5px;
  position: relative;
`;
export const StyledOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLockText = styled.p`
  font-size: 15px;
  font-weight: bold;
`;

export const QuizButton = styled.div`
  background: #78dcec;
  color: #683cbc;
  font-weight: 900;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
`;

export const StyledContainer = styled.div`
  margin: auto auto;
  flex: 45%;
`;

export const StyledImage = styled.img`
  width: 70px;
  margin-top: 10px;
`;

interface StyledTextProps {
  fontWeight?: string;
  marginTop?: string;
  size?: string;
}
export const StyledText = styled.p<StyledTextProps>`
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.size || "15px"};
  margin-top: ${(props) => props.marginTop || "5px"};
`;
export const PlayText = styled.div`
  font-size: 15px;
`;
export const StyledButtonsContainer = styled.div`
  margin: auto auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 55%;
  gap: 4px;
`;

// export const StyledQuizButton = styled.button<{
//   marginBottom: string;
// }>`
export const StyledQuizButton = styled.div`
  width: auto;
  /* margin-right: 15px; */
  margin: 5px 10px;
  background: #78dcec;
  color: #683cbc;
  font-weight: 900;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export const StyledRewardedImage = styled.img`
  width: 105px;
  margin-top: 10px;
  margin: 10px;
`;
export const StyledRewardedText = styled.p`
  font-weight: bold;
  /* color: gray; */
  font-size: 15px;
  margin-right: 20px;
  span {
    display: block;
    margin-top: 5px;
    color: white;
    font-size: 35px;
  }
`;

// Popout

export const ReferralInfo = styled.div`
  margin-top: 5px;
  padding-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
`;
export const ReferralImage = styled.img`
  max-width: 350px;
`;

export const Rule = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin: 20px;
  display: flex;
  gap: 1rem;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    color: white;
    background-color: #683cbc;
    border-radius: 100%;
  }
`;
export const RulesWrapper = styled.div`
  text-align: left;
  padding: 15px;
`;

export const ReferralButtonWrapper = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const CopyButton = styled.div`
  width: fit-content;
  position: absolute;
  right: 25px;
  color: #683cbc;
  cursor: pointer;
`;

export const CopyReferralCode = styled.div`
  background-color: #efebf8;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  color: black;
  width: 100%;
  display: flex;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 25px;
  box-sizing: border-box;
  padding-left: 30px;
`;
export const ShareButton = styled.div`
  background-color: #683cbc;
  text-align: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  padding: 15px;
  border-radius: 25px;
  box-sizing: border-box;
  position: relative;
  img {
    position: absolute;
    width: 30px;
    left: 20px;
    filter: invert();
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const ReferralOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(13px);
  z-index: 2;
  rgba: (0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #fff;
  font-weight: 100;
`;
