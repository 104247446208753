import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Progress, Space, Tooltip, message } from "antd";
import _get from "lodash.get";
import moment from "moment";
import { BsExclamationCircleFill } from "react-icons/bs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  Footer,
  Wrapper,
  Content,
  StyledHeading,
  FilterWrapper,
  Filter,
  DeviceCardContainer,
  DeviceCard,
  DetailsContainer,
  LogoContainer,
  NameContainer,
  Name,
  Model,
  Price,
  DaysLeft,
  RemoveButton,
  Strip,
  NoDataWrapper,
  TimeoutOverlay,
  StyledTfiReload,
  ProgressBar,
  ProgressStrip,
  StockDetectedFullScreen,
  StockDetectedRight,
  StockDetectedLeft,
  StockDetectedCard,
  ModelColorBox,
  ModelName,
  ModelSpecs,
  TimeLeftProgress,
  StockDetectedCardsWrapper,
  ProvideDetailsWrapper,
  ProvideDetailsHeading,
  StyledProvideDetailsInput,
} from "./style";
import { CloseOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import MobileModal from "../../common/Components/MobileModal";
import apiCaller from "../../common/utils/apiCaller";
import apiEndPoints from "../../constants/apiEndpoints";
import AddDeviceModal from "../Footer/AddDeviceModal";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { I_GET_ADDED_DEVICES, I_GET_SERVICES_LIST } from "../../constants/apiEndPointsInterfaces";

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

function Dashboard() {
  const authData = useAuth();
  const location = useLocation();
  const timer = useRef(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [state, setState] = useState("");
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);

  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progressStatus, setProgressStatus] = useState(-1);
  const [activeFilter, setActiveFilter] = useState(-1);
  const [filters, setFilters] = useState([]);
  const [filteredDevicesList, setFilteredDevicesList] = useState([]);
  const [stockDetectedPhones, setStockDetectedPhones] = useState([]);

  async function getServicesList() {
    try {
      const res = await apiCaller<I_GET_SERVICES_LIST>(
        apiEndPoints.GET_SERVICES_LIST.URL,
        apiEndPoints.GET_SERVICES_LIST.METHOD
      );
      setFilters([{ id: -1, name: "All" }, ...res.data]);
    } catch (err) {
      if (err instanceof Error) message.error(_get(err, "response.data.message", err.message));
    }
  }

  async function getAddedDevices() {
    try {
      setLoading(true);
      const res = await apiCaller<I_GET_ADDED_DEVICES>(
        apiEndPoints.GET_ADDED_DEVICES.URL,
        apiEndPoints.GET_ADDED_DEVICES.METHOD
      );
      if (!res.data.devices.length) return;

      const devices = res.data.devices.map((obj) => {
        const remainingTime = moment.duration(moment(obj.validTill).diff(moment()));
        const timeLeft = remainingTime.days() * 24 + remainingTime.hours();

        return {
          id: obj.device.id,
          serviceId: obj.device.service.id,
          serviceCoverLogo: obj.device.service.coverLogo,
          brandName: obj.device.service.name,
          brandLogoURL: obj.device.service.logo,
          name: obj.device.modelName,
          color: obj.device.color,
          specs: obj.device.spec,
          price: obj.device.price,
          image: obj.device.image,
          styleColor: obj.device.styleColor,
          inStock: obj.device.isInStock,
          validTill: obj.validTill,
          timeLeft,
        };
      });

      if (devices.length && !authData.user.name) setIsUserDetailsModalOpen(true);
      setDevices(devices);
      setStockDetectedPhones(devices.filter((device) => device.inStock));
      await wait(500);
    } catch (err) {
      if (err instanceof Error) message.error(_get(err, "response.data.message", err.message));
    }

    setLoading(false);
    setProgressStatus(100);
  }

  async function deleteDevice(selectedDeviceId: number) {
    try {
      clearTimeout(timer.current);
      setLoading(true);
      setProgressStatus(-1);

      await apiCaller(apiEndPoints.REMOVE_DEVICE.URL, apiEndPoints.REMOVE_DEVICE.METHOD, { selectedDeviceId });
      message.success("Device removed successfully");
    } catch (err) {
      if (err instanceof Error) message.error(_get(err, "response.data.message", err.message));
    } finally {
      getAddedDevices();
    }
  }

  async function reactivateDevice(deviceId: number) {
    try {
      clearTimeout(timer.current);
      setLoading(true);
      setProgressStatus(-1);

      await apiCaller(apiEndPoints.REACTIVATE_DEVICE.URL, apiEndPoints.REACTIVATE_DEVICE.METHOD, { deviceId });
      message.success("Device reactivated");
    } catch (err) {
      if (err instanceof Error) message.error(_get(err, "response.data.message", err.message));
    } finally {
      getAddedDevices();
    }
  }

  async function setUserDetails() {
    const API = apiEndPoints.SET_USER_DETAILS;
    const res = await apiCaller(API.URL, API.METHOD, { firstName, lastName, state });
    setIsUserDetailsModalOpen(false);
    if (window) window.location.reload();
  }

  useEffect(() => {
    getAddedDevices();
    getServicesList();
  }, []);

  useEffect(() => {
    if (location.state && location.state.deviceAdded) {
      clearTimeout(timer.current);
      setProgressStatus(-1);
      getAddedDevices();
    }
  }, [location.state]);

  useEffect(() => {
    if (progressStatus === 0) timer.current = setTimeout(getAddedDevices, 3000);
    if (progressStatus === 100) timer.current = setTimeout(() => setProgressStatus(0), 500);
  }, [progressStatus]);

  useEffect(() => {
    console.log({ activeFilter });
    if (activeFilter === -1) {
      setFilteredDevicesList([...devices]);
      return;
    }

    setFilteredDevicesList(devices.filter((device) => device.serviceId === activeFilter));
  }, [activeFilter, devices]);

  useEffect(() => {
    // if (!stockDetectedPhones.length) player.current.stop();
    // else player.current.play();
  }, [stockDetectedPhones]);

  return (
    <Wrapper>
      <Content>
        <StyledHeading>Dashboard</StyledHeading>
        <FilterWrapper>
          {filters.map((filter) => (
            <Filter key={filter.id} isActive={activeFilter === filter.id} onClick={() => setActiveFilter(filter.id)}>
              <span>{filter.name}</span>
            </Filter>
          ))}
        </FilterWrapper>
        <DeviceCardContainer>
          {devices.length === 0 ? (
            <NoDataWrapper>
              <span>
                <BsExclamationCircleFill />
                No device to show.
              </span>
              <h1>Click on + button to add Device.</h1>
            </NoDataWrapper>
          ) : null}

          {filteredDevicesList.length
            ? filteredDevicesList.map((device) => (
                <DeviceCard key={device.id}>
                  <DetailsContainer>
                    <LogoContainer>
                      <img src={device.brandLogoURL} alt="" />
                    </LogoContainer>
                    <NameContainer>
                      <Name>
                        {device.styleColor ? (
                          <Tooltip title={device.color} trigger={["click", "hover"]}>
                            <ModelColorBox color={device.styleColor} />
                          </Tooltip>
                        ) : null}
                        {device.brandName}
                      </Name>
                      <Model>
                        <ModelName>{device.name[0].toUpperCase() + device.name.slice(1)}</ModelName>
                        <ModelSpecs>
                          {device.specs ? `${device.specs.replace(/[\(\)]/g, "")} | ` : ``}
                          {device.color}
                        </ModelSpecs>
                        <ModelSpecs style={{ top: "45px" }}>
                          ₹ {new Intl.NumberFormat("en-IN").format(device.price)}
                        </ModelSpecs>
                        <ModelSpecs style={{ top: "42px", right: "0px", left: "auto" }}>
                          <TimeLeftProgress size={12} type="circle" percent={(device.timeLeft / 240) * 100} />
                          {device.timeLeft > 24 ? `${Math.ceil(device.timeLeft / 24)} days` : `${device.timeLeft} hrs`}
                        </ModelSpecs>
                      </Model>
                    </NameContainer>
                    <RemoveButton onClick={() => deleteDevice(device.id)}>
                      <CloseOutlined />
                    </RemoveButton>
                  </DetailsContainer>
                  <Strip
                    inStock={device.inStock}
                    isLoading={moment(device.validTill).diff(moment()) > 0 ? loading : false}
                  >
                    <span>{device.inStock ? "IN STOCK" : "NOT IN STOCK"}</span>
                    {moment(device.validTill).diff(moment()) > 0 ? (
                      <ProgressBar trailColor={!device.inStock ? "#fafafa" : "#7ba650"}>
                        <ProgressStrip
                          strokeColor={!device.inStock ? "#c6c6c6" : "#457931"}
                          isFull={progressStatus === 100}
                        />
                      </ProgressBar>
                    ) : null}
                  </Strip>

                  {moment(device.validTill).diff(moment()) <= 0 && (
                    <TimeoutOverlay>
                      <StyledTfiReload onClick={() => reactivateDevice(device.id)} />
                      <h1>Reactivate</h1>
                    </TimeoutOverlay>
                  )}
                </DeviceCard>
              ))
            : null}

          {devices.length && filteredDevicesList.length === 0 ? (
            <NoDataWrapper>
              <span>
                <BsExclamationCircleFill />
                No devices match the filter.
              </span>
            </NoDataWrapper>
          ) : null}
        </DeviceCardContainer>
      </Content>

      <MobileModal
        isOpen={isUserDetailsModalOpen}
        onClose={() => {
          message.error("Please fill profile details");
        }}
      >
        <ProvideDetailsWrapper>
          <ProvideDetailsHeading>Provide details</ProvideDetailsHeading>
          <StyledProvideDetailsInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First name"
            minLength={2}
            addonBefore={<UserOutlined className="site-form-item-icon" />}
          />
          <StyledProvideDetailsInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last name"
            minLength={2}
            addonBefore={<UserOutlined className="site-form-item-icon" />}
          />
          <StyledProvideDetailsInput
            value={state}
            onChange={(e) => setState(e.target.value)}
            placeholder="State / City"
            minLength={2}
            addonBefore={<HomeOutlined />}
          />
          <Button
            style={{ height: "35px", width: "100%" }}
            type="primary"
            onClick={() => {
              if (firstName.length < 2 || lastName.length < 2 || state.length < 2) {
                return message.error("Please enter valid data");
              }

              setUserDetails();
            }}
          >
            Submit
          </Button>
        </ProvideDetailsWrapper>
      </MobileModal>
      {false && (
        <MobileModal isOpen={false} onClose={() => {}} style={{ background: "#279f0e" }} crossColor={"#fff"}>
          <StockDetectedFullScreen>
            <h1>Stock Detected</h1>
            <StockDetectedCardsWrapper>
              {stockDetectedPhones.map((device) => (
                <>
                  {stockDetectedPhones.length === 1 ? <img src={device.serviceCoverLogo} /> : null}
                  <StockDetectedCard>
                    <StockDetectedLeft>
                      <img src={device.image} alt={`Image of ${device.name}`} />
                    </StockDetectedLeft>
                    <StockDetectedRight>
                      <span>{device.name}</span>
                      <span>{device.specs}</span>
                      <p>{device.color}</p>
                    </StockDetectedRight>
                  </StockDetectedCard>
                </>
              ))}
            </StockDetectedCardsWrapper>
          </StockDetectedFullScreen>
        </MobileModal>
      )}
    </Wrapper>
  );
}

export default Dashboard;
