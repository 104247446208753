import React from "react";

const LockSVG = ({ size = "30px" }) => {
  return (
    <svg
      style={{ width: size, height: size, margin: "auto auto" }}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="white"
      viewBox="0 0 256 256"
    >
      <path d="M128,112a28,28,0,0,0-8,54.83V184a8,8,0,0,0,16,0V166.83A28,28,0,0,0,128,112Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,128,152Zm80-72H176V56a48,48,0,0,0-96,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM96,56a32,32,0,0,1,64,0V80H96ZM208,208H48V96H208V208Z"></path>
    </svg>
  );
};

export default LockSVG;
