import { Link } from "react-router-dom";
import { navLinks } from "../constants";

const Footer = () => {
  return (
    <>
      <footer className="bg-white dark:bg-gray-900">
        <div className="container flex flex-col items-center justify-between px-6 pb-8 sm:py-8 mx-auto lg:flex-row">
          <div>
            <Link to="/" className="flex items-center py-5 px-2">
              <span className="font-bold text-2xl tracking-tighter">
                Stock Detector<span className="text-3xl">.</span>
              </span>
            </Link>
          </div>

          <div className="flex flex-wrap items-center justify-center gap-4 mt-6 lg:gap-6 lg:mt-0">
            {navLinks.map(({ key, title, link }) => (
              <Link
                key={key}
                to={link}
                className="text-sm text-gray-600 transition-colors duration-300 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
              >
                {title}
              </Link>
            ))}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
