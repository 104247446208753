import React from "react";
import LockSVG from "./LockSVG";
import {
  RewardCardWrapper,
  StyledOverlay,
  StyledLockText,
  StyledContainer,
  StyledImage,
  StyledText,
  StyledButtonsContainer,
  StyledQuizButton,
  StyledRewardedImage,
  StyledRewardedText,
  PlayText,
} from "./style";
interface UserProps {
  isLocked: boolean;
  isRewarded: boolean;
  amount: string;
}

const RewardCard: React.FC<UserProps> = (user) => {
  return (
    <>
      {user.isRewarded ? (
        <RewardCardWrapper>
          <StyledContainer>
            <StyledRewardedImage src="/referral/trophy.png" alt="Reward" />
          </StyledContainer>
          <StyledButtonsContainer>
            <StyledRewardedText>
              You Won<span> ₹{500}</span>
            </StyledRewardedText>
          </StyledButtonsContainer>
        </RewardCardWrapper>
      ) : (
        <RewardCardWrapper>
          {user.isLocked && (
            <StyledOverlay>
              <div>
                <LockSVG size="50px" />
                <StyledLockText>This is Locked</StyledLockText>
              </div>
            </StyledOverlay>
          )}
          <StyledContainer>
            {/* <StyledImage src="https://pngfre.com/wp-content/uploads/Trophy-10.png" alt="Reward" /> */}
            <StyledImage src="/referral/trophy.png" alt="Reward" />

            <StyledText fontWeight="500" size="12px" >To Unlock Reward Play</StyledText>
            <StyledText fontWeight="900" marginTop="5px">
              Quiz
            </StyledText>
          </StyledContainer>

          <StyledButtonsContainer>
            <PlayText>Play</PlayText>
            <StyledQuizButton>Live Paid Quiz</StyledQuizButton>
            <StyledQuizButton>Free Demo Quiz</StyledQuizButton>
          </StyledButtonsContainer>
        </RewardCardWrapper>
      )}
    </>
  );
};

export default RewardCard;
