import { features } from "../constants";

const Features = () => {
  return (
    <>
      <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-normal sm:text-4xl md:text-5xl">Features</h2>
            <p className="mx-auto max-w-[700px] mt-4  text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
              Take charge of your shopping journey! Experience a smarter way to shop with our incredible and exclusive
              features.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-20 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
            {features.map((feature) => (
              <div className="w-full px-4">
                <div className="mb-  transition-shadow duration-500 rounded-[20px] bg-white p-10 shadow-2 hover:shadow-lg dark:bg-dark-2 md:px-7 xl:px-10  h-[280px] md:h-[320px]">
                  <div className="mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                    <img src={feature.url} alt="" />
                  </div>
                  <h4 className="mb-[14px] text-2xl font-semibold text-dark dark:text-white">{feature.heading}</h4>
                  <p className="text-body-color dark:text-dark-6">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
