import React, { useState } from "react";
import { Button, Typography } from "antd";
import { LeftCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import Component1 from "./Refferals";
import Component2 from "./Rewards";
import { ReferralWrapper, Header, BackBtnWrapper, HeadingText } from "../styles";
import {
  HeroSection,
  TotalEarned,
  IllustrationWrapper,
  RefferalImage,
  TabItem,
  TotalAmount,
  ButtonWrapper,
  ReferralOverlay,
} from "./style";

const { Title } = Typography;

const totalRewardsEarned = 5000;

const App = () => {
  const [currentComponent, setCurrentComponent] = useState("Component1");

  const handleButtonClick = (component: string) => {
    setCurrentComponent(component);
  };

  return (
    <ReferralWrapper>
      <Header>
        <BackBtnWrapper>
          <LeftCircleOutlined />
        </BackBtnWrapper>
        <HeadingText>Reffer & Earn</HeadingText>
      </Header>

      <HeroSection>
        <TotalEarned>
          <h1>Total Rewards Earned</h1>
          <TotalAmount>
            <span>₹ </span>
            {totalRewardsEarned}
          </TotalAmount>
        </TotalEarned>
        <IllustrationWrapper>
          <RefferalImage
            src="https://img.freepik.com/free-vector/people-making-money-from-referral-concept-illustration_52683-22927.jpg?w=2000&t=st=1701329490~exp=1701330090~hmac=dfec944ce7472c9930df26e39e467b61c1e26bd18e7192a5b4fdb72ead0a96a2"
            alt="Reward"
          />
        </IllustrationWrapper>
      </HeroSection>

      <ButtonWrapper>
        <TabItem isActive={currentComponent === "Component1"} onClick={() => handleButtonClick("Component1")}>
          My Refferals
        </TabItem>

        <TabItem isActive={currentComponent === "Component2"} onClick={() => handleButtonClick("Component2")}>
          Rewards
        </TabItem>
      </ButtonWrapper>

      {currentComponent === "Component1" ? <Component1 /> : <Component2 />}
      <ReferralOverlay>Coming Soon!!!</ReferralOverlay>
    </ReferralWrapper>
  );
};

export default App;
