import { Method } from "../common/utils/apiCaller";

interface IApiEndPoints {
  [key: string]: {
    URL: string;
    METHOD: Method;
  };
}

const apiEndPoints: IApiEndPoints = {
  LOGIN: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`,
    METHOD: "POST",
  },
  VERIFY_OTP: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/auth/verifyOTP`,
    METHOD: "POST",
  },
  GET_USER_INFO: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/auth/userInfo`,
    METHOD: "GET",
  },
  GET_SERVICES_LIST: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/getServicesList`,
    METHOD: "GET",
  },
  GET_ADDED_DEVICES: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/getUserAddedDevices`,
    METHOD: "GET",
  },
  GET_SERVICES_DEVICES: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/getServicesDevices`,
    METHOD: "GET",
  },
  ADD_DEVICE: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/addDevice`,
    METHOD: "POST",
  },
  REMOVE_DEVICE: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/removeDevice`,
    METHOD: "POST",
  },
  REACTIVATE_DEVICE: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/reactivateDevice`,
    METHOD: "POST",
  },
  GET_SERVICES_PLANS: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/services/plans`,
    METHOD: "GET",
  },
  PAY: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/payment/pay`,
    METHOD: "POST",
  },
  SET_USER_DETAILS: {
    URL: `${process.env.REACT_APP_BASE_URL}/api/v1/auth/setUserInfo`,
    METHOD: "POST",
  },
};

export default apiEndPoints;
