import styled from "styled-components";
import { Button } from "antd";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  font-family: sans-serif;
  padding-top: 10px;
`;

export const Header = styled.div`
  height: 50px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Body = styled.div<{ isFooterVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0px 15px;
  ${(props) => (props.isFooterVisible ? `height: calc(100% - 66px - 50px);` : `height: calc(100% - 66px);`)}
  box-sizing: border-box;
  overflow: scroll;
`;

export const Footer = styled.div`
  height: 50px;
  padding: 0px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1677ff;
  color: #fff;
`;

export const BackBtnWrapper = styled.div`
  font-size: 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeadingText = styled.div`
  font-size: 16px;
  flex-grow: 1;
  color: #656363;
  font-weight: 600;
`;

export const PriceText = styled.div`
  font-size: 16px;
`;

export const StyledHeading = styled.div`
  font-size: 40px;
  padding: 20px;
  font-weight: 400;
  color: #626262;
  padding: 0px 15px 20px 15px;
`;

export const ServiceTypeModalHeader = styled.div`
  font-size: 22px;
`;

export const ServerTypeContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ServerTypeCard = styled.div`
  height: 350px;
  flex: 1;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  position: relative;
`;

export const ServiceTypeCardHeader = styled.div<{ simple?: boolean }>`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  background: #4869bd;
  color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  img {
    margin-left: 10px;
    width: 20px;
    height: 25px;
  }

  ${(props) => {
    if (!props.simple) return;

    return `
      border-radius: 20px;
      height: 80px;
    `;
  }}
`;

export const ServiceTypeCardBody = styled.div`
  flex: 1;
  padding: 10px;
`;

export const ServiceTypeCardBodyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #5b5b5b;
`;

export const ServiceTypeCardFooter = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: #626262;
  background: #e6e6e6;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const ServerTypeCardPrice = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
`;

export const KnowMore = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #4869bd;
  text-decoration: underline;
  text-align: end;
`;

export const StyledButton = styled(Button)`
  border: 1px solid #fff;
`;
