export const navLinks = [
  {
    key: 1,
    title: "Home",
    link: "/",
  },
  {
    key: 2,
    title: "How it works",
    link: "/how-it-works",
  },
  {
    key: 3,
    title: "Downloads",
    link: "/downloads",
  },
];

export const howItWorksStepsData = [
  {
    step: 1,
    title: "Click on the plus icon",
    description: "Add a device to track. Select the brand and the model according to your need.",
    image: "/step1.png",
  },
  {
    step: 2,
    title: "Once you click on Add Device",
    description:
      "The tracking of the product gets started. Just sit back and relax. Once we find stock, we'll notify you.",
    image: "/step2.png",
  },
  {
    step: 3,
    title: "If the item's stock is detected",
    description: "You'll be notified with audio and visual cues.",
    image: "/step3.png",
  },
];

export const brands = [
  {
    key: 1,
    title: "Croma",
    url: "https://hindubabynames.info/wp-content/themes/hbn_download/download/e-commerce-companies/croma-logo.png",
  },
  {
    key: 2,
    title: "Realme",
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Realme-realme-_logo_box-RGB-01_with_out_back_ground.svg/2560px-Realme-realme-_logo_box-RGB-01_with_out_back_ground.svg.png",
  },
  {
    key: 3,
    title: "Oneplus",
    url: "https://1000logos.net/wp-content/uploads/2022/11/OnePlus-Logo.png",
  },
  {
    key: 4,
    title: "Samsung",
    url: "https://www.freepnglogos.com/uploads/samsung-logo-text-png-1.png",
  },
  {
    key: 5,
    title: "Flipkart",
    url: "https://logos-world.net/wp-content/uploads/2020/11/Flipkart-Logo-700x394.png",
  },
  {
    key: 6,
    title: "Amazon",
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/2560px-Amazon_logo.svg.png",
  },
];

export const features = [
  {
    id: 1,
    heading: "Real-time detection",
    description:
      "Data updated in seconds with thousands of requests processed daily for swift and accurate information.",
    url: "https://cdn-icons-png.flaticon.com/512/8727/8727750.png",
  },
  {
    id: 2,
    heading: "Instant Alerts",
    description: "Receive quick notifications, ensuring you never miss out on products.",
    url: "https://cdn-icons-png.flaticon.com/512/1182/1182718.png",
  },
  {
    id: 3,
    heading: "User-Friendly Interface",
    description: "Simple and user-friendly platform suitable for people of all ages.",
    url: "https://cdn-icons-png.flaticon.com/512/3858/3858633.png",
  },
  {
    id: 4,
    heading: "24/7 Monitoring",
    description: "Our system works non-stop, keeping you informed round the clock.",
    url: "https://cdn-icons-png.flaticon.com/512/189/189202.png",
  },
  {
    id: 5,
    heading: "Automatic Updates",
    description: "Seamless automatic updates, ensuring continuous performance.",
    url: "https://cdn-icons-png.flaticon.com/512/189/189249.png",
  },
  {
    id: 6,
    heading: "Cash Rewards",
    description: "Earn real cash rewards directly redeemable into your bank account.",
    url: "https://cdn-icons-png.flaticon.com/512/552/552843.png",
  },
];

export const questionsSet = [
  {
    key: 1,
    question: "What is Real-Time Stock Detection?",
    answer:
      "Real-Time Stock Detection is our technology that scans websites continuously, providing instant updates on product availability.",
  },
  {
    key: 2,
    question: "How do Instant Alerts work?",
    answer:
      "Instant Alerts notify you immediately when there are changes in stock, ensuring you stay informed and can act promptly.",
  },
  {
    key: 3,
    question: "Is 24/7 Monitoring truly continuous?",
    answer: "Yes, our system operates 24/7, ensuring you are always aware of stock changes, day or night.",
  },
  {
    key: 4,
    question: "How do Bank-Redeemable Rewards work?",
    answer:
      "Earned cash rewards are directly redeemable into your bank account. Additionally, there is no limit on the rewards earned, so refer as many people as you like.",
  },
  {
    key: 5,
    question: "Can I use the platform on multiple devices?",
    answer: "No, currently, this only works with one device.",
  },
  {
    key: 6,
    question: "How can I participate in the Refer and Earn program?",
    answer: "Simply refer friends, and when they join, you earn direct cash rewards redeemable in your bank account.",
  },
  {
    key: 7,
    question: "Is the system compatible with iOS?",
    answer: "No, currently, we only offer the Android app.",
  },
  {
    key: 8,
    question: "Are there any hidden fees for redeeming rewards?",
    answer:
      "No, there are no hidden fees. The cash rewards you earn are directly redeemable into your bank account without any additional charges.",
  },
];
