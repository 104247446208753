const ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  DOWNLOADS: "/downloads",
  HOW_IT_WORKS: "/how-it-works",
  LOGIN: "/login",
  OTP: "/otp",
  SUBSCRIPTION: "/services",
  REFERRAL: "/referral",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  PAYMENTDONE: "/paymentdone",
  DEVICE_SECTION: "/device-section",
};

export default ROUTES;
