import React from "react";
import { message } from "antd";
import {
  ReferralInfo,
  ReferralImage,
  RulesWrapper,
  Rule,
  ReferralButtonWrapper,
  CopyButton,
  ShareButton,
  CopyReferralCode,
} from "./style";

import copyText from "../../common/utils/copyText";

// function copyText(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
//   if (window) {
//     window.navigator.clipboard.writeText(e.currentTarget.innerText);
//     message.success("Copied to clipboard");
//   }
// }

async function shareLink() {
  try {
    if (navigator.share) {
      await navigator.share({
        text: "Download this amazing app and get nothing",
        url: "www.google.com",
        title: "Tickercart",
      });
      console.log("Share successful");
    } else {
      console.error("Web Share API is not supported in this environment");
    }
  } catch (error: any) {
    if (error.name === "AbortError") {
      console.log("Share cancelled by the user");
    } else {
      console.error("Error during sharing:", error);
    }
  }
}

const Popout = () => {
  return (
    <ReferralInfo>
      <ReferralImage
        src="https://img.freepik.com/free-vector/people-making-money-from-referral-concept-illustration_52683-22927.jpg?w=2000&t=st=1701329490~exp=1701330090~hmac=dfec944ce7472c9930df26e39e467b61c1e26bd18e7192a5b4fdb72ead0a96a2"
        alt=""
      />
      <RulesWrapper>
        <Rule>
          <div>1</div>
          Share Your Refferal Code
        </Rule>
        <Rule>
          <div>2</div>
          Your friend gets nothing
        </Rule>
        <Rule>
          <div>3</div>
          You get a Apache Helicopter
        </Rule>
      </RulesWrapper>
      <ReferralButtonWrapper>
        <CopyReferralCode>
          DLKSJFLKJ39
          <CopyButton onClick={() => copyText("dlsjkflsd")}>copy</CopyButton>
        </CopyReferralCode>
        <ShareButton onClick={shareLink}>
          <img src="https://upload.wikimedia.org/wikipedia/commons/c/c8/Ei-share-apple.svg" alt="" />
          Invite Friends Now
        </ShareButton>
      </ReferralButtonWrapper>
    </ReferralInfo>
  );
};

export default Popout;
