import React from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: "Whats server and non server tracking ?",
    children: (
      <div>
        <div>Server tracking means the stock detection will happen on the server and not your own device.</div>
        <div>
          It does not require mobile to be turned on all the time. It sends notification to you when your stock is
          detected
        </div>
        <br />
        <div>
          Non server tracking means the stock detection will happen on your own device. It requires mobile to be turned
          on all the time. It rings loud sound when your stock is detected
        </div>
      </div>
    ),
  },
  {
    key: "2",
    label: "Why duration is fixed for 10 days ? ",
    children: (
      <div>
        <p>Its nothing to worry about. It's just that the stock detection of your each device has 10 days expiry</p>
        <p>You can come at any moment and restart 10 days timer again for free !!!</p>
        <br />
        <p>It helps us to keep our data clean</p>
      </div>
    ),
  },
];

const App: React.FC = () => {
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return <Collapse defaultActiveKey={["1"]} onChange={onChange} items={items} />;
};

export default App;
