import React from "react";
import RewardCard from "./RewardCard";
import { ReferralStatusWrapper } from './style'

const Component2 = () => {
  const users = [
    {
      key: 1,
      isLocked: false,
      isRewarded: false,
      amount: "200",
    },
    {
      key: 2,
      isLocked: false,
      isRewarded: true,
      amount: "300",
    },
    {
      key: 3,
      isLocked: true,
      isRewarded: false,
      amount: "400",
    },
  ];

  return (
    <ReferralStatusWrapper>
      {users.map((user) => (
        <RewardCard key={user.key} isLocked={user.isLocked} isRewarded={user.isRewarded} amount={user.amount} />
      ))}
    </ReferralStatusWrapper>
  );
};

export default Component2;
