import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { encryptText, decryptText } from "./customCrypto";
import _get from "lodash.get";

export type Method = "GET" | "POST" | "PUT" | "DELETE";
console.log();

function apiCaller<T>(
  url: string,
  method: Method,
  data?: any,
  options?: AxiosRequestConfig
): Promise<{
  status: "success" | "failed";
  data?: T;
  message?: string;
}> {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios({
        url,
        method,
        headers: {
          "Content-Type": "application/json",
        },
        data:
          process.env.NODE_ENV === "production"
            ? data
            : {
                type: "web",
                payload: encryptText(
                  JSON.stringify({
                    ...data,
                    deviceId: window.dhd82bderhjrd3334ds,
                  })
                ),
              },
        withCredentials: true,
      });

      const { data: resData } = res;
      resolve(resData);
    } catch (err) {
      reject(err);
    }
  });
}

export default apiCaller;

// 400, 500
// {status: 'success' | 'failed', data: any, message: string}
