export const referralLottie = {
  nm: "Refer_to_Friends",
  ddd: 0,
  h: 1000,
  w: 1000,
  meta: { g: "@lottiefiles/toolkit-js 0.25.4" },
  layers: [
    {
      ty: 4,
      nm: "Share :M 2",
      sr: 1,
      st: 0,
      op: 218,
      ip: 115,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100, 100], t: 115 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [122.567, 122.567, 100], t: 116 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [135.522, 135.522, 100], t: 117 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [140.785, 140.785, 100], t: 118 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [140.385, 140.385, 100], t: 119 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [136.229, 136.229, 100], t: 120 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [129.954, 129.954, 100], t: 121 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [122.864, 122.864, 100], t: 122 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [115.91, 115.91, 100], t: 123 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [109.716, 109.716, 100], t: 124 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [104.629, 104.629, 100], t: 125 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.773, 100.773, 100], t: 126 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [98.114, 98.114, 100], t: 127 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [96.513, 96.513, 100], t: 128 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [95.776, 95.776, 100], t: 129 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [95.688, 95.688, 100], t: 130 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [96.045, 96.045, 100], t: 131 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [96.665, 96.665, 100], t: 132 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.401, 97.401, 100], t: 133 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [98.145, 98.145, 100], t: 134 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [98.823, 98.823, 100], t: 135 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [99.39, 99.39, 100], t: 136 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [99.829, 99.829, 100], t: 137 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.14, 100.14, 100], t: 138 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.335, 100.335, 100], t: 139 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.433, 100.433, 100], t: 140 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.37, 100.37, 100], t: 143 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.294, 100.294, 100], t: 144 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.215, 100.215, 100], t: 145 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.141, 100.141, 100], t: 146 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.078, 100.078, 100], t: 147 },
            { s: [99.992, 99.992, 100], t: 149 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [212.75, 411.5, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [
        {
          ty: 0,
          mn: "Pseudo/MDS Elastic Controller",
          nm: "Elastic Controller",
          ix: 1,
          en: 1,
          ef: [
            { ty: 0, mn: "Pseudo/MDS Elastic Controller-0001", nm: "Amplitude", ix: 1, v: { a: 0, k: 20, ix: 1 } },
            { ty: 0, mn: "Pseudo/MDS Elastic Controller-0002", nm: "Frequency", ix: 2, v: { a: 0, k: 40, ix: 2 } },
            { ty: 0, mn: "Pseudo/MDS Elastic Controller-0003", nm: "Decay", ix: 3, v: { a: 0, k: 60, ix: 3 } },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Share",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.454, -1.121],
                    [0, -2.7],
                    [0, 0],
                    [0, -97.316],
                    [-3.229, -0.609],
                    [-1.231, 3.042],
                    [-15.917, 2.19],
                    [0, 0],
                    [-2.459, -1.095],
                    [-2.003, 1.79],
                    [0, 0],
                    [0, 1.95],
                    [1.468, 1.279],
                  ],
                  o: [
                    [0, 0],
                    [-2.003, -1.819],
                    [-2.459, 1.095],
                    [0, 0],
                    [-20.537, 5.504],
                    [0, 3.282],
                    [3.205, 0.614],
                    [20.27, -50.689],
                    [0, 0],
                    [0, 2.7],
                    [2.454, 1.092],
                    [0, 0],
                    [1.468, -1.279],
                    [0, -1.95],
                    [0, 0],
                  ],
                  v: [
                    [79.746, -25.612],
                    [18.214, -80.307],
                    [10.869, -81.455],
                    [6.837, -75.206],
                    [6.837, -53.28],
                    [-82.042, 75.206],
                    [-76.49, 81.906],
                    [-68.847, 77.743],
                    [6.837, 14.288],
                    [6.837, 34.184],
                    [10.869, 40.434],
                    [18.214, 39.285],
                    [79.746, -15.41],
                    [82.042, -20.511],
                    [79.746, -25.612],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9255, 0.9137, 0.9569], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, -0.018], ix: 1 },
              s: { a: 0, k: [40, 40], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, -0.018], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Circle",
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "el",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Ellipse",
              nm: "Ellipse Path 1",
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [100, 100], ix: 2 },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [1, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: "Share :M",
      sr: 1,
      st: 0,
      op: 115,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [212.75, 411.5, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Share",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 2",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [2.454, -1.121],
                    [0, -2.7],
                    [0, 0],
                    [0, -97.316],
                    [-3.229, -0.609],
                    [-1.231, 3.042],
                    [-15.917, 2.19],
                    [0, 0],
                    [-2.459, -1.095],
                    [-2.003, 1.79],
                    [0, 0],
                    [0, 1.95],
                    [1.468, 1.279],
                  ],
                  o: [
                    [0, 0],
                    [-2.003, -1.819],
                    [-2.459, 1.095],
                    [0, 0],
                    [-20.537, 5.504],
                    [0, 3.282],
                    [3.205, 0.614],
                    [20.27, -50.689],
                    [0, 0],
                    [0, 2.7],
                    [2.454, 1.092],
                    [0, 0],
                    [1.468, -1.279],
                    [0, -1.95],
                    [0, 0],
                  ],
                  v: [
                    [79.746, -25.612],
                    [18.214, -80.307],
                    [10.869, -81.455],
                    [6.837, -75.206],
                    [6.837, -53.28],
                    [-82.042, 75.206],
                    [-76.49, 81.906],
                    [-68.847, 77.743],
                    [6.837, 14.288],
                    [6.837, 34.184],
                    [10.869, 40.434],
                    [18.214, 39.285],
                    [79.746, -15.41],
                    [82.042, -20.511],
                    [79.746, -25.612],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9255, 0.9137, 0.9569], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, -0.018], ix: 1 },
              s: { a: 0, k: [40, 40], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, -0.018], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Circle",
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "el",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Ellipse",
              nm: "Ellipse Path 1",
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [100, 100], ix: 2 },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [1, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 2,
    },
    {
      ty: 0,
      nm: "Share 2",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [500, 500, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      w: 1000,
      h: 1000,
      refId: "comp_0",
      ind: 3,
    },
    {
      ty: 4,
      nm: "Mask",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      td: 1,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [460.141, 504.338, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [17.204, -133.487],
                    [-43.805, -43.709],
                    [-82.586, 0],
                    [0, 165.866],
                    [51.302, 81.616],
                    [91.915, -27.558],
                  ],
                  o: [
                    [-12.174, 94.454],
                    [57.512, 57.386],
                    [143.072, 0],
                    [0, -94.001],
                    [-87.902, -139.843],
                    [-98.104, 29.414],
                  ],
                  v: [
                    [-310.899, -60.029],
                    [-241.481, 232.101],
                    [8.449, 316.837],
                    [313.211, 97.003],
                    [245.015, -165.967],
                    [-62.512, -307.465],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "fl",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Graphic - Fill",
          nm: "Fill 1",
          c: { a: 0, k: [1, 0, 0], ix: 4 },
          r: 1,
          o: { a: 0, k: 100, ix: 5 },
        },
      ],
      ind: 4,
    },
    {
      ty: 0,
      nm: "Man",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      tt: 1,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [500, 500, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      w: 1000,
      h: 1000,
      refId: "comp_1",
      ind: 5,
    },
    {
      ty: 4,
      nm: "Plane",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [822.674, 188.632, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0, 100], t: 110 },
            { s: [100, 100, 100], t: 130 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.664, y: 0 },
              i: { x: 0.626, y: 0.515 },
              s: [226.503, 397.347, 0],
              t: 110,
              ti: [-140.526, -10.975, 0],
              to: [-18.222, -158.298, 0],
            },
            {
              o: { x: 0.183, y: 0.383 },
              i: { x: 0.469, y: 0.753 },
              s: [466.503, 177.772, 0],
              t: 125.91,
              ti: [-45.512, 1.087, 0],
              to: [114.901, 8.974, 0],
            },
            {
              o: { x: 0.239, y: 0.615 },
              i: { x: 0.561, y: 1 },
              s: [708.021, 228.77, 0],
              t: 142.51,
              ti: [-19.65, 13.367, 0],
              to: [24.72, -0.59, 0],
            },
            { s: [777.602, 206.464, 0], t: 170 },
          ],
          ix: 2,
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [-70], t: 110 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [-28], t: 120 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [19], t: 124 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [36], t: 129 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [52], t: 133 },
            { o: { x: 0.167, y: 0.167 }, i: { x: 0.24, y: 1 }, s: [48], t: 137 },
            { s: [7], t: 170 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-3.053, -11.939],
                    [-6.594, 11.939],
                    [6.594, 0.129],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9294, 0.6314, 0.6235], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [792.849, 208.812], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [61.108, -17.285],
                    [-39.997, 17.285],
                    [-61.108, -9.029],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9804, 0.8627, 0.8588], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [822.674, 169.991], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [46.993, -35.926],
                    [-24.865, 35.926],
                    [-46.993, 8.241],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9804, 0.8627, 0.8588], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [836.789, 188.632], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          ix: 4,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-46.975, 34.023],
                    [-50.553, 0.548],
                    [50.553, -34.023],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [833.23, 186.728], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 6,
    },
    {
      ty: 4,
      nm: "Layer 1",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [501.573, 287.181, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [24.72, -0.59],
                    [114.901, 8.974],
                    [-18.222, -158.298],
                  ],
                  o: [
                    [-19.65, 13.367],
                    [-45.512, 1.087],
                    [-140.526, -10.975],
                    [0, 0],
                  ],
                  v: [
                    [276.484, -80.553],
                    [206.902, -58.247],
                    [-34.616, -109.245],
                    [-274.616, 110.33],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "tm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Trim",
              nm: "Trim Paths 1",
              ix: 2,
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [100], t: 110 },
                  { s: [0], t: 170 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.861, ix: 5 },
              d: [
                { nm: "dash", n: "d", v: { a: 0, k: 8.174, ix: 1 } },
                { nm: "gap", n: "g", v: { a: 0, k: 16.347, ix: 2 } },
              ],
              c: { a: 0, k: [0.9294, 0.6314, 0.6235], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 7,
    },
    {
      ty: 4,
      nm: "Partical",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [698, 474, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [5], t: 90 },
            { s: [0], t: 180 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -5.989],
                    [6.738, 0],
                    [0, 5.989],
                    [-6.737, 0],
                  ],
                  o: [
                    [0, 5.989],
                    [-6.737, 0],
                    [0, -5.989],
                    [6.738, 0],
                  ],
                  v: [
                    [12.199, 0],
                    [0, 10.844],
                    [-12.199, 0],
                    [0, -10.844],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9255, 0.9137, 0.9569], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [394.227, 299.978], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 8,
    },
    {
      ty: 4,
      nm: "Partical 2",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [728, 322, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-5], t: 90 },
            { s: [0], t: 180 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -11.978],
                    [15.721, 0],
                    [0, 11.978],
                    [-15.721, 0],
                  ],
                  o: [
                    [0, 11.978],
                    [-15.721, 0],
                    [0, -11.978],
                    [15.721, 0],
                  ],
                  v: [
                    [28.464, 0],
                    [0, 21.687],
                    [-28.464, 0],
                    [0, -21.687],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9255, 0.9137, 0.9569], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [342.72, 341.998], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 9,
    },
    {
      ty: 4,
      nm: "Partical 3",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [366, 602, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [5], t: 90 },
            { s: [0], t: 180 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -11.978],
                    [15.721, 0],
                    [0, 11.978],
                    [-15.721, 0],
                  ],
                  o: [
                    [0, 11.978],
                    [-15.721, 0],
                    [0, -11.978],
                    [15.721, 0],
                  ],
                  v: [
                    [28.464, 0],
                    [0, 21.687],
                    [-28.464, 0],
                    [0, -21.687],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9255, 0.9137, 0.9569], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [342.72, 341.998], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 10,
    },
    {
      ty: 4,
      nm: "Partical 4",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [500, 500, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [5], t: 90 },
            { s: [0], t: 180 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -3.743],
                    [4.866, 0],
                    [0, 3.743],
                    [-4.866, 0],
                  ],
                  o: [
                    [0, 3.743],
                    [-4.866, 0],
                    [0, -3.743],
                    [4.866, 0],
                  ],
                  v: [
                    [8.81, 0],
                    [0, 6.777],
                    [-8.81, 0],
                    [0, -6.777],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9255, 0.9137, 0.9569], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [740.545, 762.187], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 11,
    },
    {
      ty: 4,
      nm: "Partical 5",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [500, 500, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-5], t: 90 },
            { s: [0], t: 180 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -10.106],
                    [11.229, 0],
                    [0, 10.106],
                    [-11.229, 0],
                  ],
                  o: [
                    [0, 10.106],
                    [-11.229, 0],
                    [0, -10.106],
                    [11.229, 0],
                  ],
                  v: [
                    [20.332, 0],
                    [0, 18.299],
                    [-20.332, 0],
                    [0, -18.299],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9255, 0.9137, 0.9569], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [779.175, 736.434], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 12,
    },
    {
      ty: 4,
      nm: "Partical 6",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [500, 500, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [2], t: 90 },
            { s: [0], t: 180 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "el",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Ellipse",
              nm: "Ellipse Path 1",
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [47.441, 47.441], ix: 2 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9255, 0.9137, 0.9569], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [605, 856.391], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 13,
    },
    {
      ty: 4,
      nm: "Group 6",
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [476.564, 559.459, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [476.564, 559.459, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [206.666, -316.586],
                    [-145.49, 0],
                    [0, 165.866],
                  ],
                  o: [
                    [-120.41, 184.446],
                    [143.072, 0],
                    [0, -426.973],
                  ],
                  v: [
                    [-263.101, -7.61],
                    [-7.974, 261.715],
                    [296.788, 41.882],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.9725, 0.9098, 0.9059], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [476.564, 559.459], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 14,
    },
  ],
  v: "5.9.4",
  fr: 30,
  op: 181,
  ip: 0,
  assets: [
    {
      nm: "Share",
      id: "comp_0",
      layers: [
        {
          ty: 4,
          nm: "Matte of Girl 2",
          sr: 1,
          st: 0,
          op: 300,
          ip: 50,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          td: 1,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [612.75, 477.25, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 1,
              it: [
                {
                  ty: "el",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Ellipse",
                  nm: "Ellipse Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  s: { a: 0, k: [100, 100], ix: 2 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 0, 0], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
          ],
          ind: 1,
          parent: 2,
        },
        {
          ty: 4,
          nm: "Girl Avatar 3",
          sr: 1,
          st: 0,
          op: 300,
          ip: 50,
          hd: false,
          ddd: 0,
          bm: 0,
          tt: 1,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [612.964, 476.962, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100, 100], t: 50 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [111.595, 111.595, 100], t: 51 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [116.777, 116.777, 100], t: 52 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [117.002, 117.002, 100], t: 53 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [114.079, 114.079, 100], t: 54 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [109.709, 109.709, 100], t: 55 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [105.218, 105.218, 100], t: 56 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [101.462, 101.462, 100], t: 57 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [98.843, 98.843, 100], t: 58 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.409, 97.409, 100], t: 59 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [96.977, 96.977, 100], t: 60 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.251, 97.251, 100], t: 61 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.918, 97.918, 100], t: 62 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [98.712, 98.712, 100], t: 63 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [99.442, 99.442, 100], t: 64 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100, 100], t: 65 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.35, 100.35, 100], t: 66 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.507, 100.507, 100], t: 67 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.425, 100.425, 100], t: 69 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.293, 100.293, 100], t: 70 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.158, 100.158, 100], t: 71 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.044, 100.044, 100], t: 72 },
                { s: [99.965, 99.965, 100], t: 73 },
              ],
            },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [612.964, 476.962, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [
            {
              ty: 0,
              mn: "Pseudo/MDS Elastic Controller",
              nm: "Elastic Controller",
              ix: 1,
              en: 1,
              ef: [
                { ty: 0, mn: "Pseudo/MDS Elastic Controller-0001", nm: "Amplitude", ix: 1, v: { a: 0, k: 12, ix: 1 } },
                { ty: 0, mn: "Pseudo/MDS Elastic Controller-0002", nm: "Frequency", ix: 2, v: { a: 0, k: 60, ix: 2 } },
                { ty: 0, mn: "Pseudo/MDS Elastic Controller-0003", nm: "Decay", ix: 3, v: { a: 0, k: 70, ix: 3 } },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-3.855, -11.706],
                        [0, 0],
                        [-6.022, 12.099],
                      ],
                      o: [
                        [-20.95, 6.897],
                        [0, 0],
                        [0, 0],
                        [6.022, -12.099],
                      ],
                      v: [
                        [11.369, -18.607],
                        [-2.389, 19.719],
                        [-2.389, 19.719],
                        [-9.817, -11.71],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [603.472, 467.905], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-2.016, -0.005],
                        [-0.392, -0.914],
                        [1.337, 0.131],
                      ],
                      o: [
                        [1.765, 0.005],
                        [0.391, 0.914],
                        [-1.337, -0.13],
                      ],
                      v: [
                        [-1.967, -1.106],
                        [2.721, -1.106],
                        [-0.379, 1.504],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [613.054, 482.234], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 3",
              ix: 3,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.558, 1.247],
                      ],
                      o: [
                        [4.868, 0.935],
                        [0, 0],
                      ],
                      v: [
                        [-5.143, 0.339],
                        [5.143, -0.752],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.964, 478.39], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 4",
              ix: 4,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [0.701, 0],
                        [0, 0.701],
                        [-0.701, 0],
                      ],
                      o: [
                        [0, 0.701],
                        [-0.701, 0],
                        [0, -0.701],
                        [0.701, 0],
                      ],
                      v: [
                        [1.268, 0],
                        [0, 1.268],
                        [-1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [619.301, 467.339], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 5",
              ix: 5,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [-0.7, 0],
                        [0, 0.701],
                        [0.701, 0],
                      ],
                      o: [
                        [0, 0.701],
                        [0.701, 0],
                        [0, -0.701],
                        [-0.7, 0],
                      ],
                      v: [
                        [-1.268, 0],
                        [0, 1.268],
                        [1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [605.193, 467.339], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 6",
              ix: 6,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.502, 1.12],
                        [-0.653, -1.253],
                        [1.604, 0.079],
                      ],
                      o: [
                        [0.788, -1.759],
                        [0.653, 1.253],
                        [-1.604, -0.079],
                      ],
                      v: [
                        [-3.047, -0.408],
                        [3.011, -0.111],
                        [-0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [620.28, 460.966], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 7",
              ix: 7,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.502, 1.12],
                        [0.653, -1.253],
                        [-1.603, 0.079],
                      ],
                      o: [
                        [-0.788, -1.759],
                        [-0.653, 1.253],
                        [1.604, -0.079],
                      ],
                      v: [
                        [3.047, -0.408],
                        [-3.011, -0.111],
                        [0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [604.214, 460.966], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 8",
              ix: 8,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-0.737, -0.096],
                      ],
                      o: [
                        [-1.84, 3.977],
                        [0, 0],
                      ],
                      v: [
                        [-0.512, -2.423],
                        [1.135, 2.423],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [611.111, 473.82], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 9",
              ix: 9,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-0.061, -0.754],
                        [0.59, 0],
                      ],
                      o: [
                        [1.298, 0.619],
                        [0.061, 0.754],
                        [-0.59, 0],
                      ],
                      v: [
                        [-2.142, -0.594],
                        [2.14, -0.594],
                        [-0.001, 0.827],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.699, 475.794], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 10",
              ix: 10,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [12.615, 0.005],
                        [-2.948, 14.147],
                        [-2.428, -11.653],
                      ],
                      o: [
                        [-12.609, -0.005],
                        [2.428, -11.657],
                        [2.948, 14.147],
                      ],
                      v: [
                        [-0.014, 18.336],
                        [-17.131, -9.594],
                        [17.132, -9.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.246, 471.026], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 11",
              ix: 11,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [1.048, -1.375],
                      ],
                      o: [
                        [1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [-1.957, 0.268],
                        [1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [630.839, 472.297], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 12",
              ix: 12,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [3.063, 3.547],
                      ],
                      o: [
                        [3.191, -4.847],
                        [0, 4.941],
                        [-3.063, -3.547],
                      ],
                      v: [
                        [-2.154, -3.07],
                        [4.738, -1.391],
                        [-3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [630.338, 473.396], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 13",
              ix: 13,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.048, -1.375],
                      ],
                      o: [
                        [-1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [1.957, 0.268],
                        [-1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [593.655, 472.297], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 14",
              ix: 14,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [-3.063, 3.547],
                      ],
                      o: [
                        [-3.19, -4.847],
                        [0, 4.941],
                        [3.063, -3.547],
                      ],
                      v: [
                        [2.154, -3.07],
                        [-4.738, -1.391],
                        [3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [594.156, 473.396], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 15",
              ix: 15,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [3.92, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-3.149, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.901, -5.183],
                        [5.901, 1.738],
                        [-0.001, 5.183],
                        [-5.901, 2.196],
                        [-5.901, -5.183],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.819, 487.624], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 16",
              ix: 16,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [3.259, 0],
                        [0, 0],
                        [0, 3.259],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.259, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.259],
                      ],
                      v: [
                        [0, 8.586],
                        [0, 8.586],
                        [-5.901, 2.686],
                        [-5.901, -8.586],
                        [5.901, -8.586],
                        [5.901, 2.686],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.819, 492.331], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 17",
              ix: 17,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [23.989, -11.336],
                        [-12.164, 16.497],
                      ],
                      o: [
                        [7.322, 15.083],
                        [0, 0],
                      ],
                      v: [
                        [-20.862, -3.405],
                        [20.862, -3.405],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.966, 501.136], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 18",
              ix: 18,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [-5.167, 8.306],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.167, -8.311],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [5.167, -8.311],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.167, 8.306],
                      ],
                      v: [
                        [38.943, 16.732],
                        [-38.943, 16.732],
                        [-30.143, -5.594],
                        [-6.007, -16.732],
                        [0, -15.726],
                        [6.008, -16.732],
                        [30.149, -5.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "gf",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - G-Fill",
                  nm: "Gradient Fill 1",
                  e: { a: 0, k: [38.922, -0.23], ix: 6 },
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 0.9803921568627451, 0.39215686274509803, 0.3843137254901961, 0.414, 0.9803921568627451,
                        0.39215686274509803, 0.3843137254901961, 1, 0.9803921568627451, 0.39215686274509803,
                        0.3843137254901961,
                      ],
                      ix: 9,
                    },
                  },
                  t: 1,
                  a: { a: 0, k: 0 },
                  h: { a: 0, k: 0 },
                  s: { a: 0, k: [-38.964, -0.23], ix: 5 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 10 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.964, 510.23], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 19",
              ix: 19,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-11.9, -0.263],
                        [0, 0],
                        [10.424, -11.592],
                      ],
                      o: [
                        [-2.861, -19.554],
                        [11.9, 0.263],
                        [0, 0],
                        [-10.424, 11.592],
                      ],
                      v: [
                        [-21.57, 7.554],
                        [1.152, -18.839],
                        [18.678, -9.107],
                        [16.768, 15.12],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [613.122, 460.567], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 20",
              ix: 20,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-2.275, -3.688],
                        [0, 0],
                      ],
                      o: [
                        [1.784, -5.495],
                        [2.276, 3.688],
                        [0, 0],
                      ],
                      v: [
                        [-7.491, -4.57],
                        [6.026, -1.907],
                        [7.248, 7.142],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.4824, 0.1686, 0.102], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [623.596, 448.556], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 21",
              ix: 21,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-6.403, -6.922],
                        [6.471, -0.64],
                        [0, 0],
                      ],
                      o: [
                        [-1.894, -9.665],
                        [6.403, 6.922],
                        [-6.471, 0.64],
                        [0, 0],
                      ],
                      v: [
                        [-8.993, 3.717],
                        [6.187, -4.897],
                        [1.344, 7.86],
                        [-7.356, 3.472],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [629.732, 440.646], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 22",
              ix: 22,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "el",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Ellipse",
                  nm: "Ellipse Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  s: { a: 0, k: [100, 100], ix: 2 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9804, 0.6235, 0.6157], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.964, 476.962], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 2,
        },
        {
          ty: 4,
          nm: "Matte of Girl",
          sr: 1,
          st: 0,
          op: 50,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          td: 1,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [612.75, 477.25, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 1,
              it: [
                {
                  ty: "el",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Ellipse",
                  nm: "Ellipse Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  s: { a: 0, k: [100, 100], ix: 2 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 0, 0], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
          ],
          ind: 3,
          parent: 4,
        },
        {
          ty: 4,
          nm: "Girl Avatar",
          sr: 1,
          st: 0,
          op: 50,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          tt: 1,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [612.964, 476.962, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [612.964, 476.962, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-3.855, -11.706],
                        [0, 0],
                        [-6.022, 12.099],
                      ],
                      o: [
                        [-20.95, 6.897],
                        [0, 0],
                        [0, 0],
                        [6.022, -12.099],
                      ],
                      v: [
                        [11.369, -18.607],
                        [-2.389, 19.719],
                        [-2.389, 19.719],
                        [-9.817, -11.71],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [603.472, 467.905], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-2.016, -0.005],
                        [-0.392, -0.914],
                        [1.337, 0.131],
                      ],
                      o: [
                        [1.765, 0.005],
                        [0.391, 0.914],
                        [-1.337, -0.13],
                      ],
                      v: [
                        [-1.967, -1.106],
                        [2.721, -1.106],
                        [-0.379, 1.504],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [613.054, 482.234], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 3",
              ix: 3,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.558, 1.247],
                      ],
                      o: [
                        [4.868, 0.935],
                        [0, 0],
                      ],
                      v: [
                        [-5.143, 0.339],
                        [5.143, -0.752],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.964, 478.39], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 4",
              ix: 4,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [0.701, 0],
                        [0, 0.701],
                        [-0.701, 0],
                      ],
                      o: [
                        [0, 0.701],
                        [-0.701, 0],
                        [0, -0.701],
                        [0.701, 0],
                      ],
                      v: [
                        [1.268, 0],
                        [0, 1.268],
                        [-1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [619.301, 467.339], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 5",
              ix: 5,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [-0.7, 0],
                        [0, 0.701],
                        [0.701, 0],
                      ],
                      o: [
                        [0, 0.701],
                        [0.701, 0],
                        [0, -0.701],
                        [-0.7, 0],
                      ],
                      v: [
                        [-1.268, 0],
                        [0, 1.268],
                        [1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [605.193, 467.339], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 6",
              ix: 6,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.502, 1.12],
                        [-0.653, -1.253],
                        [1.604, 0.079],
                      ],
                      o: [
                        [0.788, -1.759],
                        [0.653, 1.253],
                        [-1.604, -0.079],
                      ],
                      v: [
                        [-3.047, -0.408],
                        [3.011, -0.111],
                        [-0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [620.28, 460.966], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 7",
              ix: 7,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.502, 1.12],
                        [0.653, -1.253],
                        [-1.603, 0.079],
                      ],
                      o: [
                        [-0.788, -1.759],
                        [-0.653, 1.253],
                        [1.604, -0.079],
                      ],
                      v: [
                        [3.047, -0.408],
                        [-3.011, -0.111],
                        [0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [604.214, 460.966], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 8",
              ix: 8,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-0.737, -0.096],
                      ],
                      o: [
                        [-1.84, 3.977],
                        [0, 0],
                      ],
                      v: [
                        [-0.512, -2.423],
                        [1.135, 2.423],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [611.111, 473.82], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 9",
              ix: 9,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-0.061, -0.754],
                        [0.59, 0],
                      ],
                      o: [
                        [1.298, 0.619],
                        [0.061, 0.754],
                        [-0.59, 0],
                      ],
                      v: [
                        [-2.142, -0.594],
                        [2.14, -0.594],
                        [-0.001, 0.827],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.699, 475.794], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 10",
              ix: 10,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [12.615, 0.005],
                        [-2.948, 14.147],
                        [-2.428, -11.653],
                      ],
                      o: [
                        [-12.609, -0.005],
                        [2.428, -11.657],
                        [2.948, 14.147],
                      ],
                      v: [
                        [-0.014, 18.336],
                        [-17.131, -9.594],
                        [17.132, -9.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.246, 471.026], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 11",
              ix: 11,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [1.048, -1.375],
                      ],
                      o: [
                        [1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [-1.957, 0.268],
                        [1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [630.839, 472.297], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 12",
              ix: 12,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [3.063, 3.547],
                      ],
                      o: [
                        [3.191, -4.847],
                        [0, 4.941],
                        [-3.063, -3.547],
                      ],
                      v: [
                        [-2.154, -3.07],
                        [4.738, -1.391],
                        [-3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [630.338, 473.396], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 13",
              ix: 13,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.048, -1.375],
                      ],
                      o: [
                        [-1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [1.957, 0.268],
                        [-1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [593.655, 472.297], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 14",
              ix: 14,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [-3.063, 3.547],
                      ],
                      o: [
                        [-3.19, -4.847],
                        [0, 4.941],
                        [3.063, -3.547],
                      ],
                      v: [
                        [2.154, -3.07],
                        [-4.738, -1.391],
                        [3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [594.156, 473.396], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 15",
              ix: 15,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [3.92, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-3.149, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.901, -5.183],
                        [5.901, 1.738],
                        [-0.001, 5.183],
                        [-5.901, 2.196],
                        [-5.901, -5.183],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.819, 487.624], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 16",
              ix: 16,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [3.259, 0],
                        [0, 0],
                        [0, 3.259],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.259, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.259],
                      ],
                      v: [
                        [0, 8.586],
                        [0, 8.586],
                        [-5.901, 2.686],
                        [-5.901, -8.586],
                        [5.901, -8.586],
                        [5.901, 2.686],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.819, 492.331], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 17",
              ix: 17,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [23.989, -11.336],
                        [-12.164, 16.497],
                      ],
                      o: [
                        [7.322, 15.083],
                        [0, 0],
                      ],
                      v: [
                        [-20.862, -3.405],
                        [20.862, -3.405],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.966, 501.136], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 18",
              ix: 18,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [-5.167, 8.306],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.167, -8.311],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [5.167, -8.311],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.167, 8.306],
                      ],
                      v: [
                        [38.943, 16.732],
                        [-38.943, 16.732],
                        [-30.143, -5.594],
                        [-6.007, -16.732],
                        [0, -15.726],
                        [6.008, -16.732],
                        [30.149, -5.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "gf",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - G-Fill",
                  nm: "Gradient Fill 1",
                  e: { a: 0, k: [38.922, -0.23], ix: 6 },
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 0.9803921568627451, 0.39215686274509803, 0.3843137254901961, 0.414, 0.9803921568627451,
                        0.39215686274509803, 0.3843137254901961, 1, 0.9803921568627451, 0.39215686274509803,
                        0.3843137254901961,
                      ],
                      ix: 9,
                    },
                  },
                  t: 1,
                  a: { a: 0, k: 0 },
                  h: { a: 0, k: 0 },
                  s: { a: 0, k: [-38.964, -0.23], ix: 5 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 10 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.964, 510.23], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 19",
              ix: 19,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-11.9, -0.263],
                        [0, 0],
                        [10.424, -11.592],
                      ],
                      o: [
                        [-2.861, -19.554],
                        [11.9, 0.263],
                        [0, 0],
                        [-10.424, 11.592],
                      ],
                      v: [
                        [-21.57, 7.554],
                        [1.152, -18.839],
                        [18.678, -9.107],
                        [16.768, 15.12],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [613.122, 460.567], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 20",
              ix: 20,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-2.275, -3.688],
                        [0, 0],
                      ],
                      o: [
                        [1.784, -5.495],
                        [2.276, 3.688],
                        [0, 0],
                      ],
                      v: [
                        [-7.491, -4.57],
                        [6.026, -1.907],
                        [7.248, 7.142],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.4824, 0.1686, 0.102], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [623.596, 448.556], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 21",
              ix: 21,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-6.403, -6.922],
                        [6.471, -0.64],
                        [0, 0],
                      ],
                      o: [
                        [-1.894, -9.665],
                        [6.403, 6.922],
                        [-6.471, 0.64],
                        [0, 0],
                      ],
                      v: [
                        [-8.993, 3.717],
                        [6.187, -4.897],
                        [1.344, 7.86],
                        [-7.356, 3.472],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [629.732, 440.646], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 22",
              ix: 22,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "el",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Ellipse",
                  nm: "Ellipse Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  s: { a: 0, k: [100, 100], ix: 2 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9804, 0.6235, 0.6157], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [612.964, 476.962], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 4,
        },
        {
          ty: 4,
          nm: "Matte of Boy 2",
          sr: 1,
          st: 0,
          op: 300,
          ip: 90,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          td: 1,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [766.925, 570.915, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 1,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0],
                      ],
                      o: [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0],
                      ],
                      v: [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 0, 0], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
          ],
          ind: 5,
          parent: 6,
        },
        {
          ty: 4,
          nm: "Boy Avatar 2",
          sr: 1,
          st: 0,
          op: 300,
          ip: 90,
          hd: false,
          ddd: 0,
          bm: 0,
          tt: 1,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [766.925, 571.435, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100, 100], t: 90 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [111.595, 111.595, 100], t: 91 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [116.777, 116.777, 100], t: 92 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [117.002, 117.002, 100], t: 93 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [114.079, 114.079, 100], t: 94 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [109.709, 109.709, 100], t: 95 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [105.218, 105.218, 100], t: 96 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [101.462, 101.462, 100], t: 97 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [98.843, 98.843, 100], t: 98 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.409, 97.409, 100], t: 99 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [96.977, 96.977, 100], t: 100 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.251, 97.251, 100], t: 101 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.918, 97.918, 100], t: 102 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [98.712, 98.712, 100], t: 103 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [99.442, 99.442, 100], t: 104 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100, 100], t: 105 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.35, 100.35, 100], t: 106 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.507, 100.507, 100], t: 107 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.425, 100.425, 100], t: 109 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.293, 100.293, 100], t: 110 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.158, 100.158, 100], t: 111 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.044, 100.044, 100], t: 112 },
                { s: [99.965, 99.965, 100], t: 113 },
              ],
            },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [268.464, -107.768, 0], ix: 2 },
            r: { a: 0, k: -60, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [
            {
              ty: 0,
              mn: "Pseudo/MDS Elastic Controller",
              nm: "Elastic Controller",
              ix: 1,
              en: 1,
              ef: [
                { ty: 0, mn: "Pseudo/MDS Elastic Controller-0001", nm: "Amplitude", ix: 1, v: { a: 0, k: 12, ix: 1 } },
                { ty: 0, mn: "Pseudo/MDS Elastic Controller-0002", nm: "Frequency", ix: 2, v: { a: 0, k: 60, ix: 2 } },
                { ty: 0, mn: "Pseudo/MDS Elastic Controller-0003", nm: "Decay", ix: 3, v: { a: 0, k: 70, ix: 3 } },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [4.596, -10.307],
                        [0, 0],
                        [7.212, 0.701],
                        [-8.78, 3.658],
                      ],
                      o: [
                        [-3.269, 7.33],
                        [0, 0],
                        [-7.212, -0.701],
                        [8.78, -3.658],
                      ],
                      v: [
                        [14.476, 0.806],
                        [4.053, 3.963],
                        [-7.851, 7.657],
                        [-10.041, -5.35],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [762.896, 545.837], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [0.701, 0],
                        [0, 0.701],
                        [-0.701, 0],
                      ],
                      o: [
                        [0, 0.701],
                        [-0.701, 0],
                        [0, -0.701],
                        [0.701, 0],
                      ],
                      v: [
                        [1.268, 0],
                        [0, 1.268],
                        [-1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [773.262, 561.292], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 3",
              ix: 3,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [-0.7, 0],
                        [0, 0.701],
                        [0.701, 0],
                      ],
                      o: [
                        [0, 0.701],
                        [0.701, 0],
                        [0, -0.701],
                        [-0.7, 0],
                      ],
                      v: [
                        [-1.268, 0],
                        [0, 1.268],
                        [1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [759.154, 561.292], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 4",
              ix: 4,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-2.016, -0.005],
                        [-0.392, -0.914],
                        [1.337, 0.13],
                      ],
                      o: [
                        [1.765, 0.005],
                        [0.392, 0.914],
                        [-1.337, -0.131],
                      ],
                      v: [
                        [-1.967, -1.106],
                        [2.721, -1.106],
                        [-0.379, 1.504],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [767.039, 575.863], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 5",
              ix: 5,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.502, 1.12],
                        [-0.653, -1.253],
                        [1.604, 0.079],
                      ],
                      o: [
                        [0.788, -1.759],
                        [0.653, 1.253],
                        [-1.604, -0.079],
                      ],
                      v: [
                        [-3.047, -0.408],
                        [3.011, -0.111],
                        [-0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [774.241, 554.919], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 6",
              ix: 6,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.502, 1.12],
                        [0.653, -1.253],
                        [-1.604, 0.079],
                      ],
                      o: [
                        [-0.788, -1.759],
                        [-0.653, 1.253],
                        [1.604, -0.079],
                      ],
                      v: [
                        [3.047, -0.408],
                        [-3.011, -0.111],
                        [0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [758.175, 554.919], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 7",
              ix: 7,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.558, 1.247],
                      ],
                      o: [
                        [4.868, 0.935],
                        [0, 0],
                      ],
                      v: [
                        [-5.143, 0.339],
                        [5.143, -0.752],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.949, 572.019], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 8",
              ix: 8,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-0.737, -0.096],
                      ],
                      o: [
                        [-1.84, 3.977],
                        [0, 0],
                      ],
                      v: [
                        [-0.512, -2.423],
                        [1.135, 2.423],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [765.072, 567.773], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 9",
              ix: 9,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-0.061, -0.754],
                        [0.59, 0],
                      ],
                      o: [
                        [1.298, 0.619],
                        [0.061, 0.754],
                        [-0.59, 0],
                      ],
                      v: [
                        [-2.142, -0.594],
                        [2.14, -0.594],
                        [-0.001, 0.827],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.66, 569.747], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 10",
              ix: 10,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [12.615, 0.005],
                        [-2.948, 14.147],
                        [-2.428, -11.653],
                      ],
                      o: [
                        [-12.609, -0.005],
                        [2.428, -11.657],
                        [2.948, 14.147],
                      ],
                      v: [
                        [-0.014, 18.336],
                        [-17.131, -9.594],
                        [17.132, -9.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.207, 564.979], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 11",
              ix: 11,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [1.048, -1.375],
                      ],
                      o: [
                        [1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [-1.957, 0.268],
                        [1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [784.8, 566.249], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 12",
              ix: 12,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [3.063, 3.547],
                      ],
                      o: [
                        [3.191, -4.847],
                        [0, 4.941],
                        [-3.063, -3.547],
                      ],
                      v: [
                        [-2.154, -3.07],
                        [4.738, -1.391],
                        [-3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [784.299, 567.349], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 13",
              ix: 13,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.048, -1.375],
                      ],
                      o: [
                        [-1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [1.957, 0.268],
                        [-1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [747.616, 566.249], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 14",
              ix: 14,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [-3.063, 3.547],
                      ],
                      o: [
                        [-3.19, -4.847],
                        [0, 4.941],
                        [3.063, -3.547],
                      ],
                      v: [
                        [2.154, -3.07],
                        [-4.738, -1.391],
                        [3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [748.117, 567.349], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 15",
              ix: 15,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [3.92, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-3.149, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.901, -5.183],
                        [5.901, 1.738],
                        [-0.001, 5.183],
                        [-5.901, 2.196],
                        [-5.901, -5.183],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.781, 581.577], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 16",
              ix: 16,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [3.259, 0],
                        [0, 0],
                        [0, 3.259],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.259, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.259],
                      ],
                      v: [
                        [0, 8.586],
                        [0, 8.586],
                        [-5.901, 2.686],
                        [-5.901, -8.586],
                        [5.901, -8.586],
                        [5.901, 2.686],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.78, 586.284], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 17",
              ix: 17,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [7.866, -4.864],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1.008, -8.105],
                        [7.595, -6.491],
                        [-2.254, 8.105],
                        [-7.595, 1.56],
                        [-7.595, -5.461],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [774.057, 595.556], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 18",
              ix: 18,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [-7.866, -4.864],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.008, -8.105],
                        [-7.595, -6.491],
                        [2.254, 8.105],
                        [7.595, 1.56],
                        [7.595, -5.461],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [759.198, 595.556], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 19",
              ix: 19,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [-5.987, 8.41],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.987, -8.415],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [5.987, -8.415],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.987, 8.41],
                      ],
                      v: [
                        [43.555, 17.252],
                        [-43.555, 17.252],
                        [-34.926, -5.768],
                        [-6.719, -17.252],
                        [0, -16.215],
                        [6.719, -17.252],
                        [34.933, -5.768],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "gf",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - G-Fill",
                  nm: "Gradient Fill 1",
                  e: { a: 0, k: [43.185, -0.703], ix: 6 },
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 0.9803921568627451, 0.39215686274509803, 0.3843137254901961, 0.414, 0.9803921568627451,
                        0.39215686274509803, 0.3843137254901961, 1, 0.9803921568627451, 0.39215686274509803,
                        0.3843137254901961,
                      ],
                      ix: 9,
                    },
                  },
                  t: 1,
                  a: { a: 0, k: 0 },
                  h: { a: 0, k: 0 },
                  s: { a: 0, k: [-43.925, -0.703], ix: 5 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 10 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.925, 604.703], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 20",
              ix: 20,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-3.081, 5.548],
                        [4.405, -2.324],
                        [10.085, 4.632],
                        [2.938, -8.073],
                        [0, 0],
                        [-5.128, -8.01],
                      ],
                      o: [
                        [6.881, -12.39],
                        [0, 0],
                        [-11.669, -5.36],
                        [-2.711, 7.45],
                        [0, 0],
                        [5.128, 8.01],
                      ],
                      v: [
                        [20.48, 12.827],
                        [17.507, -4.342],
                        [6.755, -16.657],
                        [-22.924, -9.765],
                        [-20.793, 1.275],
                        [-18.663, 14.639],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [765.337, 548.959], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 21",
              ix: 21,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0],
                      ],
                      o: [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0],
                      ],
                      v: [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9804, 0.6235, 0.6157], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.925, 570.915], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 6,
          parent: 13,
        },
        {
          ty: 4,
          nm: "Matte of Boy",
          sr: 1,
          st: 0,
          op: 90,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          td: 1,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [766.925, 570.915, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 1,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0],
                      ],
                      o: [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0],
                      ],
                      v: [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 0, 0], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
          ],
          ind: 7,
          parent: 8,
        },
        {
          ty: 4,
          nm: "Boy Avatar",
          sr: 1,
          st: 0,
          op: 90,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          tt: 1,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [766.925, 571.435, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [268.464, -107.768, 0], ix: 2 },
            r: { a: 0, k: -60, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [4.596, -10.307],
                        [0, 0],
                        [7.212, 0.701],
                        [-8.78, 3.658],
                      ],
                      o: [
                        [-3.269, 7.33],
                        [0, 0],
                        [-7.212, -0.701],
                        [8.78, -3.658],
                      ],
                      v: [
                        [14.476, 0.806],
                        [4.053, 3.963],
                        [-7.851, 7.657],
                        [-10.041, -5.35],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [762.896, 545.837], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [0.701, 0],
                        [0, 0.701],
                        [-0.701, 0],
                      ],
                      o: [
                        [0, 0.701],
                        [-0.701, 0],
                        [0, -0.701],
                        [0.701, 0],
                      ],
                      v: [
                        [1.268, 0],
                        [0, 1.268],
                        [-1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [773.262, 561.292], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 3",
              ix: 3,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [-0.7, 0],
                        [0, 0.701],
                        [0.701, 0],
                      ],
                      o: [
                        [0, 0.701],
                        [0.701, 0],
                        [0, -0.701],
                        [-0.7, 0],
                      ],
                      v: [
                        [-1.268, 0],
                        [0, 1.268],
                        [1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [759.154, 561.292], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 4",
              ix: 4,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-2.016, -0.005],
                        [-0.392, -0.914],
                        [1.337, 0.13],
                      ],
                      o: [
                        [1.765, 0.005],
                        [0.392, 0.914],
                        [-1.337, -0.131],
                      ],
                      v: [
                        [-1.967, -1.106],
                        [2.721, -1.106],
                        [-0.379, 1.504],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [767.039, 575.863], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 5",
              ix: 5,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.502, 1.12],
                        [-0.653, -1.253],
                        [1.604, 0.079],
                      ],
                      o: [
                        [0.788, -1.759],
                        [0.653, 1.253],
                        [-1.604, -0.079],
                      ],
                      v: [
                        [-3.047, -0.408],
                        [3.011, -0.111],
                        [-0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [774.241, 554.919], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 6",
              ix: 6,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.502, 1.12],
                        [0.653, -1.253],
                        [-1.604, 0.079],
                      ],
                      o: [
                        [-0.788, -1.759],
                        [-0.653, 1.253],
                        [1.604, -0.079],
                      ],
                      v: [
                        [3.047, -0.408],
                        [-3.011, -0.111],
                        [0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [758.175, 554.919], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 7",
              ix: 7,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.558, 1.247],
                      ],
                      o: [
                        [4.868, 0.935],
                        [0, 0],
                      ],
                      v: [
                        [-5.143, 0.339],
                        [5.143, -0.752],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.949, 572.019], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 8",
              ix: 8,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-0.737, -0.096],
                      ],
                      o: [
                        [-1.84, 3.977],
                        [0, 0],
                      ],
                      v: [
                        [-0.512, -2.423],
                        [1.135, 2.423],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [765.072, 567.773], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 9",
              ix: 9,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-0.061, -0.754],
                        [0.59, 0],
                      ],
                      o: [
                        [1.298, 0.619],
                        [0.061, 0.754],
                        [-0.59, 0],
                      ],
                      v: [
                        [-2.142, -0.594],
                        [2.14, -0.594],
                        [-0.001, 0.827],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.66, 569.747], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 10",
              ix: 10,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [12.615, 0.005],
                        [-2.948, 14.147],
                        [-2.428, -11.653],
                      ],
                      o: [
                        [-12.609, -0.005],
                        [2.428, -11.657],
                        [2.948, 14.147],
                      ],
                      v: [
                        [-0.014, 18.336],
                        [-17.131, -9.594],
                        [17.132, -9.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.207, 564.979], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 11",
              ix: 11,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [1.048, -1.375],
                      ],
                      o: [
                        [1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [-1.957, 0.268],
                        [1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [784.8, 566.249], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 12",
              ix: 12,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [3.063, 3.547],
                      ],
                      o: [
                        [3.191, -4.847],
                        [0, 4.941],
                        [-3.063, -3.547],
                      ],
                      v: [
                        [-2.154, -3.07],
                        [4.738, -1.391],
                        [-3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [784.299, 567.349], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 13",
              ix: 13,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.048, -1.375],
                      ],
                      o: [
                        [-1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [1.957, 0.268],
                        [-1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [747.616, 566.249], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 14",
              ix: 14,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [-3.063, 3.547],
                      ],
                      o: [
                        [-3.19, -4.847],
                        [0, 4.941],
                        [3.063, -3.547],
                      ],
                      v: [
                        [2.154, -3.07],
                        [-4.738, -1.391],
                        [3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [748.117, 567.349], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 15",
              ix: 15,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [3.92, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-3.149, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.901, -5.183],
                        [5.901, 1.738],
                        [-0.001, 5.183],
                        [-5.901, 2.196],
                        [-5.901, -5.183],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.781, 581.577], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 16",
              ix: 16,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [3.259, 0],
                        [0, 0],
                        [0, 3.259],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.259, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.259],
                      ],
                      v: [
                        [0, 8.586],
                        [0, 8.586],
                        [-5.901, 2.686],
                        [-5.901, -8.586],
                        [5.901, -8.586],
                        [5.901, 2.686],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.78, 586.284], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 17",
              ix: 17,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [7.866, -4.864],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1.008, -8.105],
                        [7.595, -6.491],
                        [-2.254, 8.105],
                        [-7.595, 1.56],
                        [-7.595, -5.461],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [774.057, 595.556], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 18",
              ix: 18,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [-7.866, -4.864],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.008, -8.105],
                        [-7.595, -6.491],
                        [2.254, 8.105],
                        [7.595, 1.56],
                        [7.595, -5.461],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [759.198, 595.556], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 19",
              ix: 19,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [-5.987, 8.41],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.987, -8.415],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [5.987, -8.415],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.987, 8.41],
                      ],
                      v: [
                        [43.555, 17.252],
                        [-43.555, 17.252],
                        [-34.926, -5.768],
                        [-6.719, -17.252],
                        [0, -16.215],
                        [6.719, -17.252],
                        [34.933, -5.768],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "gf",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - G-Fill",
                  nm: "Gradient Fill 1",
                  e: { a: 0, k: [43.185, -0.703], ix: 6 },
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 0.9803921568627451, 0.39215686274509803, 0.3843137254901961, 0.414, 0.9803921568627451,
                        0.39215686274509803, 0.3843137254901961, 1, 0.9803921568627451, 0.39215686274509803,
                        0.3843137254901961,
                      ],
                      ix: 9,
                    },
                  },
                  t: 1,
                  a: { a: 0, k: 0 },
                  h: { a: 0, k: 0 },
                  s: { a: 0, k: [-43.925, -0.703], ix: 5 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 10 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.925, 604.703], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 20",
              ix: 20,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-3.081, 5.548],
                        [4.405, -2.324],
                        [10.085, 4.632],
                        [2.938, -8.073],
                        [0, 0],
                        [-5.128, -8.01],
                      ],
                      o: [
                        [6.881, -12.39],
                        [0, 0],
                        [-11.669, -5.36],
                        [-2.711, 7.45],
                        [0, 0],
                        [5.128, 8.01],
                      ],
                      v: [
                        [20.48, 12.827],
                        [17.507, -4.342],
                        [6.755, -16.657],
                        [-22.924, -9.765],
                        [-20.793, 1.275],
                        [-18.663, 14.639],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [765.337, 548.959], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 21",
              ix: 21,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0],
                      ],
                      o: [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0],
                      ],
                      v: [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9804, 0.6235, 0.6157], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.925, 570.915], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 8,
          parent: 13,
        },
        {
          ty: 4,
          nm: "Matte of girl 2",
          sr: 1,
          st: 0,
          op: 300,
          ip: 70,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          td: 1,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [766.925, 391.332, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 1,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0],
                      ],
                      o: [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0],
                      ],
                      v: [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 0, 0], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
          ],
          ind: 9,
          parent: 10,
        },
        {
          ty: 4,
          nm: "Girl Avatar 4",
          sr: 1,
          st: 0,
          op: 300,
          ip: 70,
          hd: false,
          ddd: 0,
          bm: 0,
          tt: 1,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [766.925, 391.332, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100, 100], t: 70 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [111.595, 111.595, 100], t: 71 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [116.777, 116.777, 100], t: 72 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [117.002, 117.002, 100], t: 73 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [114.079, 114.079, 100], t: 74 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [109.709, 109.709, 100], t: 75 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [105.218, 105.218, 100], t: 76 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [101.462, 101.462, 100], t: 77 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [98.843, 98.843, 100], t: 78 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.409, 97.409, 100], t: 79 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [96.977, 96.977, 100], t: 80 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.251, 97.251, 100], t: 81 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [97.918, 97.918, 100], t: 82 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [98.712, 98.712, 100], t: 83 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [99.442, 99.442, 100], t: 84 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100, 100], t: 85 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.35, 100.35, 100], t: 86 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.507, 100.507, 100], t: 87 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.425, 100.425, 100], t: 89 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.293, 100.293, 100], t: 90 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.158, 100.158, 100], t: 91 },
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100.044, 100.044, 100], t: 92 },
                { s: [99.965, 99.965, 100], t: 93 },
              ],
            },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [266.925, -108.668, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [
            {
              ty: 0,
              mn: "Pseudo/MDS Elastic Controller",
              nm: "Elastic Controller",
              ix: 1,
              en: 1,
              ef: [
                { ty: 0, mn: "Pseudo/MDS Elastic Controller-0001", nm: "Amplitude", ix: 1, v: { a: 0, k: 12, ix: 1 } },
                { ty: 0, mn: "Pseudo/MDS Elastic Controller-0002", nm: "Frequency", ix: 2, v: { a: 0, k: 60, ix: 2 } },
                { ty: 0, mn: "Pseudo/MDS Elastic Controller-0003", nm: "Decay", ix: 3, v: { a: 0, k: 70, ix: 3 } },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-1.364, -2.723],
                        [4.489, 0.839],
                        [-3.501, 5.883],
                      ],
                      o: [
                        [2.875, 5.739],
                        [-4.489, -0.839],
                        [3.501, -5.883],
                      ],
                      v: [
                        [6.201, -8.274],
                        [-3.314, 9.294],
                        [-4.301, -2.39],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [751.734, 371.559], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.007, -3.537],
                        [-4.374, 2.808],
                        [11.523, 2.73],
                      ],
                      o: [
                        [-0.023, 11.878],
                        [4.375, -2.808],
                        [-11.523, -2.73],
                      ],
                      v: [
                        [-14.375, -6.35],
                        [12.539, 8.426],
                        [1.98, -7.19],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [772.261, 369.72], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 3",
              ix: 3,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-2.016, -0.005],
                        [-0.392, -0.914],
                        [1.337, 0.13],
                      ],
                      o: [
                        [1.765, 0.005],
                        [0.392, 0.914],
                        [-1.337, -0.13],
                      ],
                      v: [
                        [-1.967, -1.106],
                        [2.721, -1.106],
                        [-0.379, 1.504],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.792, 398.34], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 4",
              ix: 4,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-2.29, -0.078],
                        [3.399, 0.812],
                      ],
                      o: [
                        [4.826, 0.519],
                        [0, 0],
                        [-3.399, -0.812],
                      ],
                      v: [
                        [-5.171, -1.722],
                        [5.171, -1.656],
                        [-1.118, 1.615],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.66, 394.432], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 5",
              ix: 5,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [0.701, 0],
                        [0, 0.7],
                        [-0.701, 0],
                      ],
                      o: [
                        [0, 0.7],
                        [-0.701, 0],
                        [0, -0.701],
                        [0.701, 0],
                      ],
                      v: [
                        [1.268, 0],
                        [0, 1.268],
                        [-1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [773.262, 381.71], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 6",
              ix: 6,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [-0.7, 0],
                        [0, 0.7],
                        [0.701, 0],
                      ],
                      o: [
                        [0, 0.7],
                        [0.701, 0],
                        [0, -0.701],
                        [-0.7, 0],
                      ],
                      v: [
                        [-1.268, 0],
                        [0, 1.268],
                        [1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [759.154, 381.71], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 7",
              ix: 7,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.502, 1.12],
                        [-0.653, -1.253],
                        [1.604, 0.079],
                      ],
                      o: [
                        [0.788, -1.759],
                        [0.653, 1.253],
                        [-1.604, -0.079],
                      ],
                      v: [
                        [-3.047, -0.408],
                        [3.011, -0.111],
                        [-0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [774.241, 375.337], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 8",
              ix: 8,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.502, 1.12],
                        [0.653, -1.253],
                        [-1.604, 0.079],
                      ],
                      o: [
                        [-0.788, -1.759],
                        [-0.653, 1.253],
                        [1.604, -0.079],
                      ],
                      v: [
                        [3.047, -0.408],
                        [-3.011, -0.111],
                        [0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [758.175, 375.337], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 9",
              ix: 9,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-0.737, -0.096],
                      ],
                      o: [
                        [-1.84, 3.977],
                        [0, 0],
                      ],
                      v: [
                        [-0.512, -2.423],
                        [1.135, 2.423],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [765.072, 388.191], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 10",
              ix: 10,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-0.061, -0.754],
                        [0.59, 0],
                      ],
                      o: [
                        [1.298, 0.619],
                        [0.061, 0.754],
                        [-0.59, 0],
                      ],
                      v: [
                        [-2.142, -0.594],
                        [2.14, -0.594],
                        [-0.001, 0.827],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.66, 390.164], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 11",
              ix: 11,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [12.615, 0.005],
                        [-2.948, 14.147],
                        [-2.428, -11.653],
                      ],
                      o: [
                        [-12.609, -0.005],
                        [2.428, -11.657],
                        [2.948, 14.147],
                      ],
                      v: [
                        [-0.014, 18.336],
                        [-17.131, -9.594],
                        [17.132, -9.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.207, 385.396], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 12",
              ix: 12,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [1.048, -1.375],
                      ],
                      o: [
                        [1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [-1.957, 0.268],
                        [1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [784.8, 386.667], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 13",
              ix: 13,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [3.063, 3.547],
                      ],
                      o: [
                        [3.191, -4.847],
                        [0, 4.941],
                        [-3.063, -3.547],
                      ],
                      v: [
                        [-2.154, -3.07],
                        [4.738, -1.391],
                        [-3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [784.299, 387.766], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 14",
              ix: 14,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.048, -1.375],
                      ],
                      o: [
                        [-1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [1.957, 0.268],
                        [-1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [747.616, 386.667], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 15",
              ix: 15,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [-3.063, 3.547],
                      ],
                      o: [
                        [-3.19, -4.847],
                        [0, 4.941],
                        [3.063, -3.547],
                      ],
                      v: [
                        [2.154, -3.07],
                        [-4.738, -1.391],
                        [3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [748.117, 387.766], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 16",
              ix: 16,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [3.92, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-3.149, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.901, -5.183],
                        [5.901, 1.738],
                        [-0.001, 5.183],
                        [-5.901, 2.196],
                        [-5.901, -5.183],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.781, 401.994], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 17",
              ix: 17,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [3.259, 0],
                        [0, 0],
                        [0, 3.259],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.259, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.259],
                      ],
                      v: [
                        [0, 8.586],
                        [0, 8.586],
                        [-5.901, 2.686],
                        [-5.901, -8.586],
                        [5.901, -8.586],
                        [5.901, 2.686],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.78, 406.702], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 18",
              ix: 18,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [17.987, -7.786],
                        [-8.335, 11.375],
                      ],
                      o: [
                        [9.122, 13.037],
                        [0, 0],
                      ],
                      v: [
                        [-17.354, -2.852],
                        [17.354, -2.852],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.927, 413.493], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 19",
              ix: 19,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [-5.167, 8.306],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.167, -8.311],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [5.167, -8.311],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.167, 8.306],
                      ],
                      v: [
                        [38.943, 16.732],
                        [-38.943, 16.732],
                        [-30.143, -5.594],
                        [-6.007, -16.732],
                        [0, -15.726],
                        [6.008, -16.732],
                        [30.149, -5.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "gf",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - G-Fill",
                  nm: "Gradient Fill 1",
                  e: { a: 0, k: [37.961, -0.6], ix: 6 },
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 0.9803921568627451, 0.39215686274509803, 0.3843137254901961, 0.414, 0.9803921568627451,
                        0.39215686274509803, 0.3843137254901961, 1, 0.9803921568627451, 0.39215686274509803,
                        0.3843137254901961,
                      ],
                      ix: 9,
                    },
                  },
                  t: 1,
                  a: { a: 0, k: 0 },
                  h: { a: 0, k: 0 },
                  s: { a: 0, k: [-39.925, -0.6], ix: 5 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 10 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.925, 424.6], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 20",
              ix: 20,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.04, -0.094],
                        [0.373, -0.177],
                        [0.935, -2.252],
                        [-0.054, -2.36],
                        [5.198, -6.475],
                        [3.551, -2.404],
                        [-3.919, -7.165],
                        [-8.079, -1.199],
                        [-7.966, 1.803],
                        [-4.507, 3.447],
                        [4.875, 9.516],
                        [-2.446, 7.57],
                        [12.041, 5.221],
                      ],
                      o: [
                        [0.315, -0.745],
                        [-2.171, 1.03],
                        [-0.905, 2.18],
                        [0.166, 7.262],
                        [-2.685, 3.344],
                        [-6.763, 4.579],
                        [3.919, 7.165],
                        [8.079, 1.199],
                        [5.534, -1.252],
                        [10.623, -8.125],
                        [-3.124, -6.099],
                        [4.402, -13.621],
                        [-19.729, -8.554],
                      ],
                      v: [
                        [-7.531, -33.231],
                        [-11.974, -32.417],
                        [-16.753, -27.609],
                        [-17.763, -20.652],
                        [-17.311, 2.24],
                        [-28.252, 8.815],
                        [-32.463, 30.747],
                        [-12.462, 43.342],
                        [11.809, 41.302],
                        [27.51, 35.092],
                        [28.306, 7.273],
                        [33.232, -9.268],
                        [19.288, -41.33],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [757.648, 396.108], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 21",
              ix: 21,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0],
                      ],
                      o: [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0],
                      ],
                      v: [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9804, 0.6235, 0.6157], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.925, 391.332], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 10,
          parent: 14,
        },
        {
          ty: 4,
          nm: "Matte of girl",
          sr: 1,
          st: 0,
          op: 70,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          td: 1,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [766.925, 391.332, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 1,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0],
                      ],
                      o: [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0],
                      ],
                      v: [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 0, 0], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
          ],
          ind: 11,
          parent: 12,
        },
        {
          ty: 4,
          nm: "Girl Avatar 2",
          sr: 1,
          st: 0,
          op: 70,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          tt: 1,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [766.925, 391.332, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [266.925, -108.668, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-1.364, -2.723],
                        [4.489, 0.839],
                        [-3.501, 5.883],
                      ],
                      o: [
                        [2.875, 5.739],
                        [-4.489, -0.839],
                        [3.501, -5.883],
                      ],
                      v: [
                        [6.201, -8.274],
                        [-3.314, 9.294],
                        [-4.301, -2.39],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [751.734, 371.559], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.007, -3.537],
                        [-4.374, 2.808],
                        [11.523, 2.73],
                      ],
                      o: [
                        [-0.023, 11.878],
                        [4.375, -2.808],
                        [-11.523, -2.73],
                      ],
                      v: [
                        [-14.375, -6.35],
                        [12.539, 8.426],
                        [1.98, -7.19],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [772.261, 369.72], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 3",
              ix: 3,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-2.016, -0.005],
                        [-0.392, -0.914],
                        [1.337, 0.13],
                      ],
                      o: [
                        [1.765, 0.005],
                        [0.392, 0.914],
                        [-1.337, -0.13],
                      ],
                      v: [
                        [-1.967, -1.106],
                        [2.721, -1.106],
                        [-0.379, 1.504],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.792, 398.34], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 4",
              ix: 4,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-2.29, -0.078],
                        [3.399, 0.812],
                      ],
                      o: [
                        [4.826, 0.519],
                        [0, 0],
                        [-3.399, -0.812],
                      ],
                      v: [
                        [-5.171, -1.722],
                        [5.171, -1.656],
                        [-1.118, 1.615],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.66, 394.432], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 5",
              ix: 5,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [0.701, 0],
                        [0, 0.7],
                        [-0.701, 0],
                      ],
                      o: [
                        [0, 0.7],
                        [-0.701, 0],
                        [0, -0.701],
                        [0.701, 0],
                      ],
                      v: [
                        [1.268, 0],
                        [0, 1.268],
                        [-1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [773.262, 381.71], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 6",
              ix: 6,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -0.701],
                        [-0.7, 0],
                        [0, 0.7],
                        [0.701, 0],
                      ],
                      o: [
                        [0, 0.7],
                        [0.701, 0],
                        [0, -0.701],
                        [-0.7, 0],
                      ],
                      v: [
                        [-1.268, 0],
                        [0, 1.268],
                        [1.268, 0],
                        [0, -1.268],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [759.154, 381.71], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 7",
              ix: 7,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.502, 1.12],
                        [-0.653, -1.253],
                        [1.604, 0.079],
                      ],
                      o: [
                        [0.788, -1.759],
                        [0.653, 1.253],
                        [-1.604, -0.079],
                      ],
                      v: [
                        [-3.047, -0.408],
                        [3.011, -0.111],
                        [-0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [774.241, 375.337], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 8",
              ix: 8,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.502, 1.12],
                        [0.653, -1.253],
                        [-1.604, 0.079],
                      ],
                      o: [
                        [-0.788, -1.759],
                        [-0.653, 1.253],
                        [1.604, -0.079],
                      ],
                      v: [
                        [3.047, -0.408],
                        [-3.011, -0.111],
                        [0.1, 1.415],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [758.175, 375.337], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 9",
              ix: 9,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-0.737, -0.096],
                      ],
                      o: [
                        [-1.84, 3.977],
                        [0, 0],
                      ],
                      v: [
                        [-0.512, -2.423],
                        [1.135, 2.423],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [765.072, 388.191], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 1,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 10",
              ix: 10,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-0.061, -0.754],
                        [0.59, 0],
                      ],
                      o: [
                        [1.298, 0.619],
                        [0.061, 0.754],
                        [-0.59, 0],
                      ],
                      v: [
                        [-2.142, -0.594],
                        [2.14, -0.594],
                        [-0.001, 0.827],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.66, 390.164], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 50, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 11",
              ix: 11,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [12.615, 0.005],
                        [-2.948, 14.147],
                        [-2.428, -11.653],
                      ],
                      o: [
                        [-12.609, -0.005],
                        [2.428, -11.657],
                        [2.948, 14.147],
                      ],
                      v: [
                        [-0.014, 18.336],
                        [-17.131, -9.594],
                        [17.132, -9.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.207, 385.396], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 12",
              ix: 12,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [1.048, -1.375],
                      ],
                      o: [
                        [1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [-1.957, 0.268],
                        [1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [784.8, 386.667], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 13",
              ix: 13,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [3.063, 3.547],
                      ],
                      o: [
                        [3.191, -4.847],
                        [0, 4.941],
                        [-3.063, -3.547],
                      ],
                      v: [
                        [-2.154, -3.07],
                        [4.738, -1.391],
                        [-3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [784.299, 387.766], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 14",
              ix: 14,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [-1.048, -1.375],
                      ],
                      o: [
                        [-1.986, -3.712],
                        [0, 0],
                      ],
                      v: [
                        [1.957, 0.268],
                        [-1.725, 1.395],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.652, ix: 5 },
                  c: { a: 0, k: [0.8784, 0.4941, 0.3804], ix: 3 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [747.616, 386.667], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 15",
              ix: 15,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, -3.413],
                        [-3.063, 3.547],
                      ],
                      o: [
                        [-3.19, -4.847],
                        [0, 4.941],
                        [3.063, -3.547],
                      ],
                      v: [
                        [2.154, -3.07],
                        [-4.738, -1.391],
                        [3.685, 4.26],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [748.117, 387.766], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 16",
              ix: 16,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [3.92, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-3.149, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.901, -5.183],
                        [5.901, 1.738],
                        [-0.001, 5.183],
                        [-5.901, 2.196],
                        [-5.901, -5.183],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.0824, 0.0392, 0.0314], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.781, 401.994], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 17",
              ix: 17,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [3.259, 0],
                        [0, 0],
                        [0, 3.259],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.259, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 3.259],
                      ],
                      v: [
                        [0, 8.586],
                        [0, 8.586],
                        [-5.901, 2.686],
                        [-5.901, -8.586],
                        [5.901, -8.586],
                        [5.901, 2.686],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.78, 406.702], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 18",
              ix: 18,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [17.987, -7.786],
                        [-8.335, 11.375],
                      ],
                      o: [
                        [9.122, 13.037],
                        [0, 0],
                      ],
                      v: [
                        [-17.354, -2.852],
                        [17.354, -2.852],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9451, 0.7137, 0.6039], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.927, 413.493], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 19",
              ix: 19,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [-5.167, 8.306],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.167, -8.311],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [5.167, -8.311],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.167, 8.306],
                      ],
                      v: [
                        [38.943, 16.732],
                        [-38.943, 16.732],
                        [-30.143, -5.594],
                        [-6.007, -16.732],
                        [0, -15.726],
                        [6.008, -16.732],
                        [30.149, -5.594],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "gf",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - G-Fill",
                  nm: "Gradient Fill 1",
                  e: { a: 0, k: [37.961, -0.6], ix: 6 },
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 0.9803921568627451, 0.39215686274509803, 0.3843137254901961, 0.414, 0.9803921568627451,
                        0.39215686274509803, 0.3843137254901961, 1, 0.9803921568627451, 0.39215686274509803,
                        0.3843137254901961,
                      ],
                      ix: 9,
                    },
                  },
                  t: 1,
                  a: { a: 0, k: 0 },
                  h: { a: 0, k: 0 },
                  s: { a: 0, k: [-39.925, -0.6], ix: 5 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 10 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.925, 424.6], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 20",
              ix: 20,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.04, -0.094],
                        [0.373, -0.177],
                        [0.935, -2.252],
                        [-0.054, -2.36],
                        [5.198, -6.475],
                        [3.551, -2.404],
                        [-3.919, -7.165],
                        [-8.079, -1.199],
                        [-7.966, 1.803],
                        [-4.507, 3.447],
                        [4.875, 9.516],
                        [-2.446, 7.57],
                        [12.041, 5.221],
                      ],
                      o: [
                        [0.315, -0.745],
                        [-2.171, 1.03],
                        [-0.905, 2.18],
                        [0.166, 7.262],
                        [-2.685, 3.344],
                        [-6.763, 4.579],
                        [3.919, 7.165],
                        [8.079, 1.199],
                        [5.534, -1.252],
                        [10.623, -8.125],
                        [-3.124, -6.099],
                        [4.402, -13.621],
                        [-19.729, -8.554],
                      ],
                      v: [
                        [-7.531, -33.231],
                        [-11.974, -32.417],
                        [-16.753, -27.609],
                        [-17.763, -20.652],
                        [-17.311, 2.24],
                        [-28.252, 8.815],
                        [-32.463, 30.747],
                        [-12.462, 43.342],
                        [11.809, 41.302],
                        [27.51, 35.092],
                        [28.306, 7.273],
                        [33.232, -9.268],
                        [19.288, -41.33],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3569, 0.2824, 0.3216], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [757.648, 396.108], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 21",
              ix: 21,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0],
                      ],
                      o: [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0],
                      ],
                      v: [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9804, 0.6235, 0.6157], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [766.925, 391.332], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 12,
          parent: 14,
        },
        {
          ty: 4,
          nm: "Shape Layer 2",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [112.5, -19.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [612.5, 480.5, 0], ix: 2 },
            r: { a: 0, k: 60, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Shape 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [112.5, -19.5],
                        [269.5, -111],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  c: { a: 0, k: [0.9804, 0.6235, 0.6157], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Trim",
              nm: "Trim Paths 1",
              ix: 2,
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              s: { a: 0, k: 0, ix: 1 },
              m: 1,
            },
          ],
          ind: 13,
          parent: 4,
        },
        {
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [112.5, -19.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [612.5, 480.5, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Shape 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [112.5, -19.5],
                        [269.5, -111],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  c: { a: 0, k: [0.9804, 0.6235, 0.6157], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Trim",
              nm: "Trim Paths 1",
              ix: 2,
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              s: { a: 0, k: 0, ix: 1 },
              m: 1,
            },
          ],
          ind: 14,
          parent: 4,
        },
      ],
    },
    {
      nm: "Man",
      id: "comp_1",
      layers: [
        {
          ty: 3,
          nm: "left-Wrist",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100, 100], t: 0 },
                { s: [100, 100, 100], t: 180 },
              ],
            },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [420.75, 621, 0], t: 0, ti: [0, 0, 0], to: [0, 0, 0] },
                { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [452.75, 572, 0], t: 30, ti: [0, 0, 0], to: [0, 0, 0] },
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0, y: 1 },
                  s: [447.875, 568.938, 0],
                  t: 40,
                  ti: [0, 0, 0],
                  to: [0, 0, 0],
                },
                { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [452.75, 572, 0], t: 50, ti: [0, 0, 0], to: [0, 0, 0] },
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0, y: 1 },
                  s: [437.438, 584.625, 0],
                  t: 60,
                  ti: [0, 0, 0],
                  to: [0, 0, 0],
                },
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0, y: 1 },
                  s: [442.438, 588.125, 0],
                  t: 70,
                  ti: [0, 0, 0],
                  to: [0, 0, 0],
                },
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0, y: 1 },
                  s: [428.062, 599.125, 0],
                  t: 80,
                  ti: [0, 0, 0],
                  to: [0, 0, 0],
                },
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0.667, y: 1 },
                  s: [432.688, 602.875, 0],
                  t: 90,
                  ti: [0, 0, 0],
                  to: [0, 0, 0],
                },
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0, y: 1 },
                  s: [416.438, 617.75, 0],
                  t: 110,
                  ti: [0, 0, 0],
                  to: [0, 0, 0],
                },
                { s: [420.75, 621, 0], t: 120 },
              ],
              ix: 2,
            },
            r: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0], t: 0 },
                { s: [0], t: 180 },
              ],
            },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [
            {
              ty: 0,
              mn: "Pseudo/Limber_16",
              nm: "Limber",
              ix: 1,
              en: 1,
              ef: [
                { ty: 6, mn: "Pseudo/Limber_16-0001", nm: "Shape", ix: 1, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0002", nm: "Upper Length", ix: 2, v: { a: 0, k: 158, ix: 2 } },
                { ty: 0, mn: "Pseudo/Limber_16-0003", nm: "Lower Length", ix: 3, v: { a: 0, k: 174, ix: 3 } },
                { ty: 0, mn: "Pseudo/Limber_16-0004", nm: "Start Size", ix: 4, v: { a: 0, k: 81, ix: 4 } },
                { ty: 0, mn: "Pseudo/Limber_16-0005", nm: "Middle Size", ix: 5, v: { a: 0, k: 70, ix: 5 } },
                { ty: 0, mn: "Pseudo/Limber_16-0006", nm: "End Size", ix: 6, v: { a: 0, k: 40, ix: 6 } },
                { ty: 0, mn: "Pseudo/Limber_16-0007", nm: "Bone Curvature", ix: 7, v: { a: 0, k: 0, ix: 7 } },
                { ty: 7, mn: "Pseudo/Limber_16-0008", nm: "Square End", ix: 8, v: { a: 0, k: 1, ix: 8 } },
                { ty: 6, mn: "Pseudo/Limber_16-0009", nm: "Limber", ix: 9, v: 0 },
                { ty: 6, mn: "Pseudo/Limber_16-0010", nm: "Scale", ix: 10, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0011", nm: "Size Scale", ix: 11, v: { a: 0, k: 100, ix: 11 } },
                {
                  ty: 7,
                  mn: "Pseudo/Limber_16-0012",
                  nm: "Link Length to Size Scale",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
                { ty: 6, mn: "Pseudo/Limber_16-0013", nm: "Limber", ix: 13, v: 0 },
                { ty: 6, mn: "Pseudo/Limber_16-0014", nm: "Colors", ix: 14, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0015", nm: "Upper Split", ix: 15, v: { a: 0, k: 100, ix: 15 } },
                { ty: 0, mn: "Pseudo/Limber_16-0016", nm: "Upper Rounding", ix: 16, v: { a: 0, k: 0, ix: 16 } },
                { ty: 0, mn: "Pseudo/Limber_16-0017", nm: "Lower Split", ix: 17, v: { a: 0, k: 70, ix: 17 } },
                { ty: 0, mn: "Pseudo/Limber_16-0018", nm: "Lower Rounding", ix: 18, v: { a: 0, k: 0, ix: 18 } },
                {
                  ty: 2,
                  mn: "Pseudo/Limber_16-0019",
                  nm: "Upper Color",
                  ix: 19,
                  v: { a: 0, k: [0.4902, 0.4039, 0.8118], ix: 19 },
                },
                {
                  ty: 2,
                  mn: "Pseudo/Limber_16-0020",
                  nm: "Middle Color",
                  ix: 20,
                  v: { a: 0, k: [0.4902, 0.4039, 0.8118], ix: 20 },
                },
                {
                  ty: 2,
                  mn: "Pseudo/Limber_16-0021",
                  nm: "Lower Color",
                  ix: 21,
                  v: { a: 0, k: [0.6824, 0.749, 0.8706], ix: 21 },
                },
                { ty: 6, mn: "Pseudo/Limber_16-0022", nm: "Limber", ix: 22, v: 0 },
                { ty: 6, mn: "Pseudo/Limber_16-0023", nm: "Dynamics", ix: 23, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0024", nm: "Clockwise", ix: 24, v: { a: 0, k: -100, ix: 24 } },
                { ty: 0, mn: "Pseudo/Limber_16-0025", nm: "Rotate Start", ix: 25, v: { a: 0, k: 0, ix: 25 } },
                { ty: 0, mn: "Pseudo/Limber_16-0026", nm: "Rotate End", ix: 26, v: { a: 0, k: 0, ix: 26 } },
                { ty: 7, mn: "Pseudo/Limber_16-0027", nm: "Stretch", ix: 27, v: { a: 0, k: 1, ix: 27 } },
                { ty: 0, mn: "Pseudo/Limber_16-0028", nm: "Anti-pop", ix: 28, v: { a: 0, k: 0, ix: 28 } },
                { ty: 6, mn: "Pseudo/Limber_16-0029", nm: "Limber", ix: 29, v: 0 },
                { ty: 6, mn: "Pseudo/Limber_16-0030", nm: "Forward Kinematics", ix: 30, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0031", nm: "FK", ix: 31, v: { a: 0, k: 0, ix: 31 } },
                { ty: 0, mn: "Pseudo/Limber_16-0032", nm: "Upper FK Rotation", ix: 32, v: { a: 0, k: 180, ix: 32 } },
                { ty: 0, mn: "Pseudo/Limber_16-0033", nm: "Lower FK Rotation", ix: 33, v: { a: 0, k: 0, ix: 33 } },
                { ty: 6, mn: "Pseudo/Limber_16-0034", nm: "Limber", ix: 34, v: 0 },
              ],
            },
          ],
          ind: 1,
        },
        {
          ty: 4,
          nm: "left-Arm",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0, 0], t: 0 },
                { s: [0, 0, 0], t: 180 },
              ],
            },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0, 0], t: 0 },
                { s: [0, 0, 0], t: 180 },
              ],
            },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Limb",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Upper Group",
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Rigged Upper",
                      ix: 1,
                      cix: 2,
                      np: 1,
                      it: [
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Sholder L Group",
                          ix: 1,
                          cix: 2,
                          np: 1,
                          it: [
                            {
                              ty: "gr",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Group",
                              nm: "Sholder L",
                              ix: 1,
                              cix: 2,
                              np: 2,
                              it: [
                                {
                                  ty: "sh",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Shape - Group",
                                  nm: "Path 1",
                                  ix: 1,
                                  d: 1,
                                  ks: {
                                    a: 0,
                                    k: {
                                      c: true,
                                      i: [
                                        [53.34, -8.529],
                                        [3.409, -9.425],
                                        [1.951, -20.553],
                                        [-10.732, -16.978],
                                        [-15.967, 3.963],
                                        [0.801, 12.758],
                                        [0, 0],
                                        [-0.975, 16.085],
                                      ],
                                      o: [
                                        [-12.194, 1.95],
                                        [-3.2, 8.845],
                                        [-1.952, 20.552],
                                        [10.733, 16.978],
                                        [15.967, -3.963],
                                        [-1.537, -24.472],
                                        [0, 0],
                                        [0.976, -16.085],
                                      ],
                                      v: [
                                        [-66.574, -117.061],
                                        [-93.893, -92.04],
                                        [-97.795, -44.679],
                                        [-81.516, 95.614],
                                        [-40.701, 114.373],
                                        [-14.285, 80.527],
                                        [-15.839, 28.595],
                                        [-13.888, -59.871],
                                      ],
                                    },
                                    ix: 2,
                                  },
                                },
                                {
                                  ty: "fl",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Graphic - Fill",
                                  nm: "Fill 1",
                                  c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 4 },
                                  r: 1,
                                  o: { a: 0, k: 100, ix: 5 },
                                },
                                {
                                  ty: "tr",
                                  a: { a: 0, k: [0, 0], ix: 1 },
                                  s: { a: 0, k: [100, 100], ix: 3 },
                                  sk: { a: 0, k: 0, ix: 4 },
                                  p: { a: 0, k: [0, 0], ix: 2 },
                                  r: { a: 0, k: 0, ix: 6 },
                                  sa: { a: 0, k: 0, ix: 5 },
                                  o: { a: 0, k: 100, ix: 7 },
                                },
                              ],
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [52.634, 79.428], ix: 2 },
                              r: { a: 0, k: 2.081, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100], t: 0 },
                              { s: [100, 100], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 180, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C1 Group",
                      ix: 2,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C1",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [81, 81], t: 0 },
                              { s: [81, 81], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9804, 0.3922, 0.3843],
                                t: 0,
                              },
                              { s: [0.9804, 0.3922, 0.3843], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [0, 0], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C2 Group",
                      ix: 3,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C2",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [70, 70], t: 0 },
                              { s: [70, 70], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9804, 0.3922, 0.3843],
                                t: 0,
                              },
                              { s: [0.9804, 0.3922, 0.3843], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, -158], t: 0 },
                              { s: [0, -158], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [274.7, 530.696], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [275.031, 530.607], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [275.644, 530.442], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [276.709, 530.159], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.223, 529.763], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.704, 529.383], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [280.876, 529.087], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [281.782, 528.861], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [282.501, 528.684], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [283.086, 528.541], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [283.573, 528.423], t: 12 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [283.982, 528.324], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [284.331, 528.24], t: 14 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [284.631, 528.168], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [284.889, 528.107], t: 16 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.112, 528.054], t: 17 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.305, 528.008], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.471, 527.969], t: 19 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.738, 527.906], t: 21 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [286.002, 527.844], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.869, 527.875], t: 134 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.675, 527.921], t: 135 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.427, 527.979], t: 136 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.119, 528.052], t: 137 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [284.747, 528.141], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [284.307, 528.246], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [283.796, 528.369], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [283.214, 528.51], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [282.564, 528.669], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [281.856, 528.843], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [281.104, 529.03], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [280.329, 529.225], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.554, 529.421], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.804, 529.613], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.098, 529.796], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.45, 529.964], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [276.871, 530.116], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [276.363, 530.251], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [275.925, 530.367], t: 152 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [275.556, 530.466], t: 153 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [275.25, 530.548], t: 154 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [275.004, 530.614], t: 155 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [274.812, 530.666], t: 156 },
                          { s: [274.573, 530.73], t: 158 },
                        ],
                      },
                      r: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [185.133], t: 1 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [184.931], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [184.501], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [183.695], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [182.266], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [180.175], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [178.066], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [176.358], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [175.015], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [173.938], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [173.052], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [172.309], t: 12 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [171.681], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [171.143], t: 14 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [170.681], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [170.281], t: 16 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [169.934], t: 17 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [169.634], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [169.375], t: 19 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [169.151], t: 20 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.958], t: 21 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.794], t: 22 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.657], t: 23 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.543], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.378], t: 26 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.293], t: 31 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.495], t: 32 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.714], t: 33 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.825], t: 34 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.949], t: 41 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.755], t: 42 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.536], t: 43 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.423], t: 44 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.258], t: 50 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [168.632], t: 51 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [170.874], t: 52 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [173.399], t: 53 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [174.679], t: 54 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [175.439], t: 55 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [175.922], t: 56 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [176.234], t: 57 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [176.427], t: 58 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [176.516], t: 61 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [176.274], t: 62 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [176.003], t: 63 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [175.865], t: 64 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [175.698], t: 67 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [175.662], t: 70 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [176.008], t: 71 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [178.085], t: 72 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [180.409], t: 73 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [181.594], t: 74 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [182.293], t: 75 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [182.735], t: 76 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [183.022], t: 77 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [183.2], t: 78 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [183.276], t: 81 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [183.019], t: 82 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [182.732], t: 83 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [182.585], t: 84 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [182.409], t: 87 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [182.437], t: 91 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [182.628], t: 92 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [182.923], t: 93 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [183.31], t: 94 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [183.781], t: 95 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [184.321], t: 96 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [184.915], t: 97 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [185.548], t: 98 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [186.209], t: 99 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [187.557], t: 101 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [188.217], t: 102 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [188.849], t: 103 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [189.441], t: 104 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [189.978], t: 105 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [190.446], t: 106 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [190.833], t: 107 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [191.125], t: 108 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [191.313], t: 109 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [191.321], t: 111 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [190.99], t: 112 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [190.623], t: 113 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [190.437], t: 114 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [190.259], t: 116 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [190.093], t: 133 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [189.948], t: 135 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [189.841], t: 136 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [189.709], t: 137 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [189.549], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [189.36], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [189.14], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [188.891], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [188.613], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [188.31], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [187.989], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [187.659], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [187.329], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [187.01], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [186.71], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [186.436], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [186.19], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [185.975], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [185.791], t: 152 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [185.635], t: 153 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [185.506], t: 154 },
                          { s: [185.321], t: 156 },
                        ],
                      },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Lower Group 2",
                  ix: 2,
                  cix: 2,
                  np: 4,
                  it: [
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Rigged Lower",
                      ix: 1,
                      cix: 2,
                      np: 1,
                      it: [
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Elbow L Group",
                          ix: 1,
                          cix: 2,
                          np: 1,
                          it: [
                            {
                              ty: "gr",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Group",
                              nm: "Sholder L",
                              ix: 1,
                              cix: 2,
                              np: 2,
                              it: [
                                {
                                  ty: "sh",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Shape - Group",
                                  nm: "Path 1",
                                  ix: 1,
                                  d: 1,
                                  ks: {
                                    a: 0,
                                    k: {
                                      c: true,
                                      i: [
                                        [-4.12, -6.518],
                                        [-17.734, 2.507],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [10.524, -4.921],
                                      ],
                                      o: [
                                        [10.733, 16.978],
                                        [21.405, -3.025],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-26.633, 12.454],
                                      ],
                                      v: [
                                        [-81.516, 95.614],
                                        [-43.013, 114.623],
                                        [98.105, 37.202],
                                        [79.477, -7.345],
                                        [-15.839, 28.595],
                                        [-63.601, 48.331],
                                      ],
                                    },
                                    ix: 2,
                                  },
                                },
                                {
                                  ty: "fl",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Graphic - Fill",
                                  nm: "Fill 1",
                                  c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 4 },
                                  r: 1,
                                  o: { a: 0, k: 100, ix: 5 },
                                },
                                {
                                  ty: "tr",
                                  a: { a: 0, k: [0, 0], ix: 1 },
                                  s: { a: 0, k: [100, 100], ix: 3 },
                                  sk: { a: 0, k: 0, ix: 4 },
                                  p: { a: 0, k: [0, 0], ix: 2 },
                                  r: { a: 0, k: 0, ix: 6 },
                                  sa: { a: 0, k: 0, ix: 5 },
                                  o: { a: 0, k: 100, ix: 7 },
                                },
                              ],
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [51.942, 79.031], ix: 2 },
                              r: { a: 0, k: 114.949, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100], t: 0 },
                              { s: [100, 100], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 180, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C2 Group",
                      ix: 2,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C2",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [70, 70], t: 0 },
                              { s: [70, 70], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9804, 0.3922, 0.3843],
                                t: 0,
                              },
                              { s: [0.9804, 0.3922, 0.3843], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [0, 0], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C3 Group",
                      ix: 3,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C3",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [40, 40], t: 0 },
                              { s: [40, 40], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9373, 0.6078, 0.6039],
                                t: 0,
                              },
                              { s: [0.9373, 0.6078, 0.6039], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, -174], t: 0 },
                              { s: [0, -174], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "st",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Stroke",
                      nm: "Stroke 1",
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 1, ix: 5 },
                      c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 3 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [260.408, 688.105], t: 1 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [261.12, 688.111], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [262.631, 688.119], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [265.461, 688.114], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [270.462, 688.035], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.741, 687.763], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.035, 687.293], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [290.911, 686.768], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [295.512, 686.264], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [299.186, 685.801], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [302.2, 685.381], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [304.717, 685.002], t: 12 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [306.843, 684.661], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [308.657, 684.356], t: 14 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [310.217, 684.083], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [311.563, 683.839], t: 16 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [312.728, 683.622], t: 17 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [313.734, 683.43], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [314.604, 683.26], t: 19 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [315.354, 683.111], t: 20 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [315.999, 682.981], t: 21 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.546, 682.869], t: 22 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.006, 682.775], t: 23 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.386, 682.696], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.694, 682.631], t: 25 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.936, 682.58], t: 26 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [318.244, 682.515], t: 28 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [318.244, 682.515], t: 31 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.698, 682.627], t: 32 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.104, 682.746], t: 33 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.806, 682.806], t: 34 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.526, 682.861], t: 36 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.469, 682.872], t: 41 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.995, 682.768], t: 42 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.588, 682.649], t: 43 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.891, 682.587], t: 44 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [318.185, 682.527], t: 46 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [318.337, 682.495], t: 50 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.326, 682.702], t: 51 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [311.244, 683.801], t: 52 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [304.345, 684.754], t: 53 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [300.837, 685.121], t: 54 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [298.749, 685.301], t: 55 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.42, 685.401], t: 56 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [296.562, 685.46], t: 57 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [296.029, 685.494], t: 58 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [295.748, 685.512], t: 59 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [295.785, 685.509], t: 61 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [296.451, 685.467], t: 62 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.196, 685.417], t: 63 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.577, 685.39], t: 64 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.801, 685.374], t: 65 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [298.095, 685.352], t: 68 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [298.134, 685.349], t: 70 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.184, 685.418], t: 71 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [291.465, 685.713], t: 72 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.056, 685.797], t: 73 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [281.788, 685.74], t: 74 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.863, 685.675], t: 75 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.644, 685.621], t: 76 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.855, 685.582], t: 77 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.364, 685.555], t: 78 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.104, 685.54], t: 79 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.155, 685.543], t: 81 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.861, 685.582], t: 82 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.652, 685.622], t: 83 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.056, 685.641], t: 84 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.293, 685.651], t: 85 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.542, 685.662], t: 87 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.465, 685.659], t: 91 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.94, 685.635], t: 92 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.127, 685.596], t: 93 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.062, 685.538], t: 94 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [275.764, 685.457], t: 95 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [274.279, 685.352], t: 96 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [272.646, 685.22], t: 97 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [270.908, 685.061], t: 98 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [269.094, 684.875], t: 99 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [267.247, 684.663], t: 100 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [265.404, 684.429], t: 101 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [263.602, 684.179], t: 102 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [261.879, 683.921], t: 103 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [260.267, 683.661], t: 104 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.808, 683.412], t: 105 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [257.536, 683.183], t: 106 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [256.489, 682.986], t: 107 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [255.697, 682.832], t: 108 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [255.189, 682.732], t: 109 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [255.166, 682.727], t: 111 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [256.062, 682.904], t: 112 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [257.057, 683.093], t: 113 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [257.561, 683.187], t: 114 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [257.857, 683.241], t: 115 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.043, 683.275], t: 116 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.296, 683.328], t: 131 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.537, 683.951], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.795, 684.611], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [259.021, 685.184], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [259.273, 685.815], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [259.522, 686.432], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [259.74, 686.969], t: 149 },
                          { s: [259.984, 687.561], t: 152 },
                        ],
                      },
                      r: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.25], t: 1 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.011], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [66.509], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [65.581], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [63.978], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.725], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [59.553], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [57.861], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [56.572], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.562], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.748], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.076], t: 12 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [53.515], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [53.041], t: 14 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [52.637], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [52.29], t: 16 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.992], t: 17 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.735], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.515], t: 19 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.325], t: 20 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.163], t: 21 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.025], t: 22 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.91], t: 23 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.815], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.738], t: 25 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.678], t: 26 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.633], t: 27 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.582], t: 29 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.578], t: 30 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.509], t: 31 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.102], t: 32 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.651], t: 33 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.421], t: 34 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.288], t: 35 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.203], t: 36 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.147], t: 37 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.112], t: 38 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.088], t: 40 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.159], t: 41 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.567], t: 42 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.019], t: 43 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.247], t: 44 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.381], t: 45 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.465], t: 46 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.52], t: 47 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.555], t: 48 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.578], t: 50 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.737], t: 51 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.732], t: 52 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [52.936], t: 53 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [53.578], t: 54 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [53.97], t: 55 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.224], t: 56 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.389], t: 57 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.492], t: 58 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.547], t: 59 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.561], t: 60 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.629], t: 61 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.033], t: 62 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.478], t: 63 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.703], t: 64 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.836], t: 65 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.919], t: 66 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.973], t: 67 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [56.025], t: 69 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [56.03], t: 70 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [56.202], t: 71 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [57.273], t: 72 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [58.538], t: 73 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [59.211], t: 74 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [59.617], t: 75 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [59.877], t: 76 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.046], t: 77 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.152], t: 78 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.209], t: 79 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.224], t: 80 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.287], t: 81 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.661], t: 82 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.074], t: 83 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.284], t: 84 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.406], t: 85 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.483], t: 86 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.534], t: 87 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.588], t: 90 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.631], t: 91 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.757], t: 92 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.954], t: 93 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [62.213], t: 94 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [62.532], t: 95 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [62.9], t: 96 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [63.311], t: 97 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [63.753], t: 98 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [64.221], t: 99 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [64.704], t: 100 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [65.193], t: 101 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [65.677], t: 102 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [66.147], t: 103 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [66.593], t: 104 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67], t: 105 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.36], t: 106 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.658], t: 107 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.886], t: 108 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.032], t: 109 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.084], t: 110 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.127], t: 111 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.38], t: 112 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.661], t: 113 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.804], t: 114 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.888], t: 115 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.941], t: 116 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [69.01], t: 119 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.963], t: 134 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.895], t: 136 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.847], t: 137 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.79], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.722], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.645], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.557], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.459], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.354], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.243], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.13], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.018], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.911], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.811], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.72], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.64], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.569], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.509], t: 152 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.459], t: 153 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.417], t: 154 },
                          { s: [67.358], t: 156 },
                        ],
                      },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Lower Group",
                  ix: 3,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Rigged Lower",
                      ix: 1,
                      cix: 2,
                      np: 1,
                      it: [
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Elbow L Group",
                          ix: 1,
                          cix: 2,
                          np: 1,
                          it: [
                            {
                              ty: "gr",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Group",
                              nm: "Sholder L",
                              ix: 1,
                              cix: 2,
                              np: 2,
                              it: [
                                {
                                  ty: "sh",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Shape - Group",
                                  nm: "Path 1",
                                  ix: 1,
                                  d: 1,
                                  ks: {
                                    a: 0,
                                    k: {
                                      c: true,
                                      i: [
                                        [-4.12, -6.518],
                                        [-17.734, 2.507],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [10.524, -4.921],
                                      ],
                                      o: [
                                        [10.733, 16.978],
                                        [21.405, -3.025],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-26.633, 12.454],
                                      ],
                                      v: [
                                        [-81.516, 95.614],
                                        [-43.013, 114.623],
                                        [98.105, 37.202],
                                        [116.928, 24.396],
                                        [100.146, -15.301],
                                        [79.477, -7.345],
                                        [-15.839, 28.595],
                                        [-63.601, 48.331],
                                      ],
                                    },
                                    ix: 2,
                                  },
                                },
                                {
                                  ty: "fl",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Graphic - Fill",
                                  nm: "Fill 1",
                                  c: { a: 0, k: [0.9373, 0.6078, 0.6039], ix: 4 },
                                  r: 1,
                                  o: { a: 0, k: 100, ix: 5 },
                                },
                                {
                                  ty: "tr",
                                  a: { a: 0, k: [0, 0], ix: 1 },
                                  s: { a: 0, k: [100, 100], ix: 3 },
                                  sk: { a: 0, k: 0, ix: 4 },
                                  p: { a: 0, k: [0, 0], ix: 2 },
                                  r: { a: 0, k: 0, ix: 6 },
                                  sa: { a: 0, k: 0, ix: 5 },
                                  o: { a: 0, k: 100, ix: 7 },
                                },
                              ],
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [51.942, 79.031], ix: 2 },
                              r: { a: 0, k: 114.949, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100], t: 0 },
                              { s: [100, 100], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 180, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C2 Group",
                      ix: 2,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C2",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [70, 70], t: 0 },
                              { s: [70, 70], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9804, 0.3922, 0.3843],
                                t: 0,
                              },
                              { s: [0.9804, 0.3922, 0.3843], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [0, 0], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C3 Group",
                      ix: 3,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C3",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [40, 40], t: 0 },
                              { s: [40, 40], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9373, 0.6078, 0.6039],
                                t: 0,
                              },
                              { s: [0.9373, 0.6078, 0.6039], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, -174], t: 0 },
                              { s: [0, -174], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [260.408, 688.105], t: 1 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [261.12, 688.111], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [262.631, 688.119], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [265.461, 688.114], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [270.462, 688.035], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.741, 687.763], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.035, 687.293], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [290.911, 686.768], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [295.512, 686.264], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [299.186, 685.801], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [302.2, 685.381], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [304.717, 685.002], t: 12 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [306.843, 684.661], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [308.657, 684.356], t: 14 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [310.217, 684.083], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [311.563, 683.839], t: 16 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [312.728, 683.622], t: 17 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [313.734, 683.43], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [314.604, 683.26], t: 19 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [315.354, 683.111], t: 20 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [315.999, 682.981], t: 21 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.546, 682.869], t: 22 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.006, 682.775], t: 23 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.386, 682.696], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.694, 682.631], t: 25 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.936, 682.58], t: 26 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [318.244, 682.515], t: 28 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [318.244, 682.515], t: 31 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.698, 682.627], t: 32 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.104, 682.746], t: 33 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.806, 682.806], t: 34 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.526, 682.861], t: 36 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.469, 682.872], t: 41 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [316.995, 682.768], t: 42 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.588, 682.649], t: 43 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.891, 682.587], t: 44 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [318.185, 682.527], t: 46 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [318.337, 682.495], t: 50 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [317.326, 682.702], t: 51 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [311.244, 683.801], t: 52 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [304.345, 684.754], t: 53 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [300.837, 685.121], t: 54 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [298.749, 685.301], t: 55 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.42, 685.401], t: 56 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [296.562, 685.46], t: 57 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [296.029, 685.494], t: 58 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [295.748, 685.512], t: 59 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [295.785, 685.509], t: 61 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [296.451, 685.467], t: 62 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.196, 685.417], t: 63 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.577, 685.39], t: 64 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.801, 685.374], t: 65 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [298.095, 685.352], t: 68 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [298.134, 685.349], t: 70 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [297.184, 685.418], t: 71 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [291.465, 685.713], t: 72 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [285.056, 685.797], t: 73 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [281.788, 685.74], t: 74 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.863, 685.675], t: 75 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.644, 685.621], t: 76 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.855, 685.582], t: 77 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.364, 685.555], t: 78 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.104, 685.54], t: 79 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.155, 685.543], t: 81 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.861, 685.582], t: 82 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.652, 685.622], t: 83 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.056, 685.641], t: 84 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.293, 685.651], t: 85 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.542, 685.662], t: 87 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [279.465, 685.659], t: 91 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.94, 685.635], t: 92 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [278.127, 685.596], t: 93 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [277.062, 685.538], t: 94 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [275.764, 685.457], t: 95 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [274.279, 685.352], t: 96 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [272.646, 685.22], t: 97 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [270.908, 685.061], t: 98 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [269.094, 684.875], t: 99 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [267.247, 684.663], t: 100 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [265.404, 684.429], t: 101 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [263.602, 684.179], t: 102 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [261.879, 683.921], t: 103 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [260.267, 683.661], t: 104 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.808, 683.412], t: 105 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [257.536, 683.183], t: 106 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [256.489, 682.986], t: 107 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [255.697, 682.832], t: 108 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [255.189, 682.732], t: 109 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [255.166, 682.727], t: 111 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [256.062, 682.904], t: 112 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [257.057, 683.093], t: 113 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [257.561, 683.187], t: 114 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [257.857, 683.241], t: 115 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.043, 683.275], t: 116 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.296, 683.328], t: 131 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.537, 683.951], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [258.795, 684.611], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [259.021, 685.184], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [259.273, 685.815], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [259.522, 686.432], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [259.74, 686.969], t: 149 },
                          { s: [259.984, 687.561], t: 152 },
                        ],
                      },
                      r: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.25], t: 1 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.011], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [66.509], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [65.581], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [63.978], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.725], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [59.553], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [57.861], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [56.572], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.562], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.748], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.076], t: 12 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [53.515], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [53.041], t: 14 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [52.637], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [52.29], t: 16 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.992], t: 17 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.735], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.515], t: 19 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.325], t: 20 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.163], t: 21 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.025], t: 22 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.91], t: 23 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.815], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.738], t: 25 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.678], t: 26 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.633], t: 27 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.582], t: 29 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.578], t: 30 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.509], t: 31 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.102], t: 32 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.651], t: 33 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.421], t: 34 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.288], t: 35 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.203], t: 36 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.147], t: 37 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.112], t: 38 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.088], t: 40 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.159], t: 41 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [49.567], t: 42 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.019], t: 43 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.247], t: 44 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.381], t: 45 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.465], t: 46 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.52], t: 47 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.555], t: 48 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.578], t: 50 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [50.737], t: 51 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [51.732], t: 52 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [52.936], t: 53 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [53.578], t: 54 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [53.97], t: 55 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.224], t: 56 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.389], t: 57 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.492], t: 58 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.547], t: 59 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.561], t: 60 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [54.629], t: 61 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.033], t: 62 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.478], t: 63 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.703], t: 64 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.836], t: 65 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.919], t: 66 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [55.973], t: 67 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [56.025], t: 69 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [56.03], t: 70 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [56.202], t: 71 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [57.273], t: 72 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [58.538], t: 73 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [59.211], t: 74 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [59.617], t: 75 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [59.877], t: 76 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.046], t: 77 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.152], t: 78 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.209], t: 79 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.224], t: 80 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.287], t: 81 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [60.661], t: 82 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.074], t: 83 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.284], t: 84 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.406], t: 85 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.483], t: 86 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.534], t: 87 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.588], t: 90 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.631], t: 91 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.757], t: 92 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [61.954], t: 93 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [62.213], t: 94 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [62.532], t: 95 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [62.9], t: 96 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [63.311], t: 97 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [63.753], t: 98 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [64.221], t: 99 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [64.704], t: 100 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [65.193], t: 101 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [65.677], t: 102 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [66.147], t: 103 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [66.593], t: 104 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67], t: 105 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.36], t: 106 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.658], t: 107 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.886], t: 108 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.032], t: 109 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.084], t: 110 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.127], t: 111 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.38], t: 112 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.661], t: 113 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.804], t: 114 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.888], t: 115 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.941], t: 116 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [69.01], t: 119 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.963], t: 134 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.895], t: 136 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.847], t: 137 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.79], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.722], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.645], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.557], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.459], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.354], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.243], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.13], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [68.018], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.911], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.811], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.72], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.64], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.569], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.509], t: 152 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.459], t: 153 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [67.417], t: 154 },
                          { s: [67.358], t: 156 },
                        ],
                      },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Admin",
              ix: 2,
              cix: 2,
              it: [
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100], t: 0 },
                      { s: [100, 100], t: 180 },
                    ],
                  },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 2,
        },
        {
          ty: 4,
          nm: "Hand R",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [1.066, -28.734, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Hand R",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [-1.919, 3.516],
                        [-3.199, 2.637],
                        [-3.838, 3.223],
                        [-0.32, 2.93],
                        [1.279, 5.274],
                        [6.717, 2.05],
                        [0, -3.223],
                        [0, 0],
                        [3.199, 1.172],
                        [-2.239, -3.808],
                        [-2.879, -1.465],
                        [0, 0],
                        [6.077, 2.344],
                        [4.159, -2.344],
                        [-4.159, -3.223],
                        [-2.879, -1.172],
                        [4.479, 1.758],
                        [-1.28, -3.223],
                        [-0.32, -1.172],
                        [1.599, -3.516],
                      ],
                      o: [
                        [5.758, -1.465],
                        [1.92, -3.516],
                        [3.199, -2.637],
                        [3.838, -3.223],
                        [0.32, -2.93],
                        [-1.28, -5.274],
                        [-6.718, -2.051],
                        [0, 3.223],
                        [0, 0],
                        [-3.199, -1.172],
                        [2.239, 3.809],
                        [2.879, 1.465],
                        [0, 0],
                        [-6.078, -2.344],
                        [-4.158, 2.344],
                        [0, 0],
                        [0, 0],
                        [-4.478, -1.758],
                        [1.279, 3.223],
                        [0.32, 1.172],
                        [-1.599, 3.516],
                      ],
                      v: [
                        [-1.64, 28.394],
                        [13.714, 22.827],
                        [21.711, 15.796],
                        [26.829, 7.3],
                        [27.149, -4.419],
                        [26.51, -18.482],
                        [13.395, -26.978],
                        [1.878, -25.806],
                        [8.916, -18.189],
                        [-5.798, -22.876],
                        [-11.876, -17.603],
                        [2.518, -7.348],
                        [6.996, -2.954],
                        [-7.718, -5.298],
                        [-23.392, -10.571],
                        [-21.153, -2.075],
                        [-9.317, 4.663],
                        [-22.752, 3.199],
                        [-28.19, 6.714],
                        [-14.116, 13.745],
                        [-17.954, 21.363],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9961, 0.7686, 0.6784], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 3,
          parent: 7,
        },
        {
          ty: 4,
          nm: "Phone",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [518.242, 588.18, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [-31.26, -16.294, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-6.194, -1.406],
                        [0, 0],
                        [-2.93, 5.714],
                        [0, 0],
                        [5.851, 1.365],
                        [0, 0],
                        [3.003, -5.836],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [5.913, 1.342],
                        [0, 0],
                        [2.905, -5.663],
                        [0, 0],
                        [-6.133, -1.431],
                        [0, 0],
                        [-3.028, 5.887],
                      ],
                      v: [
                        [-65.756, 66.943],
                        [-8.27, 80.3],
                        [7.721, 72.375],
                        [71.539, -53.987],
                        [66.224, -66.707],
                        [9.238, -80.267],
                        [-7.326, -72.299],
                        [-71.512, 53.733],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "gf",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - G-Fill",
                  nm: "Gradient Fill 1",
                  e: { a: 0, k: [-589.75, 360.053], ix: 6 },
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 1, 1, 0.454, 0.9882352941176471, 0.796078431372549, 0.792156862745098, 1,
                        0.9411764705882353, 0.5607843137254902, 0.5568627450980392,
                      ],
                      ix: 9,
                    },
                  },
                  t: 1,
                  a: { a: 0, k: 0 },
                  h: { a: 0, k: 0 },
                  s: { a: 0, k: [-453.702, 490.121], ix: 5 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 10 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [520.955, 588.273], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-9.902, -4.196],
                        [0, 0],
                        [-2.922, 5.696],
                        [0, 0],
                        [5.813, 1.361],
                        [0, 0],
                        [2.981, -5.793],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [5.878, 1.33],
                        [0, 0],
                        [2.895, -5.645],
                        [0, 0],
                        [-10.444, -0.369],
                        [0, 0],
                        [-3.007, 5.843],
                      ],
                      v: [
                        [-64.346, 66.576],
                        [-7.136, 79.839],
                        [8.775, 71.927],
                        [72.342, -53.876],
                        [67.08, -66.556],
                        [10.396, -80.082],
                        [-8.391, -72.257],
                        [-72.037, 52.628],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9686, 0.5686, 0.5647], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [516.332, 587.622], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 4,
          parent: 3,
        },
        {
          ty: 4,
          nm: "Hand L",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [414.28, 611.915, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [-1.22, -3.085, 0], ix: 2 },
            r: { a: 0, k: -10, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [4.881, 1.714],
                        [8.201, -0.196],
                        [9.831, -1.417],
                        [8.385, -3.977],
                        [3.019, -1.394],
                        [-6.079, -9.225],
                        [-1.439, 0.514],
                        [-6.647, -0.337],
                        [-6.923, 1.157],
                        [-4.951, 3.132],
                        [0.866, 2.611],
                        [16.642, -3.64],
                        [-7.037, 0.091],
                        [-7.037, 0.091],
                      ],
                      o: [
                        [-4.881, -1.715],
                        [-8.2, 0.196],
                        [-9.832, 1.418],
                        [-1.781, 0.845],
                        [-4.511, 2.082],
                        [6.079, 9.225],
                        [9.298, -3.318],
                        [7.675, 0.389],
                        [6.923, -1.157],
                        [4.951, -3.132],
                        [-0.866, -2.612],
                        [0, 0],
                        [7.037, -0.092],
                        [7.037, -0.092],
                      ],
                      v: [
                        [63.191, -21.725],
                        [29.012, -28.348],
                        [-13.905, -28.812],
                        [-41.228, -9.694],
                        [-48.512, -6.294],
                        [-53.241, 15.051],
                        [-36.367, 20.478],
                        [-19.83, 14.746],
                        [-6.394, 14.082],
                        [10.274, -0.318],
                        [21.638, -9.402],
                        [3.789, -11.57],
                        [26.659, -17.38],
                        [55.616, -15.668],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9961, 0.7686, 0.6784], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [-42.875, 6.5], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [415.662, 610.974], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 5,
          parent: 1,
        },
        {
          ty: 4,
          nm: "Body",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [2.725, 198.498, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [364.803, 863.954, 0], ix: 2 },
            r: {
              a: 1,
              k: [
                { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [0], t: 0 },
                { o: { x: 0.333, y: 0 }, i: { x: 0.5, y: 1 }, s: [2], t: 30 },
                { o: { x: 0.5, y: 0 }, i: { x: 0.5, y: 1 }, s: [2], t: 130 },
                { s: [0], t: 160 },
              ],
              ix: 10,
            },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Body",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [19.104, -23.871],
                        [-0.925, -24.809],
                        [0, 0],
                        [-47.005, 10.403],
                        [0, 0],
                        [3.704, 24.808],
                        [14.806, 20.37],
                        [0, 0],
                        [31.501, 1.351],
                      ],
                      o: [
                        [0, 0],
                        [-19.165, 23.946],
                        [0.926, 24.809],
                        [0, 0],
                        [47.006, -10.404],
                        [0, 0],
                        [-3.703, -24.809],
                        [-11.664, -16.048],
                        [0, 0],
                        [-31.501, -1.351],
                      ],
                      v: [
                        [-31.973, -198.498],
                        [-116.169, -163.052],
                        [-111.105, -81.132],
                        [-90.783, 193.875],
                        [49.714, 193.875],
                        [129.159, 173.951],
                        [109.223, -91.122],
                        [95.263, -175.55],
                        [33.084, -195.403],
                        [11.784, -168.552],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9686, 0.549, 0.5412], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 6,
        },
        {
          ty: 3,
          nm: "Clothe Wrist",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [-0.063, 13.208, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          ind: 7,
          parent: 25,
        },
        {
          ty: 3,
          nm: "Head Null",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [50, -26.25, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [3.304, -282.312, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 0, ix: 11 },
          },
          ef: [],
          ind: 8,
          parent: 6,
        },
        {
          ty: 3,
          nm: "Face Null",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [50, 50, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0, y: 1 },
                  s: [66.545, -21.816, 0],
                  t: 0,
                  ti: [0, -1.667, 0],
                  to: [0, 1.667, 0],
                },
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0.5, y: 1 },
                  s: [66.545, -11.816, 0],
                  t: 30,
                  ti: [0, 0, 0],
                  to: [0, 0, 0],
                },
                {
                  o: { x: 0.5, y: 0 },
                  i: { x: 0.5, y: 1 },
                  s: [66.545, -11.816, 0],
                  t: 130,
                  ti: [0, 1.667, 0],
                  to: [0, -1.667, 0],
                },
                { s: [66.545, -21.816, 0], t: 160 },
              ],
              ix: 2,
            },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 0, ix: 11 },
          },
          ef: [],
          ind: 9,
          parent: 8,
        },
        {
          ty: 4,
          nm: "Chick",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [81.207, 55.316, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Chick",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.246, 3.54],
                        [4.357, -0.302],
                        [-0.246, -3.54],
                        [-4.357, 0.302],
                      ],
                      o: [
                        [-0.246, -3.54],
                        [-4.357, 0.302],
                        [0.246, 3.54],
                        [4.357, -0.302],
                      ],
                      v: [
                        [7.889, -0.547],
                        [-0.445, -6.41],
                        [-7.889, 0.547],
                        [0.445, 6.41],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9765, 0.749, 0.7333], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 10,
          parent: 9,
        },
        {
          ty: 4,
          nm: "Mouth",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [387.163, 419.159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [55.235, 81.581, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [5.845, -0.401],
                        [2.58, 4.745],
                        [0, 0],
                      ],
                      o: [
                        [-2.471, 4.023],
                        [-6.807, 0.474],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [14.332, -3.701],
                        [1.097, 3.653],
                        [-14.332, -3.701],
                        [0.463, -3.701],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [386.96, 415.514], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [8.738, -0.605],
                        [0.488, 7.099],
                        [-0.197, 0.94],
                        [0, 0],
                        [0, 0],
                        [-0.022, -0.233],
                      ],
                      o: [
                        [-8.746, 0.612],
                        [-0.073, -0.984],
                        [0, 0],
                        [0, 0],
                        [0.029, 0.226],
                        [0.488, 7.106],
                      ],
                      v: [
                        [0.894, 7.297],
                        [-15.825, -4.459],
                        [-15.628, -7.345],
                        [-0.907, -7.345],
                        [15.733, -7.345],
                        [15.827, -6.66],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.8078, 0.4706, 0.4471], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [387.163, 419.159], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 11,
          parent: 9,
        },
        {
          ty: 4,
          nm: "Layer 1",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [18.793, 63.091, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Chick",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [7.764, -0.539],
                        [-0.438, -6.309],
                        [-7.764, 0.539],
                        [0.438, 6.308],
                      ],
                      o: [
                        [-7.764, 0.539],
                        [0.438, 6.309],
                        [7.764, -0.539],
                        [-0.438, -6.308],
                      ],
                      v: [
                        [-0.792, -11.423],
                        [-14.058, 0.975],
                        [0.792, 11.423],
                        [14.058, -0.975],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9765, 0.749, 0.7333], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 12,
          parent: 9,
        },
        {
          ty: 4,
          nm: "Nose",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [53.884, 56.338, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Nose",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [1.722, 1.34],
                        [1.62, -1.462],
                        [0, 0],
                        [-0.483, 1.225],
                      ],
                      o: [
                        [-1.722, -1.34],
                        [0, 0],
                        [1.314, -0.085],
                        [0.801, -2.03],
                      ],
                      v: [
                        [2.064, -3.666],
                        [-3.962, -3.503],
                        [0.747, 4.637],
                        [3.619, 2.237],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.8078, 0.4706, 0.4471], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 13,
          parent: 9,
        },
        {
          ty: 4,
          nm: "Eye R",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [402.18, 379.341, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [70.252, 41.763, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [1.721, -0.052],
                        [-1.721, 0.052],
                      ],
                      o: [
                        [-1.719, 0.052],
                        [1.719, -0.052],
                      ],
                      v: [
                        [-0.04, -1.335],
                        [0.04, 1.335],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: {
                    a: 1,
                    k: [
                      {
                        o: { x: 0.333, y: 0 },
                        i: { x: 0, y: 1 },
                        s: [404.512, 378.047],
                        t: 0,
                        ti: [0, -0.5],
                        to: [0, 0.5],
                      },
                      {
                        o: { x: 0.333, y: 0 },
                        i: { x: 0.5, y: 1 },
                        s: [404.512, 381.047],
                        t: 30,
                        ti: [0, 0],
                        to: [0, 0],
                      },
                      {
                        o: { x: 0.5, y: 0 },
                        i: { x: 0.5, y: 1 },
                        s: [404.512, 381.047],
                        t: 130,
                        ti: [0, 0.5],
                        to: [0, -0.5],
                      },
                      { s: [404.512, 378.047], t: 160 },
                    ],
                    ix: 2,
                  },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.124, -4.133],
                        [-2.917, 0.088],
                        [0.124, 4.133],
                        [2.917, -0.088],
                      ],
                      o: [
                        [0.124, 4.133],
                        [2.917, -0.088],
                        [-0.124, -4.133],
                        [-2.917, 0.088],
                      ],
                      v: [
                        [-5.282, 0.159],
                        [0.225, 7.483],
                        [5.282, -0.159],
                        [-0.225, -7.483],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.1294, 0.102, 0.0902], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [402.18, 379.341], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 14,
          parent: 9,
        },
        {
          ty: 4,
          nm: "Eye L",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [363.444, 380.507, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [31.516, 42.929, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [1.702, -0.051],
                        [-1.702, 0.051],
                      ],
                      o: [
                        [-1.699, 0.051],
                        [1.699, -0.051],
                      ],
                      v: [
                        [-0.04, -1.321],
                        [0.04, 1.321],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: {
                    a: 1,
                    k: [
                      { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [364.94, 380], t: 0, ti: [0, -0.5], to: [0, 0.5] },
                      { o: { x: 0.333, y: 0 }, i: { x: 0.5, y: 1 }, s: [364.94, 383], t: 30, ti: [0, 0], to: [0, 0] },
                      {
                        o: { x: 0.5, y: 0 },
                        i: { x: 0.5, y: 1 },
                        s: [364.94, 383],
                        t: 130,
                        ti: [0, 0.5],
                        to: [0, -0.5],
                      },
                      { s: [364.94, 380], t: 160 },
                    ],
                    ix: 2,
                  },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.124, -4.133],
                        [2.917, -0.088],
                        [0.124, 4.133],
                        [-2.917, 0.088],
                      ],
                      o: [
                        [0.124, 4.133],
                        [-2.917, 0.088],
                        [-0.124, -4.133],
                        [2.917, -0.088],
                      ],
                      v: [
                        [5.282, -0.159],
                        [0.225, 7.483],
                        [-5.282, 0.159],
                        [-0.225, -7.483],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.1294, 0.102, 0.0902], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [363.444, 380.507], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 15,
          parent: 9,
        },
        {
          ty: 4,
          nm: "Eyebrow L",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [29.163, 25.787, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Eyebrow L",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.696, 0.161],
                        [-1.234, -0.028],
                        [-2.185, -0.049],
                        [-0.261, 0.919],
                        [0.166, 0.439],
                        [1.361, 0.115],
                        [3.019, -2.082],
                        [-0.314, -1.099],
                      ],
                      o: [
                        [1.213, -0.281],
                        [2.185, 0.049],
                        [0.725, 0.016],
                        [0.129, -0.454],
                        [-0.632, -1.667],
                        [-3.377, -0.286],
                        [-0.588, 0.406],
                        [0.28, 0.981],
                      ],
                      v: [
                        [-3.532, 1.461],
                        [0.158, 1.235],
                        [6.713, 1.384],
                        [8.594, 0.353],
                        [8.475, -1.068],
                        [4.907, -3.297],
                        [-5.001, -0.866],
                        [-8.637, 3.134],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.1294, 0.102, 0.0902], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 16,
          parent: 9,
        },
        {
          ty: 4,
          nm: "Eyebrow R",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [66.89, 18.419, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Eyebrow R",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.218, -0.393],
                        [-0.764, 0.292],
                        [-2.303, 0.881],
                        [-1.333, 0.189],
                        [-0.12, 1.062],
                        [0.695, 0.171],
                        [3.522, -1.565],
                        [0.369, -1.864],
                      ],
                      o: [
                        [0.441, 0.795],
                        [2.303, -0.881],
                        [1.301, -0.497],
                        [0.766, -0.109],
                        [0.135, -1.19],
                        [-3.568, -0.877],
                        [-1.419, 0.631],
                        [-0.097, 0.491],
                      ],
                      v: [
                        [-9.271, 3.098],
                        [-7.097, 3.384],
                        [-0.188, 0.741],
                        [3.756, -0.445],
                        [9.457, -0.763],
                        [4.892, -3.271],
                        [-6.026, -1.861],
                        [-9.401, 1.669],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.1294, 0.102, 0.0902], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 17,
          parent: 9,
        },
        {
          ty: 4,
          nm: "Hair 3",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [-19.113, -62.44, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Hair",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-1.403, 10.387],
                        [-7.277, 4.631],
                        [-6.416, -11.496],
                        [1.139, -8.304],
                        [2.079, -3.367],
                        [3.152, 0.46],
                        [0.9, 0.072],
                        [0.183, 0.004],
                        [2.785, -1.615],
                        [1.26, -1.945],
                        [2.099, 3.157],
                      ],
                      o: [
                        [1.207, -8.934],
                        [10.563, -6.722],
                        [4.192, 7.51],
                        [-0.533, 3.887],
                        [-1.391, 2.253],
                        [-1.052, -0.154],
                        [-0.182, -0.014],
                        [-3.223, 0.01],
                        [-2.081, 1.122],
                        [-3.962, 4.328],
                        [-5.079, -7.639],
                      ],
                      v: [
                        [-24.369, -5.634],
                        [-11.938, -27.447],
                        [21.024, -20.782],
                        [24.146, 4.603],
                        [20.507, 15.739],
                        [11.25, 23.366],
                        [8.201, 22.999],
                        [7.655, 22.971],
                        [-1.358, 25.409],
                        [-6.37, 30.009],
                        [-18.393, 23.707],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3255, 0.2784, 0.2549], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 18,
          parent: 8,
        },
        {
          ty: 4,
          nm: "Hair 2",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0, y: 1 },
                  s: [40.64, -104.138, 0],
                  t: 0,
                  ti: [0, -1.667, 0],
                  to: [0, 1.667, 0],
                },
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0.5, y: 1 },
                  s: [40.64, -94.138, 0],
                  t: 30,
                  ti: [0, 0, 0],
                  to: [0, 0, 0],
                },
                {
                  o: { x: 0.5, y: 0 },
                  i: { x: 0.5, y: 1 },
                  s: [40.64, -94.138, 0],
                  t: 130,
                  ti: [0, 1.667, 0],
                  to: [0, -1.667, 0],
                },
                { s: [40.64, -104.138, 0], t: 160 },
              ],
              ix: 2,
            },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Hair",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-3.793, 0.715],
                        [-13.127, 4.911],
                        [-2.856, 11.537],
                        [12.081, 5.658],
                        [21.534, -14.884],
                        [4.514, -15.006],
                        [-10.138, -3.997],
                        [-11.948, 2.125],
                      ],
                      o: [
                        [13.863, -2.614],
                        [10.667, -3.991],
                        [3.573, -14.435],
                        [-23.59, -11.048],
                        [-13.073, 9.036],
                        [-2.59, 8.609],
                        [11.777, 4.643],
                        [3.771, -0.671],
                      ],
                      v: [
                        [-4.492, 33.037],
                        [36.398, 22.419],
                        [61.733, -0.783],
                        [41.092, -31.955],
                        [-33.182, -26.166],
                        [-60.603, 11.318],
                        [-54.133, 36.94],
                        [-15.845, 35.102],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3255, 0.2784, 0.2549], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 19,
          parent: 8,
        },
        {
          ty: 4,
          nm: "Ear",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [-8.627, -17.406, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Ear",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [7.669, 5.16],
                        [0.59, 7.765],
                        [-0.138, 1.427],
                        [-1.178, 2.193],
                        [-6.125, -0.495],
                        [-2.932, -5.41],
                        [0, 0],
                      ],
                      o: [
                        [-8.257, 4.134],
                        [-6.48, -4.354],
                        [-0.104, -1.409],
                        [0.247, -2.48],
                        [2.939, -5.404],
                        [6.138, 0.499],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.879, 19.009],
                        [-7.503, 17.381],
                        [-18.811, -2.205],
                        [-18.762, -6.453],
                        [-16.7, -13.585],
                        [-1.236, -21.68],
                        [13.478, -11.803],
                        [15.554, 0.046],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 0.8118, 0.7922], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 20,
          parent: 8,
        },
        {
          ty: 4,
          nm: "Face",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [48.856, -33.993, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Face",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [5.594, 22.49],
                        [1.521, 4.161],
                        [11.823, 7.991],
                        [18.304, -20.175],
                        [2.495, -5.89],
                        [-3.358, -14.962],
                        [-7.191, -11.204],
                        [-6.081, -5.589],
                        [-4.09, -2.457],
                        [-9.616, -0.317],
                        [-7.332, 4.577],
                        [-3.586, 8.719],
                      ],
                      o: [
                        [-1.076, -4.341],
                        [-4.791, -13.132],
                        [-22.699, -15.326],
                        [-4.344, 4.78],
                        [-6.162, 14.442],
                        [2.946, 13.234],
                        [4.427, 6.883],
                        [3.478, 3.198],
                        [8.218, 4.935],
                        [8.688, 0.285],
                        [8.267, -5.162],
                        [8.825, -21.485],
                      ],
                      v: [
                        [57.038, -20.736],
                        [53.126, -33.514],
                        [27.641, -66.727],
                        [-45.082, -58.123],
                        [-55.437, -41.985],
                        [-58.561, 2.96],
                        [-42.59, 40.184],
                        [-26.954, 59.145],
                        [-15.551, 67.692],
                        [11.553, 76.116],
                        [36.425, 69.536],
                        [53.283, 47.706],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 0.8118, 0.7922], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 21,
          parent: 8,
        },
        {
          ty: 4,
          nm: "Shadow",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [-7.166, -22.652, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Shadow",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [15.196, 8.816],
                        [4.786, 3.254],
                        [1.277, 6.001],
                        [1.051, 4.921],
                        [-3.562, -1.821],
                        [-15.384, -6.893],
                        [-4.965, -1.041],
                      ],
                      o: [
                        [-17.18, -3.833],
                        [-4.833, -2.799],
                        [-0.937, -6.046],
                        [-1.051, -4.921],
                        [2.943, 2.673],
                        [15.01, 7.671],
                        [4.635, 2.071],
                        [0, 0],
                      ],
                      v: [
                        [35.135, 30.394],
                        [-13.857, 11.473],
                        [-28.492, 2.417],
                        [-31.985, -15.616],
                        [-35.135, -30.394],
                        [-25.859, -24.543],
                        [19.828, -2.884],
                        [34.106, 2.306],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.9765, 0.749, 0.7333], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 22,
          parent: 24,
        },
        {
          ty: 4,
          nm: "Hair",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [37.824, -40.586, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Hair",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0.468, -5.736],
                        [13.939, -13.757],
                        [8.348, -3.246],
                        [9.818, 0.511],
                        [5.323, 2.315],
                        [4.968, 4.87],
                        [5.807, 10.376],
                        [-0.035, 11.891],
                        [-1.561, 3.722],
                        [-3.973, 3.62],
                        [-17.109, 4.744],
                        [-18.35, -0.699],
                        [-3.731, -2.917],
                        [-1.668, -3.395],
                        [-1.223, -10.36],
                      ],
                      o: [
                        [-1.592, 19.52],
                        [-6.375, 6.292],
                        [-9.163, 3.563],
                        [-5.796, -0.302],
                        [-6.379, -2.774],
                        [-8.491, -8.324],
                        [-5.807, -10.376],
                        [0.012, -4.036],
                        [2.079, -4.957],
                        [13.124, -11.958],
                        [17.696, -4.907],
                        [4.644, 0.177],
                        [3.051, 2.385],
                        [4.599, 9.36],
                        [0.674, 5.714],
                      ],
                      v: [
                        [70.125, -4.957],
                        [46.071, 47.297],
                        [23.842, 62.062],
                        [-5.136, 66.224],
                        [-22.098, 62.731],
                        [-38.776, 50.387],
                        [-60.903, 22.562],
                        [-70.405, -11.414],
                        [-68.373, -23.269],
                        [-58.443, -35.805],
                        [-12.806, -62.312],
                        [41.937, -65.858],
                        [54.55, -61.857],
                        [60.942, -52.047],
                        [69.796, -22.188],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.3255, 0.2784, 0.2549], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 23,
          parent: 8,
        },
        {
          ty: 4,
          nm: "neck",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 53.046, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [3.304, -153.016, 0], ix: 2 },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "neck",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-3.562, -1.821],
                        [-15.384, -6.893],
                        [-4.965, -1.041],
                        [0, 0],
                        [0.5, -1.46],
                        [0.019, -0.047],
                        [13.133, 0],
                        [3.764, 12.95],
                        [0.833, 7.982],
                        [0.394, 2.424],
                        [1.277, 6.001],
                        [1.051, 4.921],
                      ],
                      o: [
                        [15.01, 7.671],
                        [4.635, 2.071],
                        [0, 0],
                        [0, 0],
                        [-0.016, 0.048],
                        [-4.615, 11.501],
                        [-14.165, 0],
                        [-0.286, -0.087],
                        [-0.26, -2.446],
                        [-0.937, -6.046],
                        [-1.051, -4.921],
                        [2.943, 2.673],
                      ],
                      v: [
                        [-33.024, -47.195],
                        [12.663, -25.536],
                        [26.94, -20.346],
                        [27.969, 7.743],
                        [42.288, 28.944],
                        [42.213, 29.074],
                        [-2.969, 53.046],
                        [-36.042, 31.819],
                        [-34.685, -12.924],
                        [-35.658, -20.235],
                        [-39.151, -38.268],
                        [-42.301, -53.046],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 0.8118, 0.7922], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 24,
          parent: 6,
        },
        {
          ty: 3,
          nm: "Right-Wrist",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100, 100], t: 0 },
                { s: [100, 100, 100], t: 180 },
              ],
            },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0, y: 1 },
                  s: [565, 620, 0],
                  t: 0,
                  ti: [2.75, 0, 0],
                  to: [-2.75, 0, 0],
                },
                {
                  o: { x: 0.167, y: 0.167 },
                  i: { x: 0.833, y: 0.833 },
                  s: [548.5, 620, 0],
                  t: 30,
                  ti: [0, 0, 0],
                  to: [0, 0, 0],
                },
                {
                  o: { x: 0.5, y: 0 },
                  i: { x: 0.5, y: 1 },
                  s: [548.5, 620, 0],
                  t: 130,
                  ti: [-2.75, 0, 0],
                  to: [2.75, 0, 0],
                },
                { s: [565, 620, 0], t: 160 },
              ],
              ix: 2,
            },
            r: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0], t: 0 },
                { s: [0], t: 180 },
              ],
            },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [
            {
              ty: 0,
              mn: "Pseudo/Limber_16",
              nm: "Limber",
              ix: 1,
              en: 1,
              ef: [
                { ty: 6, mn: "Pseudo/Limber_16-0001", nm: "Shape", ix: 1, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0002", nm: "Upper Length", ix: 2, v: { a: 0, k: 173, ix: 2 } },
                { ty: 0, mn: "Pseudo/Limber_16-0003", nm: "Lower Length", ix: 3, v: { a: 0, k: 65, ix: 3 } },
                { ty: 0, mn: "Pseudo/Limber_16-0004", nm: "Start Size", ix: 4, v: { a: 0, k: 81, ix: 4 } },
                { ty: 0, mn: "Pseudo/Limber_16-0005", nm: "Middle Size", ix: 5, v: { a: 0, k: 70, ix: 5 } },
                { ty: 0, mn: "Pseudo/Limber_16-0006", nm: "End Size", ix: 6, v: { a: 0, k: 1, ix: 6 } },
                { ty: 0, mn: "Pseudo/Limber_16-0007", nm: "Bone Curvature", ix: 7, v: { a: 0, k: 0, ix: 7 } },
                { ty: 7, mn: "Pseudo/Limber_16-0008", nm: "Square End", ix: 8, v: { a: 0, k: 1, ix: 8 } },
                { ty: 6, mn: "Pseudo/Limber_16-0009", nm: "Limber", ix: 9, v: 0 },
                { ty: 6, mn: "Pseudo/Limber_16-0010", nm: "Scale", ix: 10, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0011", nm: "Size Scale", ix: 11, v: { a: 0, k: 100, ix: 11 } },
                {
                  ty: 7,
                  mn: "Pseudo/Limber_16-0012",
                  nm: "Link Length to Size Scale",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
                { ty: 6, mn: "Pseudo/Limber_16-0013", nm: "Limber", ix: 13, v: 0 },
                { ty: 6, mn: "Pseudo/Limber_16-0014", nm: "Colors", ix: 14, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0015", nm: "Upper Split", ix: 15, v: { a: 0, k: 100, ix: 15 } },
                { ty: 0, mn: "Pseudo/Limber_16-0016", nm: "Upper Rounding", ix: 16, v: { a: 0, k: 0, ix: 16 } },
                { ty: 0, mn: "Pseudo/Limber_16-0017", nm: "Lower Split", ix: 17, v: { a: 0, k: 70, ix: 17 } },
                { ty: 0, mn: "Pseudo/Limber_16-0018", nm: "Lower Rounding", ix: 18, v: { a: 0, k: 0, ix: 18 } },
                {
                  ty: 2,
                  mn: "Pseudo/Limber_16-0019",
                  nm: "Upper Color",
                  ix: 19,
                  v: { a: 0, k: [0.4275, 0.3255, 0.8118], ix: 19 },
                },
                {
                  ty: 2,
                  mn: "Pseudo/Limber_16-0020",
                  nm: "Middle Color",
                  ix: 20,
                  v: { a: 0, k: [0.4275, 0.3255, 0.8118], ix: 20 },
                },
                {
                  ty: 2,
                  mn: "Pseudo/Limber_16-0021",
                  nm: "Lower Color",
                  ix: 21,
                  v: { a: 0, k: [0.6824, 0.749, 0.8706], ix: 21 },
                },
                { ty: 6, mn: "Pseudo/Limber_16-0022", nm: "Limber", ix: 22, v: 0 },
                { ty: 6, mn: "Pseudo/Limber_16-0023", nm: "Dynamics", ix: 23, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0024", nm: "Clockwise", ix: 24, v: { a: 0, k: -100, ix: 24 } },
                { ty: 0, mn: "Pseudo/Limber_16-0025", nm: "Rotate Start", ix: 25, v: { a: 0, k: 0, ix: 25 } },
                { ty: 0, mn: "Pseudo/Limber_16-0026", nm: "Rotate End", ix: 26, v: { a: 0, k: 0, ix: 26 } },
                { ty: 7, mn: "Pseudo/Limber_16-0027", nm: "Stretch", ix: 27, v: { a: 0, k: 1, ix: 27 } },
                { ty: 0, mn: "Pseudo/Limber_16-0028", nm: "Anti-pop", ix: 28, v: { a: 0, k: 0, ix: 28 } },
                { ty: 6, mn: "Pseudo/Limber_16-0029", nm: "Limber", ix: 29, v: 0 },
                { ty: 6, mn: "Pseudo/Limber_16-0030", nm: "Forward Kinematics", ix: 30, v: 0 },
                { ty: 0, mn: "Pseudo/Limber_16-0031", nm: "FK", ix: 31, v: { a: 0, k: 0, ix: 31 } },
                { ty: 0, mn: "Pseudo/Limber_16-0032", nm: "Upper FK Rotation", ix: 32, v: { a: 0, k: 180, ix: 32 } },
                { ty: 0, mn: "Pseudo/Limber_16-0033", nm: "Lower FK Rotation", ix: 33, v: { a: 0, k: 0, ix: 33 } },
                { ty: 6, mn: "Pseudo/Limber_16-0034", nm: "Limber", ix: 34, v: 0 },
              ],
            },
          ],
          ind: 25,
        },
        {
          ty: 4,
          nm: "Right-Arm",
          sr: 1,
          st: 0,
          op: 300,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0, 0], t: 0 },
                { s: [0, 0, 0], t: 180 },
              ],
            },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0, 0], t: 0 },
                { s: [0, 0, 0], t: 180 },
              ],
            },
            r: { a: 0, k: 0, ix: 10 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100, ix: 11 },
          },
          ef: [],
          shapes: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Limb",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Lower Group 2",
                  ix: 1,
                  cix: 2,
                  np: 4,
                  it: [
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Rigged Lower",
                      ix: 1,
                      cix: 2,
                      np: 1,
                      it: [
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Elbow L Group",
                          ix: 1,
                          cix: 2,
                          np: 1,
                          it: [
                            {
                              ty: "gr",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Group",
                              nm: "Sholder L",
                              ix: 1,
                              cix: 2,
                              np: 2,
                              it: [
                                {
                                  ty: "sh",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Shape - Group",
                                  nm: "Path 1",
                                  ix: 1,
                                  d: 1,
                                  ks: {
                                    a: 0,
                                    k: {
                                      c: true,
                                      i: [
                                        [-4.12, -6.518],
                                        [-17.734, 2.507],
                                        [-35.292, 24.23],
                                        [0, 0],
                                        [0, 0],
                                        [10.524, -4.921],
                                      ],
                                      o: [
                                        [10.733, 16.978],
                                        [14.9, -2.106],
                                        [-3.744, -6.685],
                                        [0, 0],
                                        [0, 0],
                                        [-26.633, 12.454],
                                      ],
                                      v: [
                                        [-81.516, 95.614],
                                        [-43.013, 114.623],
                                        [67.511, 48.1],
                                        [25.311, 16.771],
                                        [-15.839, 28.595],
                                        [-63.601, 48.331],
                                      ],
                                    },
                                    ix: 2,
                                  },
                                },
                                {
                                  ty: "fl",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Graphic - Fill",
                                  nm: "Fill 1",
                                  c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 4 },
                                  r: 1,
                                  o: { a: 0, k: 100, ix: 5 },
                                },
                                {
                                  ty: "tr",
                                  a: { a: 0, k: [0, 0], ix: 1 },
                                  s: { a: 0, k: [100, 100], ix: 3 },
                                  sk: { a: 0, k: 0, ix: 4 },
                                  p: { a: 0, k: [0, 0], ix: 2 },
                                  r: { a: 0, k: 0, ix: 6 },
                                  sa: { a: 0, k: 0, ix: 5 },
                                  o: { a: 0, k: 100, ix: 7 },
                                },
                              ],
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [51.942, 79.031], ix: 2 },
                              r: { a: 0, k: 114.949, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 37.356], t: 0 },
                              { s: [100, 37.356], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 180, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C2 Group",
                      ix: 2,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C2",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [70, 70], t: 0 },
                              { s: [70, 70], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9804, 0.3922, 0.3843],
                                t: 0,
                              },
                              { s: [0.9804, 0.3922, 0.3843], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [0, 0], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C3 Group",
                      ix: 3,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C3",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [1, 1], t: 0 },
                              { s: [1, 1], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0.949, 0.7098, 0.702], t: 0 },
                              { s: [0.949, 0.7098, 0.702], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, -65], t: 0 },
                              { s: [0, -65], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "st",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Stroke",
                      nm: "Stroke 1",
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 1, ix: 5 },
                      c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 3 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [533.876, 677.467], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [533.936, 677.95], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.077, 678.76], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.367, 679.85], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.761, 680.844], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.161, 681.577], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.529, 682.108], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.859, 682.508], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.157, 682.817], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.665, 683.261], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [537.076, 683.559], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [537.545, 683.847], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [538.079, 684.118], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [537.635, 683.896], t: 136 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [537.154, 683.611], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.866, 683.413], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.554, 683.172], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.225, 682.883], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.89, 682.542], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.561, 682.15], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.249, 681.713], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.965, 681.24], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.716, 680.746], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.508, 680.248], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.339, 679.762], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.206, 679.302], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.103, 678.879], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.026, 678.5], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [533.926, 677.881], t: 153 },
                          { s: [533.859, 677.292], t: 156 },
                        ],
                      },
                      r: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.65], t: 0 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.586], t: 1 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.353], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [27.858], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [26.934], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [25.312], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [22.962], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [20.599], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [18.678], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [17.154], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [15.917], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [14.891], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [14.024], t: 12 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [13.282], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [12.642], t: 14 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [12.085], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [11.601], t: 16 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [11.178], t: 17 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.81], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.489], t: 19 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.211], t: 20 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.971], t: 21 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.766], t: 22 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.594], t: 23 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.451], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.335], t: 25 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.244], t: 26 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.176], t: 27 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.129], t: 28 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.102], t: 29 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.093], t: 130 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.128], t: 131 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.239], t: 132 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.431], t: 133 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.714], t: 134 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.093], t: 135 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.574], t: 136 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [11.164], t: 137 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [11.867], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [12.686], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [13.621], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [14.665], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [15.808], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [17.03], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [18.3], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [19.583], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [20.843], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [22.044], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [23.16], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [24.169], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [25.064], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [25.842], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [26.508], t: 152 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [27.067], t: 153 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [27.527], t: 154 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [27.898], t: 155 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.186], t: 156 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.398], t: 157 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.542], t: 158 },
                          { s: [28.624], t: 159 },
                        ],
                      },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Lower Group",
                  ix: 2,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Rigged Lower",
                      ix: 1,
                      cix: 2,
                      np: 1,
                      it: [
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Elbow L Group",
                          ix: 1,
                          cix: 2,
                          np: 1,
                          it: [
                            {
                              ty: "gr",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Group",
                              nm: "Sholder L",
                              ix: 1,
                              cix: 2,
                              np: 2,
                              it: [
                                {
                                  ty: "sh",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Shape - Group",
                                  nm: "Path 1",
                                  ix: 1,
                                  d: 1,
                                  ks: {
                                    a: 0,
                                    k: {
                                      c: true,
                                      i: [
                                        [-4.12, -6.518],
                                        [-17.734, 2.507],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [10.524, -4.921],
                                      ],
                                      o: [
                                        [10.733, 16.978],
                                        [21.405, -3.025],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-26.633, 12.454],
                                      ],
                                      v: [
                                        [-81.516, 95.614],
                                        [-43.013, 114.623],
                                        [93.552, 29.986],
                                        [115.61, 15.419],
                                        [108.302, -7.087],
                                        [84.029, -0.13],
                                        [-15.839, 28.595],
                                        [-63.601, 48.331],
                                      ],
                                    },
                                    ix: 2,
                                  },
                                },
                                {
                                  ty: "fl",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Graphic - Fill",
                                  nm: "Fill 1",
                                  c: { a: 0, k: [0.949, 0.7098, 0.702], ix: 4 },
                                  r: 1,
                                  o: { a: 0, k: 100, ix: 5 },
                                },
                                {
                                  ty: "tr",
                                  a: { a: 0, k: [0, 0], ix: 1 },
                                  s: { a: 0, k: [100, 100], ix: 3 },
                                  sk: { a: 0, k: 0, ix: 4 },
                                  p: { a: 0, k: [0, 0], ix: 2 },
                                  r: { a: 0, k: 0, ix: 6 },
                                  sa: { a: 0, k: 0, ix: 5 },
                                  o: { a: 0, k: 100, ix: 7 },
                                },
                              ],
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [51.942, 79.031], ix: 2 },
                              r: { a: 0, k: 114.949, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 37.356], t: 0 },
                              { s: [100, 37.356], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 180, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C2 Group",
                      ix: 2,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C2",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [70, 70], t: 0 },
                              { s: [70, 70], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9804, 0.3922, 0.3843],
                                t: 0,
                              },
                              { s: [0.9804, 0.3922, 0.3843], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [0, 0], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C3 Group",
                      ix: 3,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C3",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [1, 1], t: 0 },
                              { s: [1, 1], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0.949, 0.7098, 0.702], t: 0 },
                              { s: [0.949, 0.7098, 0.702], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, -65], t: 0 },
                              { s: [0, -65], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [533.876, 677.467], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [533.936, 677.95], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.077, 678.76], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.367, 679.85], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.761, 680.844], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.161, 681.577], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.529, 682.108], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.859, 682.508], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.157, 682.817], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.665, 683.261], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [537.076, 683.559], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [537.545, 683.847], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [538.079, 684.118], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [537.635, 683.896], t: 136 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [537.154, 683.611], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.866, 683.413], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.554, 683.172], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [536.225, 682.883], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.89, 682.542], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.561, 682.15], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [535.249, 681.713], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.965, 681.24], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.716, 680.746], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.508, 680.248], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.339, 679.762], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.206, 679.302], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.103, 678.879], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [534.026, 678.5], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [533.926, 677.881], t: 153 },
                          { s: [533.859, 677.292], t: 156 },
                        ],
                      },
                      r: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.65], t: 0 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.586], t: 1 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.353], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [27.858], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [26.934], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [25.312], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [22.962], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [20.599], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [18.678], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [17.154], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [15.917], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [14.891], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [14.024], t: 12 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [13.282], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [12.642], t: 14 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [12.085], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [11.601], t: 16 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [11.178], t: 17 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.81], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.489], t: 19 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.211], t: 20 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.971], t: 21 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.766], t: 22 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.594], t: 23 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.451], t: 24 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.335], t: 25 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.244], t: 26 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.176], t: 27 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.129], t: 28 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.102], t: 29 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.093], t: 130 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.128], t: 131 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.239], t: 132 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.431], t: 133 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [9.714], t: 134 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.093], t: 135 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [10.574], t: 136 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [11.164], t: 137 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [11.867], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [12.686], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [13.621], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [14.665], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [15.808], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [17.03], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [18.3], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [19.583], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [20.843], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [22.044], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [23.16], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [24.169], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [25.064], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [25.842], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [26.508], t: 152 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [27.067], t: 153 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [27.527], t: 154 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [27.898], t: 155 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.186], t: 156 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.398], t: 157 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [28.542], t: 158 },
                          { s: [28.624], t: 159 },
                        ],
                      },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Upper Group",
                  ix: 3,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Rigged Upper",
                      ix: 1,
                      cix: 2,
                      np: 1,
                      it: [
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Sholder L Group",
                          ix: 1,
                          cix: 2,
                          np: 1,
                          it: [
                            {
                              ty: "gr",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Group",
                              nm: "Sholder L",
                              ix: 1,
                              cix: 2,
                              np: 2,
                              it: [
                                {
                                  ty: "sh",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Shape - Group",
                                  nm: "Path 1",
                                  ix: 1,
                                  d: 1,
                                  ks: {
                                    a: 0,
                                    k: {
                                      c: true,
                                      i: [
                                        [53.34, -8.529],
                                        [3.409, -9.425],
                                        [1.951, -20.553],
                                        [-10.732, -16.978],
                                        [-15.967, 3.963],
                                        [0.801, 12.758],
                                        [0, 0],
                                        [-0.975, 16.085],
                                      ],
                                      o: [
                                        [-12.194, 1.95],
                                        [-3.2, 8.845],
                                        [-1.952, 20.552],
                                        [10.733, 16.978],
                                        [15.967, -3.963],
                                        [-1.537, -24.472],
                                        [0, 0],
                                        [0.976, -16.085],
                                      ],
                                      v: [
                                        [-66.574, -117.061],
                                        [-93.893, -92.04],
                                        [-97.795, -44.679],
                                        [-81.516, 95.614],
                                        [-40.701, 114.373],
                                        [-14.285, 80.527],
                                        [-15.839, 28.595],
                                        [-13.888, -59.871],
                                      ],
                                    },
                                    ix: 2,
                                  },
                                },
                                {
                                  ty: "fl",
                                  bm: 0,
                                  hd: false,
                                  mn: "ADBE Vector Graphic - Fill",
                                  nm: "Fill 1",
                                  c: { a: 0, k: [0.9804, 0.3922, 0.3843], ix: 4 },
                                  r: 1,
                                  o: { a: 0, k: 100, ix: 5 },
                                },
                                {
                                  ty: "tr",
                                  a: { a: 0, k: [0, 0], ix: 1 },
                                  s: { a: 0, k: [100, 100], ix: 3 },
                                  sk: { a: 0, k: 0, ix: 4 },
                                  p: { a: 0, k: [0, 0], ix: 2 },
                                  r: { a: 0, k: 0, ix: 6 },
                                  sa: { a: 0, k: 0, ix: 5 },
                                  o: { a: 0, k: 100, ix: 7 },
                                },
                              ],
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [52.634, 79.428], ix: 2 },
                              r: { a: 0, k: 2.081, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 109.494], t: 0 },
                              { s: [100, 109.494], t: 180 },
                            ],
                          },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, 0], t: 0 },
                              { s: [0, 0], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 180, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C1 Group",
                      ix: 2,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C1",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [81, 81], t: 0 },
                              { s: [81, 81], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9804, 0.3922, 0.3843],
                                t: 0,
                              },
                              { s: [0.9804, 0.3922, 0.3843], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [0, 0], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "C2 Group",
                      ix: 3,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "el",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Ellipse",
                          nm: "C2",
                          d: 1,
                          p: { a: 0, k: [0, 0], ix: 3 },
                          s: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [70, 70], t: 0 },
                              { s: [70, 70], t: 180 },
                            ],
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill",
                          c: {
                            a: 1,
                            k: [
                              {
                                o: { x: 0.167, y: 0.167 },
                                i: { x: 0.833, y: 0.833 },
                                s: [0.9804, 0.3922, 0.3843],
                                t: 0,
                              },
                              { s: [0.9804, 0.3922, 0.3843], t: 180 },
                            ],
                          },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: {
                            a: 1,
                            k: [
                              { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [0, -173], t: 0 },
                              { s: [0, -173], t: 180 },
                            ],
                          },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [443.701, 529.547], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [444.032, 529.626], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [444.647, 529.772], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [445.714, 530.029], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [447.226, 530.399], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [448.703, 530.767], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [449.869, 531.063], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [450.769, 531.294], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [451.483, 531.479], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [452.063, 531.631], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [452.544, 531.758], t: 12 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [452.95, 531.865], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [453.295, 531.957], t: 14 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [453.591, 532.036], t: 15 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [453.846, 532.105], t: 16 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [454.257, 532.215], t: 18 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [454.684, 532.331], t: 21 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [454.378, 532.248], t: 136 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [454.074, 532.166], t: 137 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [453.706, 532.067], t: 138 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [453.271, 531.951], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [452.765, 531.817], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [452.189, 531.664], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [451.545, 531.496], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [450.842, 531.313], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [450.096, 531.121], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [449.325, 530.924], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [448.554, 530.73], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [447.806, 530.543], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [447.101, 530.368], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [446.455, 530.209], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [445.876, 530.068], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [445.367, 529.945], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [444.929, 529.839], t: 152 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [444.559, 529.751], t: 153 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [444.253, 529.678], t: 154 },
                          { s: [443.813, 529.574], t: 156 },
                        ],
                      },
                      r: {
                        a: 1,
                        k: [
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [148.594], t: 2 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [148.713], t: 3 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [148.928], t: 4 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [149.285], t: 5 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [149.755], t: 6 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.169], t: 7 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.461], t: 8 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.664], t: 9 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.809], t: 10 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.916], t: 11 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [151.059], t: 13 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [151.201], t: 17 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [151.105], t: 139 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [151.032], t: 140 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.938], t: 141 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.821], t: 142 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.679], t: 143 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.514], t: 144 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.328], t: 145 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [150.129], t: 146 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [149.923], t: 147 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [149.717], t: 148 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [149.52], t: 149 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [149.337], t: 150 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [149.171], t: 151 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [149.024], t: 152 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [148.897], t: 153 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [148.791], t: 154 },
                          { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [148.703], t: 155 },
                          { s: [148.583], t: 157 },
                        ],
                      },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Admin",
              ix: 2,
              cix: 2,
              it: [
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      { o: { x: 0.167, y: 0.167 }, i: { x: 0.833, y: 0.833 }, s: [100, 100], t: 0 },
                      { s: [100, 100], t: 180 },
                    ],
                  },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
          ],
          ind: 26,
        },
      ],
    },
  ],
};
