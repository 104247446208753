import React, { useState } from "react";
import RefferalCard from "./RefferalCard";
import { InviteSection } from "./style";
import MobileModal from "../../common/Components/MobileModal";
import Popout from "./Popout";
const users = [
  {
    key: 1,
    name: "Antonio Maurya",
    refferalStatus: true,
    isSignedUp: true,
    isProfileComplete: true,
    isInvested: true,
  },
  {
    key: 2,
    name: "Elon Chaurasiya",
    refferalStatus: false,
    isSignedUp: true,
    isProfileComplete: false,
    isInvested: false,
  },
  {
    key: 3,
    name: "Nishant Maurya",
    refferalStatus: false,
    isSignedUp: true,
    isProfileComplete: true,
    isInvested: false,
  },
  {
    key: 4,
    name: " Maurya",
    refferalStatus: false,
    isSignedUp: true,
    isProfileComplete: true,
    isInvested: false,
  },
];

const Component1 = () => {
  const [isReferralModalVisible, setIsRefferalModalVisible] = useState(false);
  return (
    <>
      <InviteSection
        onClick={() => {
          setIsRefferalModalVisible(true);
        }}
      >
        {/* <img src="/referral/refer2.png" alt="" /> */}
        <img src="/referral/gift.png" alt="" />
        <h1>Refer and Earn(Invite) {"→"} </h1>
      </InviteSection>
      {users.map((user) => (
        <RefferalCard
          key={user.key}
          name={user.name}
          refferalStatus={user.refferalStatus}
          isSignedUp={user.isSignedUp}
          isProfileComplete={user.isProfileComplete}
          isInvested={user.isInvested}
        />
      ))}

      <MobileModal
        isOpen={isReferralModalVisible}
        onClose={() => {
          setIsRefferalModalVisible(false);
        }}
      >
        <Popout />
      </MobileModal>
    </>
  );
};
export default Component1;
