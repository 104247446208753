import styled from "styled-components";
import { Button, Select } from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";

export const SelectDeviceWrapper = styled.div`
  padding-bottom: 30px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ebebeb;
`;

export const SelectDeviceHeading = styled.div`
  text-align: center;
  font-size: 28px;
  color: #000;
  margin-bottom: 40px;
`;

export const SelectService = styled(Select)`
  width: 100% !important;
  height: 50px;
  /* background: #ebebeb; */
  border-radius: 10px;
  /* box-sizing: border-box; */
`;

export const SelectDevice = styled(Select)`
  width: 100% !important;
  height: 50px;
  /* background: #ebebeb; */
  border-radius: 10px;
  margin-top: 15px;
`;

export const SelectPincode = styled(Select)`
  width: 100% !important;
  min-height: 50px;
  /* background: #ebebeb; */
  border-radius: 10px;
  margin-top: 15px;

  .ant-select-selector {
    min-height: 50px;
  }
`;

export const SelectDurationText = styled.div`
  font-size: 20px;
  color: #000;
`;

export const DurationSelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 70px;
`;

export const AddDeviceButtonWrapper = styled(Button)`
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: rgb(114, 46, 209);
  bottom: 10px;
  left: 10px;
  right: 10px;
  font-size: 22px;
  color: #ffffff;
  border-radius: 15px;
  &:hover {
    background: rgb(114, 46, 209) !important;
  }
  &:active {
    background: rgb(114, 46, 209) !important;
  }
  &:disabled {
    background: rgba(0, 0, 0, 0.04) !important;
  }
`;

export const ToolTipText = styled.span`
  font-size: 14px;
  color: #000;
  margin-left: 10px;
`;

export const StyledQuestionCircleTwoTone = styled(QuestionCircleTwoTone)`
  margin-left: 10px;
  font-size: 16px;
`;
