import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { Wrapper, InnerWrapper, CloseBtnWrapper, CloseBtn } from "./Style";
import { Button, message } from "antd";
import { IProps } from "./interfaces";

function MobileModal(props: IProps) {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  function onClose() {
    props.onClose();
  }

  function onWrapperClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const target = e.target as HTMLDivElement;
    const isInnerWrapper = target.closest(".innerWrapper");

    // if (!isInnerWrapper) {
    //   onClose();
    // }
  }

  return createPortal(
    <Wrapper isOpen={isOpen} onClick={onWrapperClick}>
      <InnerWrapper className="innerWrapper" style={props.style}>
        {props.children}
        <CloseBtnWrapper onClick={onClose} style={{ color: props.crossColor }}>
          <CloseBtn />
        </CloseBtnWrapper>
      </InnerWrapper>
    </Wrapper>,
    document.querySelector("#modals")
  );
}

export default MobileModal;
