import { useState } from "react";
import { questionsSet } from "../constants";

const FAQ = () => {
  const [openedIds, setOpenedIds] = useState<number[]>([]);

  const onClickHandle = (id: number) => {
    if (openedIds.includes(id)) {
      setOpenedIds(openedIds.filter((item) => item !== id));
    } else {
      setOpenedIds([...openedIds, id]);
    }
  };

  return (
    <>
      <section className="py-10 sm:py-16 lg:py-24 bg-gray-50">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="font-bold leading-tight text-black  text-3xl sm:text-4xl md:text-5xl">
              Frequently Asked Questions
            </h2>
            <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
              Got more questions? We've got you covered. Check out our comprehensive FAQs to find quick answers.
            </p>
          </div>

          <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
            {questionsSet.map((ques) => (
              <div
                key={ques.key}
                className="transition-all duration-700 bg-white border border-gray-200 shadow-sm cursor-pointer hover:bg-gray-50 rounded-xl"
              >
                <button
                  onClick={() => onClickHandle(ques.key)}
                  type="button"
                  className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                >
                  <span className="flex text-lg font-semibold text-black"> {ques.question} </span>

                  {openedIds.includes(ques.key) ? (
                    <svg
                      className="w-6 h-6 text-gray-400 rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  )}
                </button>

                {openedIds.includes(ques.key) && (
                  <div className={"px-4 pb-5 sm:px-6 sm:pb-6"}>
                    <p>{ques.answer} </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
