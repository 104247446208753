import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import appRoutes from "./constants/appRoutes";

import Home from "./Components/Landing/Home";
import Downloads from "./Components/Landing/Downloads";
import HowItWorks from "./Components/Landing/HowItWorks";

import Services from "./Components/Services";
import Refferal from "./Components/Refferal";
import Login from "./Components/Login";
import OTP from "./Components/Login/OTP";
import Page404 from "./Components/Page404";
import Dashboard from "./Components/InnerDashboard";
import Profile from "./Components/Profile";
import PaymentDone from "./Components/PaymentDone";
import useAuth from "./hooks/useAuth";
import ProtectedRouter from "./ProtectedRouter";

const routes = [
  {
    path: appRoutes.HOME,
    isProtected: false,
    element: <Home />,
    redirectToDashboardWhenLoggedIn: false,
  },
  {
    path: appRoutes.DOWNLOADS,
    isProtected: false,
    element: <Downloads />,
    redirectToDashboardWhenLoggedIn: false,
  },
  {
    path: appRoutes.HOW_IT_WORKS,
    isProtected: false,
    element: <HowItWorks />,
    redirectToDashboardWhenLoggedIn: false,
  },
  {
    path: appRoutes.LOGIN,
    isProtected: false,
    element: <Login />,
    redirectToDashboardWhenLoggedIn: true,
  },
  {
    path: appRoutes.SUBSCRIPTION,
    isProtected: true,
    element: <Services />,
    redirectToDashboardWhenLoggedIn: false,
  },
  {
    path: appRoutes.REFERRAL,
    isProtected: true,
    element: <Refferal />,
    redirectToDashboardWhenLoggedIn: false,
  },
  {
    path: appRoutes.OTP,
    isProtected: false,
    element: <OTP />,
    redirectToDashboardWhenLoggedIn: true,
  },
  {
    path: appRoutes.DASHBOARD,
    isProtected: true,
    element: <Dashboard />,
    redirectToDashboardWhenLoggedIn: false,
  },
  {
    path: appRoutes.PROFILE,
    isProtected: true,
    element: <Profile />,
    redirectToDashboardWhenLoggedIn: false,
  },
  {
    path: appRoutes.PAYMENTDONE,
    isProtected: false,
    element: <PaymentDone />,
    redirectToDashboardWhenLoggedIn: false,
  },
];

function Router() {
  const authData = useAuth();

  return authData.isLoading ? (
    <p>Loading...</p>
  ) : (
    <BrowserRouter>
      <Routes>
        <>
          {routes.map((route) => {
            // If route is protected and user is not logged in, redirect to login page
            if (route.isProtected && !authData.user) {
              return <Route key={route.path} path={route.path} element={<Navigate to="/login" />} />;
            }

            // // If route is protected and user is logged in, render the protected route
            if (route.isProtected && authData.user) {
              return (
                <Route key={route.path} element={<ProtectedRouter />}>
                  <Route path={route.path} element={route.element} />
                </Route>
              );
            }

            // If route is not protected and user is logged in, redirect to dashboard
            if (route.redirectToDashboardWhenLoggedIn && authData.user) {
              return <Route key={route.path} path={route.path} element={<Navigate to="/dashboard" />} />;
            }

            // If route is not protected and user is not logged in, render the route
            return <Route key={route.path} path={route.path} element={route.element} />;
          })}

          <Route path="*" element={<Page404 />} />
        </>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
