import React, { useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import {
  Container,
  FAQSection,
  ProfileDetails,
  ProfileSection,
  ContactUsSection,
  ReferralSection,
  Illustration,
  CopySection,
  ShareSection,
  Share,
  StyledButton,
} from "./style";
import { CopyButton, CopyReferralCode, InviteSection } from "../Refferal/style";

import Collapse from "./Collapse";
import copyText from "../../common/utils/copyText";
import TextArea from "antd/es/input/TextArea";
import useAuth from "../../hooks/useAuth";

const Profile = () => {
  const authData = useAuth();
  const [msg, setMsg] = useState("");

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMsg(e.target.value);
  };

  return (
    <Container>
      <ProfileSection>
        <ProfileDetails>
          <div>
            {(authData.user.name || "").split(" ")[0] || "User"}
            <span>{(authData.user.name || "").split(" ")[1] || "Unknown"}</span>
          </div>
          <img src={"https://picsum.photos/200"} alt="user image" />
        </ProfileDetails>
      </ProfileSection>

      <FAQSection>
        <h1>Frequently Asked Questions</h1>
        <Collapse />
      </FAQSection>

      <ContactUsSection>
        <h1>Contact Us</h1>
        <TextArea
          showCount
          maxLength={100}
          onChange={onChange}
          placeholder="state your problem"
          style={{ height: 120, resize: "none" }}
        />
        <StyledButton
          type="primary"
          block
          onClick={() => {
            window.location.href = `https://api.whatsapp.com/send?phone=919711464750&text=${encodeURIComponent(msg)}`;
          }}
        >
          <span>
            <IoLogoWhatsapp />
          </span>
          <span>Whatsapp</span>
        </StyledButton>
      </ContactUsSection>

      {false && (
        <ReferralSection>
          <Illustration onClick={() => {}}>
            <img
              src="https://img.freepik.com/free-vector/refer-friend-concept-illustration_114360-6959.jpg?w=1380&t=st=1702666787~exp=1702667387~hmac=a3d3f43cba4d623c39e1f03d74e64231862874e13e6f0f6e3649beeed89709ab"
              alt=""
            />
            <h1>
              Earn upto ${1000} Every
              <br />
              time you refer!
              <br />
            </h1>
            {/* <span>Introduce your friends to StockDetector pro and get a flat referral cash reward, and many other prices.</span> */}
          </Illustration>
          <CopySection>
            <CopyReferralCode>
              {"U38JJ"}
              <CopyButton onClick={() => copyText("U38JJ")}>copy</CopyButton>
            </CopyReferralCode>
          </CopySection>
          <ShareSection>
            <Share>
              <img src="/whatsapp.png" alt="" />
            </Share>
            <Share>
              <img src="/whatsappBusiness.jpg" alt="" />
            </Share>
            <Share>
              <img src="/telegram.png" alt="" />
            </Share>
            <Share>
              <img src="/sms2.png" alt="" />
            </Share>
          </ShareSection>
        </ReferralSection>
      )}
    </Container>
  );
};

export default Profile;
