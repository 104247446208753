import { createContext, useEffect, useState } from "react";
import apiEndPoints from "../constants/apiEndpoints";
import { useNavigate } from "react-router-dom";
import apiCaller from "../common/utils/apiCaller";

interface IAuthContext {
  user: any;
  isLoggedIn: () => void;
  isLoading: boolean;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  isLoggedIn: () => {},
  isLoading: true,
});

interface IAuthProviderProps {
  children: React.ReactNode;
}
function AuthProvider({ children }: IAuthProviderProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const isLoggedIn = async () => {
    const API = apiEndPoints.GET_USER_INFO;
    try {
      const { data: user } = await apiCaller(API.URL, API.METHOD);
      setUser(user);
    } catch (err) {
      console.log(err);
      setUser(null);
    } finally {
      setIsLoading(false);
      console.log(user);
      if (user) window.location.href = "/dashboard";
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return <AuthContext.Provider value={{ user, isLoggedIn, isLoading }}>{children}</AuthContext.Provider>;
}

export { AuthContext };
export default AuthProvider;
