import styled, { createGlobalStyle } from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: 30px;
  position: relative;
  /* max-width: 500px; */
  margin: auto auto;
`;
export const Illustration = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
  img {
    width: 270px;
  }
`;
export const Label = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 29px;
  p {
    margin-top: 25px;
    font-weight: 400;
    font-size: 19px;
  }
  margin-bottom: 50px;
`;

export const VerifyButton = styled(Button)`
  height: 50px;
  background: linear-gradient(to right, #9064ec, #a681f2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  font-size: 18px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
`;
export const CountryCode = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: -1px;
`;

export const Terms = styled.div`
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  span {
    cursor: pointer;
    font-weight: 700;
    display: block;
    margin: auto auto;
    width: fit-content;
    border-bottom: solid #e4e4e4 1px;
  }
`;
export const Countrycode = styled.div`
  font-weight: 400;
`;

export const Country = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
`;

export const CountryImage = styled.div`
  width: 35px;
  height: 35px;
  background-image: url("https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/2560px-Flag_of_India.svg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
`;
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: solid gray 2px;
  margin-bottom: 50px;
  border-color: #e4e4e4;
`;
export const PhoneNumberInput = styled.input`
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
  &:focus {
    outline: none;
    border: none;
  }
`;

export const GlobalLoginStyle = createGlobalStyle`
  :where(.css-dev-only-do-not-override-6j9yrn).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: white;
  }

  :where(.css-dev-only-do-not-override-6j9yrn).ant-btn-default {
    border: none;
  }
`;

// OTP

export const OTPContainer = styled.div`
  /* max-width: 500px; */
  margin: auto auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
`;

export const OTPIllustration = styled.div`
  margin-bottom: 20px;
  img {
    width: 270px;
  }
`;

export const OTPLabel = styled.div`
  font-weight: 700;
  font-size: 30px;

  div {
    margin-bottom: 25px;
  }

  p {
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 17px;
    margin: 3px 0px;
  }
  margin-bottom: 50px;
`;

interface DigitProps {
  hasDigit?: boolean;
}

export const Digit = styled.input<DigitProps>`
  width: 50px;
  height: 50px;
  border: none;
  outline-color: rgb(205 180 247);
  font-weight: 900;
  color: white;
  background-color: ${(props) => (props.hasDigit ? "#9064ec" : "#e8dcfc")};
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
`;

export const DigitWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const ResendButton = styled(Button)`
  font-weight: bold;
  color: #9064ec !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: #d9d9d9 !important;
  }
`;

export const ResendOTPWrapper = styled.div`
  position: absolute;
  bottom: -70px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  span {
    font-weight: 700;
    display: block;
  }
`;
