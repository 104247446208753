import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Wrapper, Illustration, Info, PaymentAmount, PaymentStatus, TransactionNumber, RedirectingText } from "./style";
import paymentSuccess from "./payment_success.json";
import paymentFailed from "./payment_failed.json";

const PaymentDone = () => {
  const [searchParams] = useSearchParams();
  const [timeLeft, setTimeLeft] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeLeft === 0) return navigate("/dashboard");
    setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
  }, [timeLeft]);

  return (
    <Wrapper>
      <Illustration>
        <Lottie animationData={searchParams.get("status") === "success" ? paymentSuccess : paymentFailed} />
      </Illustration>
      <PaymentStatus>
        {searchParams.get("status") === "success" ? `Payment Successful` : `Payment Failed`}
      </PaymentStatus>

      {searchParams.get("status") === "success" ? (
        <Info>
          <TransactionNumber>Order ID : {searchParams.get("orderId")}</TransactionNumber>
          <PaymentAmount>
            Amount paid{" "}
            <span>Rs. {new Intl.NumberFormat("en-IN").format(Number(searchParams.get("amount") || 0))}</span>
          </PaymentAmount>
          <RedirectingText>Redirecting to dashboard in {timeLeft}</RedirectingText>
        </Info>
      ) : (
        <RedirectingText>Redirecting to dashboard in {timeLeft}</RedirectingText>
      )}
    </Wrapper>
  );
};

export default PaymentDone;
