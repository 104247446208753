import React from "react";
import { Card, Name, Result, Requirements, Item, StatusButtonWithoutImage, StatusButtonWithImage } from "./style";
import BlueCheck from "./BlueCheck";
import GreenRadio from "./GreenRadio";
interface UserProps {
  name: string;
  refferalStatus: boolean;
  isSignedUp: boolean;
  isProfileComplete: boolean;
  isInvested: boolean;
}
const getReminderText = (user: UserProps) => {
  if (!user.isSignedUp) {
    return "Sign Up";
  } else if (!user.isProfileComplete) {
    return "Complete Profile";
  } else if (!user.isInvested) {
    return "Invest";
  } else {
    return "";
  }
};

const RefferalCard: React.FC<UserProps> = (user) => {
  return (
    <Card>
      <Name>{user.name}</Name>
      <Result>
        Refferal Status : <span>{user.refferalStatus ? "Successful" : "Pending"}</span>
      </Result>
      <Requirements>
        <Item>
          {user.isSignedUp ? <BlueCheck /> : <GreenRadio />}
          <p>{"Signed Up"}</p>
        </Item>
        <Item>
          {user.isProfileComplete ? <BlueCheck /> : <GreenRadio />}
          <p>{"Profile Complete"}</p>
        </Item>
        <Item>
          {user.isInvested ? <BlueCheck /> : <GreenRadio />}
          <p>{"Invested"}</p>
        </Item>
      </Requirements>

      {user.refferalStatus ? (
        // <StatusButtonWithImage>
        //   You've got a ₹100 !
        // </StatusButtonWithImage>
        <StatusButtonWithImage>
          <div> <img src="/referral/trophy.png" alt="" />You've got a ₹100 !</div>
        </StatusButtonWithImage>
      ) : (
        <StatusButtonWithoutImage>
          Remind {user.name.split(" ")[0]} to {getReminderText(user)}
        </StatusButtonWithoutImage>
      )}
    </Card>
  );
};

export default RefferalCard;
