import styled from "styled-components";

export const ReferralWrapper = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  font-family: sans-serif;
`;

export const Header = styled.div`
  text-align: center;
  height: 50px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 0px;
  margin: 0px 10px;
  height: calc(100% - 50px - 50px);
  box-sizing: border-box;
  overflow: scroll;
`;

export const Footer = styled.div`
  height: 50px;
  padding: 0px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackBtnWrapper = styled.div`
  font-size: 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeadingText = styled.div`
  font-size: 16px;
  flex-grow: 1;
  color: #656363;
  font-weight: 600;
  margin-left: 15px;
`;

export const PriceText = styled.div`
  font-size: 16px;
`;
