import { message } from "antd";

const copyText = (text: string) => {
  try {
    if (window && window.navigator && window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text);
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.setAttribute("readonly", "");
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, textarea.value.length);
      document.execCommand("copy");
      document.body.removeChild(textarea);
      console.log("Text copied to clipboard");
    }
    message.success("Copied to clipboard");
  } catch (error) {
    message.error("Error copying");
  }
};
export default copyText;
