import styled from "styled-components";
import { IoClose } from "react-icons/io5";

export const Wrapper = styled.div<{ isOpen: boolean }>`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 99;
  ${(props) => (props.isOpen ? `bottom: 0px;` : `bottom: -100vh;`)}
  transition: bottom 0.5s ease-in-out;
`;

export const InnerWrapper = styled.div`
  position: absolute;
  height: max-content;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  bottom: 0px;
`;

export const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #000;
`;

export const CloseBtn = styled(IoClose)``;
