import { CheckCircleFilled } from "@ant-design/icons";
import Card from "./Card";
import { Wrapper, Body, Footer, PriceText, StyledHeading, StyledButton } from "./style";
import { message } from "antd";
import { useEffect, useState } from "react";
import apiEndPoints from "../../constants/apiEndpoints";
import apiCaller from "../../common/utils/apiCaller";
import { ICart, IGET_SERVICES_PLANS } from "./interfaces";
import _get from "lodash.get";
import { NoDataWrapper } from "../InnerDashboard/style";

function Features() {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState(null);
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);
  const [cart, setCart] = useState<number[]>([]);
  const [totalCartValue, setTotalCartValue] = useState<number>(0);

  console.log({ cart });

  async function getServicesPlans() {
    const API = apiEndPoints["GET_SERVICES_PLANS"];
    const res = await apiCaller<IGET_SERVICES_PLANS>(API.URL, API.METHOD);
    setData(res.data);
    setOpenIndexes(res.data.structeredData.map((service) => service.id));
    setLoading(false);
  }

  const createOrder = async (data) => {
    try {
      const API = apiEndPoints.PAY;
      const res = (await apiCaller(API.URL, API.METHOD, data)) as any;
      const razor = new window.Razorpay(res);
      razor.open();
    } catch (err) {
      if (err instanceof Error) message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!window) return;

    // Inject razorpay script in DOM
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    window.document.body.appendChild(script);

    // Get all services details
    getServicesPlans();
  }, []);

  useEffect(() => {
    let total = cart.reduce((acc, id) => {
      return acc + data.servicePlanIdPriceMap[id];
    }, 0);

    setTotalCartValue(total);
  }, [cart]);

  if (!data) return <div>Loading...</div>;
  return (
    <Wrapper>
      <StyledHeading>Services</StyledHeading>

      <Body isFooterVisible={totalCartValue !== 0}>
        {_get(data, "structeredData", []).map((obj: any) => (
          <Card
            visible={!!!data.userActiveServicesIds.includes(obj.id)}
            key={obj.id}
            name={obj.name}
            color={obj.color}
            tenureServicePlansMap={obj.tenureServicePlansMap}
            isOpen={openIndexes.includes(obj.id)}
            isInCart={
              cart.find((cartItemId) => data.serviceServicePlanIdsMap[obj.id].includes(cartItemId)) ? true : false
            }
            addToCart={(itemId) => {
              const filteredCart = cart.filter((cartItemId) => {
                return !data.serviceServicePlanIdsMap[obj.id].includes(cartItemId);
              });
              setCart([...filteredCart, itemId]);
            }}
            removeFromCart={() => {
              const filteredCart = cart.filter((cartItemId) => {
                return !data.serviceServicePlanIdsMap[obj.id].includes(cartItemId);
              });
              setCart(filteredCart);
            }}
            onToggle={() => {
              const isAlreadyOpened = openIndexes.includes(obj.id);
              if (isAlreadyOpened) {
                setOpenIndexes(openIndexes.filter((id) => id !== obj.id));
              } else {
                setOpenIndexes([...openIndexes, obj.id]);
              }
            }}
          />
        ))}
        {_get(data, "structeredData", []).find((obj: any) => !!!data.userActiveServicesIds.includes(obj.id)) ? null : (
          <NoDataWrapper>
            <span>
              <CheckCircleFilled />
              Wow! You have all the services
            </span>
            <h1>More services comming soon !!!</h1>
          </NoDataWrapper>
        )}
      </Body>
      {totalCartValue !== 0 && (
        <Footer>
          <PriceText>Rs. {new Intl.NumberFormat("en-IN").format(totalCartValue)}</PriceText>
          <StyledButton
            type="primary"
            loading={loading}
            onClick={() => {
              createOrder({ cart });
              setLoading(true);
            }}
          >
            Proceed
          </StyledButton>
        </Footer>
      )}
    </Wrapper>
  );
}

export default Features;
