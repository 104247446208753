import styled from "styled-components";

export const CardWrapper = styled.div``;

export const CardHeading = styled.div<{ color: String }>`
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  ${(props) => (props.color ? ` background: ${props.color};` : "")}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;

  .left {
    display: flex;
    gap: 10px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;

    .anticon-check-circle {
      font-size: 25px;
    }
  }

  .right {
    color: #fff;
    display: flex;
    gap: 5px;
    font-size: 20px;
  }
`;

export const CardBody = styled.div<{ isOpen: boolean }>`
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0px;
  overflow: hidden;
  /* height: 250px; */
  height: fit-content;
  ${(props) => (props.isOpen ? `padding: 15px;` : `height: 0px;`)}
  transition: height 0.2s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Dropdown = styled.div`
  border: 1px solid rgb(120 120 120 / 10%);
  border-radius: 10px;
  padding: 0px 15px;
  margin-bottom: 15px;

  .heading {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    border-bottom: 1px solid rgb(120 120 120 / 10%);
    font-weight: 600;
  }

  .body {
    display: flex;
    flex-direction: column;

    .row {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #717585;
      font-weight: 600;
    }
  }
`;

export const ServerBtn = styled.div<{ selected?: boolean }>`
  ${(props) => (props.selected ? `background: rgba(0, 0, 0, 0.3);` : ``)}
  border: 1px solid rgba(256, 256, 256, 0.4);
  border-radius: 15px;
  padding: 5px 15px;
  color: #fefefe;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;

  img {
    height: 25px;
    width: 20px;
  }
`;

export const CartText = styled.span`
  margin-left: 10px;
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .subheading {
    text-align: start;
    font-size: 12px;
    font-weight: 400;
    color: #eeeeee;
  }
`;
