import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
  overflow: scroll;
  height: 100%;
`;
export const ProfileDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  div {
    margin-left: 10px;
    width: 100%;
    font-size: 40px;
    span {
      font-size: 20px;
      display: block;
    }
  }
  img {
    background-color: #ebebeb;
    margin: 10px;
    width: 100px;
    height: 100px;
    border-radius: 25px;
    padding: 10px;
  }
`;
export const ProfileSection = styled.div`
  padding: 10px;
  background-color: #5b30ad;
`;

export const StyledButton = styled(Button)`
  && {
    background-color: #5b30ad !important;
    border-color: #5b30ad !important;
    &:hover,
    &:focus {
      background-color: #8a63d4 !important;
      border-color: #8a63d4 !important;
    }
  }
`;

export const FAQSection = styled.div`
  margin-top: 0px;
  background: #fafafa;
  padding: 20px;
  color: #5b30ad;
  h1 {
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
  }
  .ant-collapse-header-text {
    text-align: left;
    font-weight: 600;
  }
  .ant-collapse-header {
  }
  .ant-collapse-content-box {
    text-align: left;

    margin-left: 20px;
  }
  .ant-collapse-expand-icon {
    position: absolute;
    right: 0px;
  }
`;

export const ContactUsSection = styled.div`
  padding: 10px 20px;
  margin-bottom: 10px;
  color: #5b30ad;
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 22px;
  }
  input {
  }
  .ant-input {
    background-color: #efebf8;
  }
  .ant-btn {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-top: 30px;
    background-color: #5b30ad;

    svg {
      font-size: 20px;
      position: relative;
      top: 3px;
    }
  }
`;

// Referral Section

export const ReferralSection = styled.div`
  padding: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
export const Illustration = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  img {
    width: 140px;
    margin-right: 10px;
  }
  h1 {
    align-items: center;
    text-align: left;
    color: #5b30ad;
    font-size: 22px;
  }
  span {
  }
`;

export const CopySection = styled.div`
  width: 80%;
`;
export const ShareSection = styled.div`
  width: 75%;
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const Share = styled.div`
  background-color: #5b30ad;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  padding: 10px;
  img {
    width: 25px;
    filter: invert();
  }
`;
