import React, { useState, ChangeEvent, useEffect } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import _get from "lodash.get";

import apiEndPoints from "../../constants/apiEndpoints";
import apiCaller from "../../common/utils/apiCaller";

import {
  Container,
  Illustration,
  Label,
  InputWrapper,
  PhoneNumberInput,
  VerifyButton,
  Terms,
  Country,
  Countrycode,
  CountryImage,
  CountryCode,
  GlobalLoginStyle,
} from "./style";

const Index: React.FC = (props) => {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const navigate = useNavigate();

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const sanitizedInput = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhoneNumber(sanitizedInput);
  };

  const handleVerifyButtonClick = async () => {
    if (phoneNumber.length !== 10) return message.error("Please enter a valid phone number");

    try {
      setLoading(true);
      await apiCaller(apiEndPoints.LOGIN.URL, apiEndPoints.LOGIN.METHOD, { phone: phoneNumber });
      message.success("OTP sent successfully");
      navigate("/otp", { state: { phone: phoneNumber }, replace: false });
    } catch (err) {
      if (err instanceof Error) message.error(_get(err, "response.data.message", err.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <GlobalLoginStyle />
        <Illustration>
          <img src="/hand_holding_smartphone_login.avif" alt="Login banner" />
        </Illustration>
        <Label>
          Enter Your mobile Number
          <p>We will send you a Confirmation Code</p>
        </Label>
        <InputWrapper>
          <Country>
            <CountryImage />
          </Country>
          <CountryCode>+91 </CountryCode>
          <PhoneNumberInput type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} maxLength={13} />
        </InputWrapper>
        <VerifyButton loading={loading} onClick={handleVerifyButtonClick}>
          VERIFY
        </VerifyButton>

        <Terms>
          By continuing, you agree to our T&C.
          <span>Terms & Privacy Policy</span>
        </Terms>
      </Container>
    </>
  );
};

export default Index;
