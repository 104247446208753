import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;

export const Illustration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
`;

export const PaymentStatus = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
`;
export const TransactionNumber = styled.div`
  text-align: center;
  margin-top: 13px;
  font-size: 15px;
  font-weight: 500;
`;
export const Info = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const PaymentAmount = styled.div`
  border-top: solid #ececec 2px;
  width: calc(90%);
  font-weight: 600;
  margin-top: 10px;
  padding-top: 25px;
  span {
    color: #43c4d3;
  }
`;

export const RedirectingText = styled.div`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
`;
