import React, { useEffect, useRef, useState } from "react";
import {
  DeviceCard,
  DetailsContainer,
  LogoContainer,
  NameContainer,
  Name,
  Model,
  OtherDetails,
  Price,
  DaysLeft,
  RemoveButton,
  Strip,
  ProgressBar,
  ProgressStrip,
} from "./style";

import { CloseOutlined } from "@ant-design/icons";

const device = {
  id: 1,
  brandName: "Croma",
  brandLogoURL: "/favicon.ico",
  deviceName: "Vivo T2x 5G (4GB + 128GB)",
  price: 11999,
  timeLeft: 24,
  inStock: false,
};

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface IProps {
  onStockStatusChange: (status: boolean) => void;
}

function AnimatedStockAlert(props: IProps) {
  let stockCheckingCount = useRef(0);
  const timer1 = useRef<NodeJS.Timeout | null>(null);
  const timer2 = useRef<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(false);
  const [isInStock, setIsInStock] = useState(false);
  const [progressStatus, setProgressStatus] = useState(100);

  async function getStockInfo() {
    setLoading(true);

    const fakeAPIResponse = await wait(0);

    // Setting stock status to true for even number of stock checks
    if (stockCheckingCount.current % 2 === 0) {
      timer1.current = setTimeout(() => {
        setIsInStock(false);
        setLoading(false);
        setProgressStatus(100);
      }, 3000);
      setIsInStock(true);
      stockCheckingCount.current++;
      return;
    }

    await wait(200); // For better animations in case of stock not available
    setIsInStock(false);
    setLoading(false);
    setProgressStatus(100);
    stockCheckingCount.current++;
  }

  useEffect(() => {
    if (progressStatus === 0) timer2.current = setTimeout(getStockInfo, 3000);
    if (progressStatus === 100) timer2.current = setTimeout(() => setProgressStatus(0), 500);
  }, [progressStatus]);

  useEffect(() => {
    props.onStockStatusChange(isInStock);
  }, [isInStock]);

  useEffect(() => {
    return () => {
      if (timer1.current) clearTimeout(timer1.current);
      if (timer2.current) clearTimeout(timer2.current);
    };
  }, []);

  return (
    <DeviceCard className="select-none">
      <DetailsContainer>
        <LogoContainer>
          <img src={device.brandLogoURL} alt="" />
        </LogoContainer>
        <NameContainer>
          <Name>{device.brandName}</Name>
          <Model>{device.deviceName}</Model>
        </NameContainer>
        <OtherDetails>
          <Price>Rs. {device.price}</Price>
          <DaysLeft>{device.timeLeft}h</DaysLeft>
        </OtherDetails>
        <RemoveButton>
          <CloseOutlined className="text-xl mt-[15px] font-extrabold text-[#ebebeb]" />
        </RemoveButton>
      </DetailsContainer>

      <Strip inStock={isInStock} isLoading={loading}>
        <span>{isInStock ? "IN STOCK" : "NOT IN STOCK"}</span>
        {
          <ProgressBar trailColor={!isInStock ? "#fafafa" : "#7ba650"}>
            <ProgressStrip strokeColor={!isInStock ? "#c6c6c6" : "#457931"} isFull={progressStatus === 100} />
          </ProgressBar>
        }
      </Strip>
    </DeviceCard>
  );
}

export default AnimatedStockAlert;
